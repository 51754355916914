import React, { useRef, useState } from "react";
// eslint-disable-next-line
import styles from "./GoogleMapComponent.module.css";

import { GOOGLE_MAPS_API_KEY } from "../../../constants/apiConstants";
import { LoadingSpinner } from "../../../components";
import { useJsApiLoader, GoogleMap } from "@react-google-maps/api";
import { useReverseGeocoding } from "../../../hooks";
import { images } from "../../../images";
import {
  PUNE_AIRPORT_LATITUDE,
  PUNE_AIRPORT_LONGITUDE,
  libs,
} from "../../../constants/storageConstants";

const center = { lat: 18.578164454287336, lng: 73.90803401551001 };
const GoogleMapComponent = ({ addressLiftUp }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libs,
  });
  const mapRef = useRef(null);

  // const [mapCenter, setMapCenter] = useState({ lat: -34.397, lng: 150.644 });
  const [selectedLocation, setSelectedLocation] = useState({
    lat: PUNE_AIRPORT_LATITUDE,
    lng: PUNE_AIRPORT_LONGITUDE,
  });

  const address = useReverseGeocoding(
    selectedLocation.lat || "",
    selectedLocation.lng || ""
  );

  const handleMapDragEnd = () => {
    const center = mapRef.current.state.map.center;
    const newLocation = { lat: center.lat(), lng: center.lng() };
    setSelectedLocation(newLocation);
    // console.log(newLocation);
    // setMapCenter(newLocation);
    addressLiftUp(address, selectedLocation);
  };
  // const handleMapDrag = () => {
  //   if (mapRef.current) {
  //     const center = mapRef.current.state.map.center;
  //     const newLocation = { lat: center.lat(), lng: center.lng() };
  // setMapCenter(newLocation);
  //     console.log(newLocation);
  //   }
  // };

  if (!isLoaded) {
    return (
      <div className={styles.googleMapComponent}>
        <LoadingSpinner />;
      </div>
    );
  }
  return (
    <>
      <div className={styles.googleMapComponent}>
        <GoogleMap
          ref={mapRef}
          // onDrag={handleMapDrag}
          onDragEnd={handleMapDragEnd}
          center={center}
          zoom={15}
          mapContainerStyle={{
            height: "100%",
            width: "100%",
            borderRadius: "5px",
          }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            // minZoom: 10,
            // maxZoom: 21,
            restriction: {
              latLngBounds: {
                south: 5.4627, // Latitude of the southernmost point of India with an extra margin
                west: 67.1114, // Longitude of the westernmost point of India with an extra margin
                north: 38.0841, // Latitude of the northernmost point of India with an extra margin
                east: 98.3956, // Longitude of the easternmost point of India with an extra margin
              },
              strictBounds: true,
            },
          }}
        >
          {/* <Marker position={mapCenter} /> */}
        </GoogleMap>
        <div className={styles.mapMarkerRed}>
          <img src={images.mapMarkerRed} alt="marker taxiwars" />
        </div>
      </div>
    </>
  );
};

export default GoogleMapComponent;
