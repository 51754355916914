import React from "react";
import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./TotalFareMakePaymentCard.module.css";

const TotalFareMakePaymentCard = ({
  bidId,
  price,
  timeToReach,
  driverName,
  driverRating,
  driverImage = images.userGeneric,
  vehicleNumber,
  vehicleName,
  vehicleImage = images.cab,
  onCancelClick = () => {
    console.warn("prop-Function not connected");
  },
}) => {
  return (
    <div className={styles.totalFareMakePaymentCard}>
      <div className={styles.upperPart}>
        <div className={styles.writtenContent}>
          <div className={styles.priceAway}>
            <p>Total Fare ₹ {parseFloat(price / 100).toFixed(1)}</p>
            <p>Arriving in {timeToReach} min</p>
          </div>
          <div className={styles.nameRatingCarDetails}>
            <p>
              {driverName} | <img src={images.orangeStar} alt="orange star" />{" "}
              {driverRating}
            </p>
            <p>
              {vehicleNumber} | {vehicleName}
            </p>
          </div>
        </div>
        <div className={styles.imageContent}>
          <img className={styles.cab} src={vehicleImage} alt="car" />
          <img
            className={styles.userGeneric}
            src={driverImage}
            alt="user profile"
          />
        </div>
      </div>
      <hr className={styles.hrDivider} />
      <div className={styles.lowerPart}>
        {/* If we show the number they may directly initiate the call with driver before payment */}
        {/* <button>+91 99999999</button> */}
        {/*<hr /> */}
        <button onClick={onCancelClick}>Cancel Ride</button>
      </div>
    </div>
  );
};

export default TotalFareMakePaymentCard;
