import React from "react";
// eslint-disable-next-line
import styles from "./BackPageHeadingWrapper.module.css";

import { images } from "../../../images";
import { useNavigateOnEvent } from "../../../hooks";
import { AnimationLayoutSlide } from "../../../components";
import { AnimatePresence } from "framer-motion";

const BackPageHeadingWrapper = ({
  children,
  logoHeadingText = "prop-BackPageHeadingWrapper",
  diableBackButton = false,
  bgGreenTextWhite = false,
}) => {
  const navigateBackOneStep = useNavigateOnEvent(-1, true);
  return (
    <>
      <AnimatePresence>
        <AnimationLayoutSlide>
          <div className={styles.backPageHeadingWrapper}>
            <div
              style={{
                backgroundColor: bgGreenTextWhite ? "#419c00" : "",
              }}
              className={styles.appbar}
            >
              {!diableBackButton && (
                <img
                  onClick={navigateBackOneStep}
                  className={styles.arrowBackWhite}
                  src={images.arrowBackWhite}
                  alt="back icon"
                />
              )}
              {diableBackButton && <div></div>}

              <h1
                style={{
                  fontWeight: bgGreenTextWhite ? "bold" : "",
                }}
                className={styles.logoHeadingText}
              >
                {logoHeadingText}
              </h1>
              <div className={styles.emptyDiv}></div>
            </div>
            <div className={styles.content}>{children}</div>
          </div>
        </AnimationLayoutSlide>
      </AnimatePresence>
    </>
  );
};

export default BackPageHeadingWrapper;
