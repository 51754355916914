import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BackPageHeadingWrapper,
  Button,
  // Button,
  ErrorInNumber,
  // InputBox,
  InputLabelUserProfileImage,
  LogsBottomModal,
  NameEmailPhoneLine,
  WalletRidePoints,
  // InputLabelUserProfileImage,
} from "../../../components";
import {
  BASE_URL,
  DELETE_ACCOUT,
  RIDER_PROFILE_DETAILS,
  RIDE_EXTRA_SPENT_TOTAL_DETAILS,
} from "../../../constants/apiConstants";
import { riderProfileActions } from "../../../store/slice";
// eslint-disable-next-line
import styles from "./RiderEditProfile.module.css";
import { useState } from "react";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import { useLogoutHandler } from "../../../hooks";
import { toast } from "react-toastify";

const RiderEditProfile = () => {
  const logoutFunction = useLogoutHandler();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [value, setValue] = useState({});
  const [isLoadin, setIsLoadin] = useState(false);
  const [isError, setIsError] = useState(false);

  const [extraData, setExtraData] = useState({});

  const loadData = () => {
    setIsLoadin(true);
    setIsError(false);
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

    fetch(`${BASE_URL}${RIDER_PROFILE_DETAILS}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setValue(data);
      })
      .catch((e) => {
        setIsError(true);
        console.log("Error occured", e);
      })
      .finally(() => {
        setIsLoadin(false);
      });
  };

  const loadExtraData = async () => {
    setIsLoadin(true);
    setIsError(false);

    try {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

      const fetchUrl = `${BASE_URL}${RIDE_EXTRA_SPENT_TOTAL_DETAILS}`;

      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(fetchUrl, fetchOptions);

      if (!response.ok) {
        setIsError(false);

        return;
      }

      const data = await response.json();
      if (!data) {
        setIsError(false);

        return;
      }
      // console.log("Extra data", data);
      setExtraData(data);
      localStorage.setItem("extraData", JSON.stringify(data));
    } catch (error) {
      console.log("Ride edit profile", error);
    } finally {
      setIsLoadin(false);
    }
  };
  useEffect(() => {
    loadData();
    loadExtraData();
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(riderProfileActions.updateFirstName(value?.first_name));
    dispatch(riderProfileActions.updateLastName(value?.last_name));
    dispatch(riderProfileActions.updateEmailAddress(value?.email));
    dispatch(riderProfileActions.updateMobileNumber(value?.phone_number));
  }, [value, dispatch]);

  const firstName = useSelector((state) => state.riderProfile.firstName);
  const lastName = useSelector((state) => state.riderProfile.lastName);
  const emailAddress = useSelector((state) => state.riderProfile.emailAddress);
  const inputNumber = useSelector((state) => state.riderProfile.phoneNumber);
  // console.log(value);

  const sendDeleteRequestHandler = async () => {
    //
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    try {
      const response = await fetch(`${BASE_URL}${DELETE_ACCOUT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        setIsCancelDialogOpen(false);
        logoutFunction();
        toast.dismiss();
        toast.success("Data deleted");
      } else {
        setIsCancelDialogOpen(false);
        toast.dismiss();
        toast.error(`Something went wrong: ${response.status}`);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Something went wrong!");
    }
  };

  return (
    <BackPageHeadingWrapper logoHeadingText="Your Profile">
      <div className={styles.riderEditProfile}>
        <LogsBottomModal
          // para={"This ride is not eligible for refund"}
          heading="Are you sure you want to"
          // heading="Cancel Ride ?"
          downloadButtonText={"Yes"}
          cancelButtonText={"No"}
          openLogDialogBox={isCancelDialogOpen}
          downloadLogsButton={() => {
            sendDeleteRequestHandler();
          }}
          cancelButton={() => {
            setIsCancelDialogOpen(false);
          }}
          para={"delete your account ?"}
          // heading="Cancel Ride ?"
          isSupportMainVisible={false}
        />
        <div style={{ height: "140px", width: "124px" }}>
          <InputLabelUserProfileImage
            openOnChangeBehaviour={false}
            idAndName="riderProfileImage"
          />
        </div>
        {isLoadin && <ErrorInNumber displayText="Loading..." />}
        {isError && <ErrorInNumber displayText="Some error occured..." />}
        <NameEmailPhoneLine
          name={`${firstName || "Full"} ${lastName || "Name"}`}
          email={emailAddress || "na@na.com"}
          mobile={inputNumber || "+91 9999999999"}
        />
        <WalletRidePoints
          totalAmount={extraData?.total_amount_spent}
          totalRides={extraData?.total_rides}
          totalPoints={extraData?.total_points}
        />

        <div
          style={{
            width: "min-content",
            backgroundColor: "#37474f",
            borderRadius: "10px",
            marginTop: "14rem",
          }}
        >
          <Button
            displayText="Delete&nbsp;Account"
            style={{
              backgroundColor: "#37474f",
              padding: "0.8rem 1rem",
              borderRadius: "10px",
            }}
            onClickFunction={() => {
              setIsCancelDialogOpen(true);
            }}
          />
        </div>
      </div>
    </BackPageHeadingWrapper>
  );
};

export default RiderEditProfile;
