import React from "react";
// eslint-disable-next-line
import styles from "./GridFromToTextBox.module.css";

import { images } from "../../../images";

const GridFromToTextBox = ({
  originAddress,
  originAddressOnChange,
  originAddressOnFocus,
  originInputDisabled = false,
  originInputOnClick = () => {},
  destinationAddress,
  destinationAddressOnChange,
  destinationAddressOnFocus,
  destinationInputDisabled = false,
  destinationInputOnClick = () => {},
}) => {
  return (
    <div className={styles.gridFromToTextBox}>
      <div className={styles.greenToRed}>
        <span className={styles.dotGreen}></span>
        <hr className={styles.hrLine} />
        <span className={styles.dotRed}></span>
      </div>
      <div className={styles.textBoxes}>
        {originInputDisabled ? (
          <input
            // disabled
            className={styles.disabledButClickableInput}
            defaultValue={originAddress}
            placeholder="please select origin"
            type="text"
            id="originAddress"
            name="originAddress"
            onClick={originInputOnClick}
          />
        ) : (
          <input
            autoFocus
            value={originAddress}
            onChange={originAddressOnChange}
            onFocus={originAddressOnFocus}
            placeholder="please select origin"
            type="text"
            id="originAddress"
            name="originAddress"
          />
        )}
        {destinationInputDisabled ? (
          <input
            // disabled
            className={styles.disabledButClickableInput}
            defaultValue={destinationAddress}
            placeholder="please select destination"
            type="text"
            id="destinationAddress"
            name="destinationAddress"
            onClick={destinationInputOnClick}
          />
        ) : (
          <input
            value={destinationAddress}
            onChange={destinationAddressOnChange}
            onFocus={destinationAddressOnFocus}
            placeholder="please select destination"
            type="text"
            id="destinationAddress"
            name="destinationAddress"
          />
        )}
      </div>
      {/* <div className={styles.arrrowUpAndDown}>
        <div className={styles.background}>
          <img
            className={styles.arrrowUpAndDownImage}
            src={images.arrrowUpAndDown}
            alt="arrrowUpAndDown"
          />
        </div>
      </div> */}
    </div>
  );
};

export default GridFromToTextBox;
