import React from "react";
import {
  Button,
  ErrorInNumber,
  MenuTextUserWrapper,
  PaidAcknowledgementCheckbox,
  PleasePayAmountParaLine,
  RideCarDriverPickupDropDetailsCard,
  RiderRatingCard,
  TopGreenCheckStatus,
} from "../../../components";
import { RIDER_HOME_POST_A_RIDE__TO_ROUTE } from "../../../constants/routeConstants";
import { useNavigateOnEvent, usePreventBackBehaviour } from "../../../hooks";
// eslint-disable-next-line
import styles from "./RideCompleted.module.css";
import { useDispatch, useSelector } from "react-redux";
import { riderBidActions, riderPostRideActions } from "../../../store/slice";
import { useEffect } from "react";
import {
  BASE_URL,
  EXPORT_EMAIL,
  RIDER_PROFILE_DETAILS,
  RIDE_DETAILS_COMPLETED,
  SEND_RATING,
} from "../../../constants/apiConstants";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import Cookies from "js-cookie";
import { useState } from "react";

const RideCompleted = () => {
  usePreventBackBehaviour();
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(false);
  const [checkBoxError, setCheckBoxError] = useState(false);

  const [email, setEmail] = useState("");
  const [emailUpdate, setEmailUpdate] = useState(false);

  const [urlParams] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const rideId = searchParams.get("rideId");
    const bidId = searchParams.get("bidId");
    // console.log("Param ride id", rideId, "");
    return { rideId: rideId, bidId: bidId };
  });

  // const selectedBidId = useSelector((state) => state.riderBid.selectedBidId);
  const selectedBidId = urlParams.bidId;
  // const selectedBidId = 6;

  const punctuality = useSelector((state) => state.riderRating.punctuality);
  const cleanliness = useSelector((state) => state.riderRating.cleanliness);
  const professionalism = useSelector(
    (state) => state.riderRating.professionalism
  );

  const [completedRideDetails, setCompletedRideDetails] = useState();

  const navigateToHome = useNavigateOnEvent(
    RIDER_HOME_POST_A_RIDE__TO_ROUTE,
    true
  );

  const rideCompletedButtonHandler = () => {
    if (!isChecked) {
      setCheckBoxError(true);
      return;
    }
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

    fetch(`${BASE_URL}${SEND_RATING}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        bid_id: selectedBidId,
        punctuality: punctuality,
        cleanliness: cleanliness,
        professionalism: professionalism,
      }),
    })
      .then((response) => {
        if (response.ok) {
          // console.log(response);
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        // console.log("Retrun data", data);
      })
      .catch((e) => {
        console.log("Error while sending rating", e);
      });

    const updateEmailId = () => {
      fetch(`${BASE_URL}${EXPORT_EMAIL}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        method: "POST",
        body: JSON.stringify({
          email: email ? email : "na@na.com",
        }),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Email updated successfully");
          } else {
            console.error("Failed to update email:");
          }
        })
        .catch((error) => {
          console.error("Error in email update request:", error);
        });
    };
    if (emailUpdate) {
      updateEmailId();
    }

    //cleaning of ridPostSlice
    dispatch(riderBidActions.clearBidSlice());
    dispatch(riderPostRideActions.clearPostRide());
    navigateToHome();
  };

  // const currentRideId = useSelector(
  //   (state) => state.riderCurrentRide.currentRIdeId
  // );

  const currentRideId = urlParams.rideId;
  // const currentRideId = 31;

  // const selectedBidId = useSelector((state) => state.riderBid.selectedBidId);

  useEffect(() => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    fetch(`${BASE_URL}${RIDE_DETAILS_COMPLETED}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: currentRideId,
        // bid_id: selectedBidId,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        // console.log("The completed ride details", data);
        setCompletedRideDetails({
          carName: data?.car_name,
          vehicleImage: data?.car_photo,
          driverName: data?.driver_name,
          driverImage: data?.driver_photo,
          rideStartTime: data?.Ride_Status?.driver_ride_start,
          rideEndTime: data?.Ride_Status?.driver_ride_end,
          pickupAddressText: data?.Ride_Status?.pickup_address_text,
          dropAddressText: data?.Ride_Status?.drop_address_text,
          // totalAmount: data?.Ride_Status?.amount,
          totalAmount: data?.total_amount,
          amountPayable: data?.amount_payable,
          paymentType: data?.payment_type,
        });
      })
      .catch((e) => {
        console.log("Error occured fetching completed ride details", e);
        setCompletedRideDetails({});
      });
  }, [currentRideId]);

  const onCheckBoxChangeHandler = (value) => {
    setIsChecked(value);
  };

  return (
    <MenuTextUserWrapper logoHeadingText="Ride Completed">
      <div className={styles.rideCompleted}>
        <TopGreenCheckStatus displayText="Your ride is completed." />
        {/* <StaticMapWithTimeCancelledCard /> */}
        <RiderRatingCard />
        <PleasePayAmountParaLine
          totalAmount={completedRideDetails?.amountPayable}
          paymentType={completedRideDetails?.paymentType}
        />
        <PaidAcknowledgementCheckbox
          label={"I have paid the driver"}
          isChecked={isChecked}
          onCheckboxChange={onCheckBoxChangeHandler}
        />
        {checkBoxError && (
          <ErrorInNumber displayText="Please confirm if paid" />
        )}
        <EmailVerification
          setEmailUpdate={setEmailUpdate}
          email={email}
          setEmail={setEmail}
        />

        <Button
          displayText="Submit"
          onClickFunction={rideCompletedButtonHandler}
        />
        <RideCarDriverPickupDropDetailsCard
          carName={completedRideDetails?.carName}
          driverName={completedRideDetails?.driverName}
          rideStartTime={completedRideDetails?.rideStartTime}
          rideEndTime={completedRideDetails?.rideEndTime}
          pickupAddressText={completedRideDetails?.pickupAddressText}
          dropAddressText={completedRideDetails?.dropAddressText}
          totalAmount={completedRideDetails?.totalAmount}
          vehicleImage={completedRideDetails?.vehicleImage}
          driverImage={completedRideDetails?.driverImage}
          paymentType={completedRideDetails?.paymentType}
        />
      </div>
    </MenuTextUserWrapper>
  );
};

export default RideCompleted;

const EmailVerification = ({ setEmailUpdate, email, setEmail }) => {
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  const [isEmailavailable, setEmailavailability] = useState(false);

  useEffect(() => {
    const userNameCheck = () => {
      fetch(`${BASE_URL}${RIDER_PROFILE_DETAILS}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if (data && data.hasOwnProperty("email")) {
            if (data?.email === "na@na.com") {
              setEmailavailability(true);
              setEmailUpdate(true);
            }
          }
        })
        .catch((error) => {
          console.error("Error in userNameCheck:", error);
        });
    };

    userNameCheck();
  }, [accessToken]);

  const setInputEmail = (email) => {
    setEmail(email);
  };

  return (
    <>
      {isEmailavailable && (
        <input
          className={styles.input}
          type="text"
          value={email}
          onChange={(e) => setInputEmail(e.target.value)}
          placeholder="Enter email to recieve Invoice"
        />
      )}
    </>
  );
};
