import { useEffect } from "react";
import { onMessageListener } from "../firebase";

const useOnMessageDisplayNotification = () => {
  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        console.log("Notifcation payload", payload);
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
          icon: payload.notification.icon || payload.notification.image,
        };

        if ("Notification" in window) {
          // Check if the browser supports the Web Notifications API

          // Request permission to display notifications
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              // Create a new notification
              const notification = new Notification(notificationTitle, {
                body: notificationOptions.body,
                icon: notificationOptions.icon, // Optional
              });
            }
          });
        } else {
          // The browser doesn't support notifications.
          console.log("Web Notifications are not supported in this browser.");
        }
      })

      .catch((err) => {
        console.log("Notifcation onMessage error: ", err);
      });
  }, []);
};

export { useOnMessageDisplayNotification };
