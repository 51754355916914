import { configureStore } from "@reduxjs/toolkit";
import {
  authSlice,
  userAgentSlice,
  riderProfileSlice,
  riderPostRideSlice,
  helpSlice,
  riderBidSlice,
  uiSlice,
  riderAuthSlice,
  fcmTokenSlice,
  riderCurrentRideSlice,
  riderRatingSlice,
  riderCreatedRideSlice,
  customModalSlice,
  uiElementSlice,
  riderPostRideAdvanceBookingSlice,
  riderRatingAdvancedRideSlice,
  campaignDialogBoxSlice,
} from "./slice";

const store = configureStore({
  reducer: {
    userAgent: userAgentSlice.reducer,
    auth: authSlice.reducer,
    riderAuth: riderAuthSlice.reducer,
    riderPostRide: riderPostRideSlice.reducer,
    riderProfile: riderProfileSlice.reducer,
    riderBid: riderBidSlice.reducer,
    help: helpSlice.reducer,
    ui: uiSlice.reducer,
    fcmToken: fcmTokenSlice.reducer,
    riderCurrentRide: riderCurrentRideSlice.reducer,
    riderRating: riderRatingSlice.reducer,
    riderRatingAdvancedRide: riderRatingAdvancedRideSlice.reducer,
    riderCreatedRide: riderCreatedRideSlice.reducer,
    customModal: customModalSlice.reducer,
    uiElement: uiElementSlice.reducer,
    riderPostRideAdvanceBooking: riderPostRideAdvanceBookingSlice.reducer,
    campaignDialogBox: campaignDialogBoxSlice.reducer,
  },
});

export default store;
