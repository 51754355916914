// Cookie
export const ACCESS_TOKEN_COOKIE_NAME = "access";

// Local Storage
// key
export const USER_AGENT = "userAgent";
// value
export const USER_AGENT_RIDER = "rider";
export const USER_AGENT_DRIVER = "driver";

// error
export const UNDEFINED = "undefined";
export const NULL = "null";

export const USER_LOCAL_STORAGE = "_user_driver_rider_";

export const EMAIL_VALIDATION_REGEX =
  /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

// map selection center location
export const PUNE_AIRPORT_LATITUDE = 18.578164454287336;
export const PUNE_AIRPORT_LONGITUDE = 73.90803401551001;

export const DRIVER_CURRENT_LOCATION = "driver_current";

export const GLOBAL_VERSION_CONSTANT = "3.23.127";

// filter constants
export const FILTER_CONSTANT_TAXI = "Taxi";
export const FILTER_CONSTANT_AUTO = "Auto";
export const FILTER_CONSTANT_BOTH = "Both";

// export const hashMapHistoryArraySize = ;
export const HASH_MAP_HISTORY_SIZE_ARRAY = 50;

const extraData = JSON.parse(localStorage.getItem("extraData")) || {};

const cashBackAmount = extraData.total_points || "00";
const rides = extraData.total_rides || "00";

// const customer_details = JSON.parse(
//   localStorage.getItem("_user_driver_rider_")
// );

// const customerName = customer_details.first_name;
// const customerNumber = customer_details.phone_number;

export const redeemCashBack = () => {
  const fullNum = "+919650145293";
  const fullText = encodeURIComponent(`
Hi Taxiwars,
I am requesting my cashback amount "₹ ${cashBackAmount}" and my no of completed rides "${rides}"
  `);
  const url = "https://wa.me/" + fullNum + "?text=" + fullText;
  window.open(url, "_blank");
};

export const callToWhatsApp = () => {
  const fullNum = "+919650145293";
  const fullText = encodeURIComponent(`
Hi TaxiWars,
My promotional code is ---- and my cashback amount is ----
  `);
  const url = "https://wa.me/" + fullNum + "?text=" + fullText;
  window.open(url, "_blank");
};

const PromotionCode = "PUNE-OFFER";

export const navigateToWhatsApp = () => {
  const message = encodeURIComponent(`Hi,

I just invited you to use the Taxiwars app!  A Journey of Choices and Benefits 
Promotion code - ${PromotionCode}
Complete 1 ride and refer your 25 friends & Get Cashback of Rs500

Step1: Use my link to download the app - https://bit.ly/taxiwars-rider-app
Step 2: Register using your mobile number.
Step3: Start ride with Taxiwars at lowest fares

Why Taxiwars:- 
1. Drivers compete for rides and Riders always enjoy the lowest fare
2. Lowest fare Guaranteed
3. Same Day Return option in 'Advance booking' -  12-hour round trip at unbeatable prices – much cheaper than car rentals!  

Happy Travels
Travel Local & Intercity With Taxiwars

Website - https://taxiwars.in/
Demo Video - https://youtu.be/2mE3i1ZG8xQ`);

  const url = `https://api.whatsapp.com/send?text=${message}`;
  window.open(url, "_blank");
};

export const optionsgeloc = {
  enableHighAccuracy: true,
  maximumAge: 1 * 1000,
  timeout: 5 * 1000,
};

export const libs = ["places"];

export const THRESHOLD_DISTANCE_FOR_SHOWING_RETURN_TYPE = 50000; // in metres

export const IS_SHOW_APP_NOT_SUPPORTED = false;

export const PACKAGE_12_HOUR = "Package - 12hours/Return";
export const POINTS_CONSTANT = 20;
