import { THRESHOLD_DISTANCE_FOR_SHOWING_RETURN_TYPE } from "../../../constants/storageConstants";
import styles from "./SelectTimeDiv.module.css";

const SelectTimeDiv = ({
  vehicleType,
  setOpenTaxiTypeModal,
  selectedDateTime,
  setIsDateTimeModalOpen,
  isRideReturn,
  setIsRideReturn,
  distanceMetres,
}) => {
  return (
    <div
      style={{
        justifyContent: "space-around",
        // width: "100%",
        // backgroundColor: "blue",
        letterSpacing: "0.2px",
      }}
      className={styles.selectTimeDivLine}
    >
      <div
        className={styles.selectTimeDiv}
        style={{
          backgroundColor: "#419c00",
        }}
      >
        {!selectedDateTime && (
          <div
            style={{
              color: "white",
            }}
            onClick={() => {
              setIsDateTimeModalOpen(true);
            }}
          >
            Schedule
          </div>
        )}
        {selectedDateTime && (
          <div
            style={{
              color: "white",
            }}
            onClick={() => {
              setIsDateTimeModalOpen(true);
            }}
          >
            {selectedDateTime}
          </div>
        )}
      </div>
      {/* {selectedDateTime && (
        <div
          onClick={() => {
            setSelectedDateTime(null);
            onCancelResetSchedule();
          }}
          className={styles.resetButton}
        >
          Reset
        </div>
      )} */}
      <div
        onClick={() => {
          setOpenTaxiTypeModal(true);
        }}
        className={styles.selectTimeDiv}
        style={{
          backgroundColor: `${"#419c00"}`,
          color: "white",
        }}
      >
        {vehicleType === "mini"
          ? "Taxi-Mini"
          : vehicleType === "sedan"
          ? "Taxi-Sedan"
          : vehicleType === "suv"
          ? "Taxi-SUV"
          : vehicleType === "innova"
          ? "Innova"
          : ""}
      </div>
      {/* <div
        onClick={() => {
          setOpenTaxiTypeModal(true);
        }}
        className={styles.selectTimeDiv}
        style={{
          backgroundColor: `${vehicleType === "innova" ? "#419c00" : "gray"}`,
          color: `${vehicleType === "innova" ? "white" : "white"}`,
        }}
      >
        Innova
      </div> */}

      {parseInt(distanceMetres) >
        parseInt(THRESHOLD_DISTANCE_FOR_SHOWING_RETURN_TYPE) && (
        <div
          style={{
            backgroundColor: "#419c00",
            // marginTop: "1rem",
            paddingTop: "1rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            borderRadius: "25px",
            fontWeight: "bold",
          }}
          className=""
        >
          <PaidAcknowledgementCheckbox
            label={"Return"}
            isChecked={isRideReturn}
            onCheckboxChange={() => {
              setIsRideReturn((prev) => !prev);
            }}
          />
        </div>
      )}

      {/* <div
        onClick={() => {
          // setIsRideReturn((prev) => !prev);
        }}
        className={styles.selectTimeDiv}
        style={{
          backgroundColor: `${isRideReturn ? "#419c00" : "gray"}`,
          color: "white",
        }}
      >
        <input type="checkbox" name="" id="" />
        Return
      </div> */}
    </div>
  );
};

export default SelectTimeDiv;

const PaidAcknowledgementCheckbox = ({
  label,
  isChecked,
  onCheckboxChange,
}) => {
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    onCheckboxChange(isChecked);
  };
  return (
    <div className={styles.paidAcknowledgementCheckbox}>
      <label className={styles.labelContainer}>
        {/* <span></span> */}
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span className={styles.checkmark}></span>
        {label}
      </label>
    </div>
  );
};
