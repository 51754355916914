// import { getMessaging, getToken } from "firebase/messaging";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
// import app from "./firebase";
import { useEffect, useState } from "react";
import { BASE_URL, SEND_FCM_TOKEN } from "./constants/apiConstants";
import { ALL_IN_APP_NOTIFICATION_DEFAULT_TIME } from "./constants/timeConstants";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fcmTokenActions, uiElementActions } from "./store/slice";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  LoadingSpinner,
  LogsBottomModal,
  RiderAuthWrapper,
  RmhScreenWrapper,
  ScreenWrapper,
  UpdateRequestModal,
} from "./components";
import {
  MakePayment,
  MakePaymentCcAvenue,
  MakePaymentCcAvenueWaitingPage,
  PostRideFrom,
  PostRideFromToMapSelection,
  PostRideTo,
  RefundPolicy,
  RideArriving,
  RideCompleted,
  RideInProgress,
  RiderCompleteProfile,
  RiderEditProfile,
  RiderHelp,
  RiderPastRideDetails,
  RiderPastRides,
  ScheduledRide,
  SignUp,
  SplashScreen,
  TermsNCondition,
  VerifyOtpRiderDriver,
  WaitingForBid,
} from "./pages";
import {
  ADV_POST_A_RIDE_FROM_ROUTE,
  ADV_POST_A_RIDE_MAP_VIEW_ROUTE,
  ADV_POST_A_RIDE_TO_ROUTE,
  ADV_RIDER_RIDE_ARRIVING_ROUTE_ASSIGNED_DRIVER_ROUTE,
  ADV_RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE,
  ADV_RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE,
  ADV_RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE_WAITING_PAGE,
  ADV_RIDER_RIDE_IN_PROGRESS_ROUTE,
  AUTH_SIGN_UP_ROUTE,
  AUTH_VERIFY_OTP_ROUTE,
  RIDER_COMPLETE_PROFILE_ROUTE,
  RIDER_EXTRAS_EDIT_PROFILE,
  RIDER_EXTRAS_HELP,
  RIDER_EXTRAS_PAST_RIDES,
  RIDER_EXTRAS_PAST_RIDE_DETAIL,
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
  RIDER_POST_A_RIDE_FROM_TO_MAP_SELECTION_ORDER_ROUTE,
  RIDER_POST_A_RIDE_FROM_TO_ROUTE,
  RIDER_RIDE_ARRIVING_ROUTE,
  RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE,
  RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE,
  RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE,
  RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE_WAITING_PAGE,
  RIDER_RIDE_IN_PROGRESS_ROUTE,
  RIDER_SCHEDULED_RIDE,
  RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE,
  ROOT_ROUTE,
  // ROOT_ROUTE,
  ROUTE_REFUND_POLICY,
  ROUTE_TERMS_AND_CONDITIONS,
  SPLASH_SCREEN_ROUTE,
} from "./constants/routeConstants";
import {
  useCustomLogger,
  useNavigateOnEvent,
  useOnMessageDisplayNotification,
} from "./hooks";
import {
  AssignedDriverAfterPaymentConfirmation,
  MakePaymentCcAvenueAdvanceBooking,
  MakePaymentCcAvenueWaitingPageAdvanceBooking,
  PostRideFromAdvanceBooking,
  PostRideFromToMapSelectionAdvanceBooking,
  PostRideToAdvanceBooking,
  RideCompletedAdvancedRide,
  RideInProgressAdvacnedRide,
} from "./pages/RiderPagesAdvanceBooking";
import { IS_SHOW_APP_NOT_SUPPORTED } from "./constants/storageConstants";

// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

function App() {
  const dispatch = useDispatch();
  useOnMessageDisplayNotification();
  const [fcmTokenExtracted, setFcmTokenExtracted] = useState(false);

  const logger = useCustomLogger();
  const logsDownloadDialogBox = useSelector(
    (state) => state.uiElement.logsDownloadDialogBox
  );

  const navigateToSplashScreen = useNavigateOnEvent(SPLASH_SCREEN_ROUTE, true);
  const [showUpdatePrompt, setShowUpdatePrompt] = useState(false);

  // extracting fcm token from query parameter and send to backend
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const fcmToken = searchParams.get("fcm_token");

    if (fcmToken) {
      dispatch(fcmTokenActions.setFcmToken(fcmToken));
      localStorage.setItem("FCM_WITHOUT_NAV", fcmToken);
      console.log(fcmToken);

      //Sending FCM-Token to backend to Map FCM Token with user details
      const sendFCMTokenToBackend = async (fcmToken) => {
        const url = `${BASE_URL}${SEND_FCM_TOKEN}`;

        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              fcm: fcmToken,
            }),
          });

          if (response.ok) {
            localStorage.setItem("isFCMsent", true);
            console.log("FCM token sent to backend successfully.");
          } else {
            console.error(
              "Failed to send FCM token to backend:",
              response.statusText
            );
          }
        } catch (error) {
          console.error("Error sending FCM token to backend:", error.message);
        }
      };

      const conditionFromLocalStorage = localStorage.getItem("isFCMsent");

      if (conditionFromLocalStorage === null) {
        sendFCMTokenToBackend(fcmToken);
      }
    }

    // navigateToSplashScreen();
    setFcmTokenExtracted(true);

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      setShowUpdatePrompt(true);
    };
    window.addEventListener("customEventUpdateDownloaded", handleStorageChange);

    return () => {
      window.removeEventListener(
        "customEventUpdateDownloaded",
        handleStorageChange
      );
    };
  }, []);

  useEffect(() => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      // Browser supports service workers and push notifications,
      // indicating PWA support.
      console.log("PWA is supported!");
    } else {
      // PWA is not supported in this browser.
      console.log("PWA is NOT supported!");
      if (IS_SHOW_APP_NOT_SUPPORTED) {
        const unsupportedMessage = document.createElement("div");
        unsupportedMessage.textContent =
          "This app is not supported in your browser.";
        // You can style the message to make it more prominent
        unsupportedMessage.style.cssText = `
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          background-color: #ff6666;
          color: white;
          text-align: center;
          font-size: 1.9rem;
          padding: 10px;
          z-index: 9999;
        `;
        document.body.appendChild(unsupportedMessage);
      }
    }
  }, []);

  if (!fcmTokenExtracted) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {/* <Notifications /> */}
      <ToastContainer
        position="top-center"
        autoClose={ALL_IN_APP_NOTIFICATION_DEFAULT_TIME}
        hideProgressBar={false}
        newestOnTop={false}
        transition={Slide}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <UpdateRequestModal openBool={showUpdatePrompt} />
      <LogsBottomModal
        openLogDialogBox={logsDownloadDialogBox}
        downloadLogsButton={() => {
          logger.exportLogs();
          dispatch(uiElementActions.closeLogsDownloadDialogBox());
        }}
        cancelButton={() => {
          dispatch(uiElementActions.closeLogsDownloadDialogBox());
        }}
      />
      <Routes>
        {/* WARNING - DO NOT EXPORT ROUTES TO ANOTHER FILE, */}
        {/* OR ELSE REACT ROUTER WILL IGNORE INITIAL */}
        {/* BINDINDS WITH THE COMPONENTS AND GIVE CONSOLE WARNS */}
        {/* WILL BE CLEANED WHEN SHIFT TO useBrowserRouter v6.4 */}
        {/* ****************** */}
        {/* ON BOARDING ROUTES */}
        {/* ****************** */}
        <Route element={<ScreenWrapper />}>
          <Route path={SPLASH_SCREEN_ROUTE} element={<SplashScreen />} />
          {/* <Route path={WELCOME_ROUTE} element={<Welcome />} /> */}
          <Route path={AUTH_SIGN_UP_ROUTE} element={<SignUp />} />
          <Route
            path={AUTH_VERIFY_OTP_ROUTE}
            element={<VerifyOtpRiderDriver />}
          />
          <Route
            path={ROUTE_TERMS_AND_CONDITIONS}
            element={<TermsNCondition />}
          />
          <Route path={ROUTE_REFUND_POLICY} element={<RefundPolicy />} />
          <Route
            path={ROOT_ROUTE}
            element={<Navigate to={SPLASH_SCREEN_ROUTE} replace />}
          />
        </Route>
        {/* ****************** */}
        {/* RIDER ROUTES */}
        {/* ****************** */}
        <Route element={<ScreenWrapper />}>
          <Route
            path={RIDER_COMPLETE_PROFILE_ROUTE}
            element={<RiderCompleteProfile />}
          />
        </Route>

        <Route element={<RmhScreenWrapper />}>
          <Route element={<RiderAuthWrapper />}>
            <Route
              path={RIDER_HOME_POST_A_RIDE__TO_ROUTE}
              element={<PostRideTo />}
            />
            <Route
              path={ADV_POST_A_RIDE_TO_ROUTE}
              element={<PostRideToAdvanceBooking />}
            />
            <Route
              path={RIDER_POST_A_RIDE_FROM_TO_ROUTE}
              element={<PostRideFrom />}
            />
            <Route
              path={ADV_POST_A_RIDE_FROM_ROUTE}
              element={<PostRideFromAdvanceBooking />}
            />
          </Route>
          <Route
            path={RIDER_POST_A_RIDE_FROM_TO_MAP_SELECTION_ORDER_ROUTE}
            element={<PostRideFromToMapSelection />}
          />
          <Route
            path={ADV_POST_A_RIDE_MAP_VIEW_ROUTE}
            element={<PostRideFromToMapSelectionAdvanceBooking />}
          />
          <Route
            path={RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE}
            element={<WaitingForBid />}
          />
          <Route path={RIDER_SCHEDULED_RIDE} element={<ScheduledRide />} />
        </Route>
        <Route
          path={RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE}
          element={<MakePayment />}
        />
        <Route
          path={RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE}
          element={<MakePaymentCcAvenue />}
        />
        <Route
          path={ADV_RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE}
          element={<MakePaymentCcAvenueAdvanceBooking />}
        />

        <Route
          path={RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE_WAITING_PAGE}
          element={<MakePaymentCcAvenueWaitingPage />}
        />
        <Route
          path={
            ADV_RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE_WAITING_PAGE
          }
          element={<MakePaymentCcAvenueWaitingPageAdvanceBooking />}
        />

        <Route path={RIDER_RIDE_ARRIVING_ROUTE} element={<RideArriving />} />
        <Route
          path={ADV_RIDER_RIDE_ARRIVING_ROUTE_ASSIGNED_DRIVER_ROUTE}
          element={<AssignedDriverAfterPaymentConfirmation />}
        />
        <Route
          path={RIDER_RIDE_IN_PROGRESS_ROUTE}
          element={<RideInProgress />}
        />
        <Route
          path={ADV_RIDER_RIDE_IN_PROGRESS_ROUTE}
          element={<RideInProgressAdvacnedRide />}
        />
        <Route
          path={RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE}
          element={<RideCompleted />}
        />

        <Route
          path={ADV_RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE}
          element={<RideCompletedAdvancedRide />}
        />

        <Route
          path={RIDER_EXTRAS_EDIT_PROFILE}
          element={<RiderEditProfile />}
        />
        <Route path={RIDER_EXTRAS_PAST_RIDES} element={<RiderPastRides />} />
        <Route
          path={RIDER_EXTRAS_PAST_RIDE_DETAIL}
          element={<RiderPastRideDetails />}
        />

        <Route path={RIDER_EXTRAS_HELP} element={<RiderHelp />} />
      </Routes>
    </>
  );
}

export default App;
