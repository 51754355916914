import React from "react";
import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./InputLabelUserProfileImage.module.css";

const InputLabelUserProfileImage = ({
  idAndName,
  uploadedImage,
  handleChange,
  openOnChangeBehaviour = true,
}) => {
  return (
    <div className={styles.inputLabelUserProfileImage}>
      <label className={styles.inputLabel} htmlFor={idAndName}>
        <img
          className={styles.userGeneric}
          src={uploadedImage || images.userGenericInvert}
          alt="user profile"
        />
        {/* <div className={styles.cameraDiv}>
          <img className={styles.camera} src={images.camera} alt="camera" />
        </div> */}
      </label>
      {openOnChangeBehaviour && (
        <input
          id={idAndName}
          name={idAndName}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default InputLabelUserProfileImage;
