import { createSlice } from "@reduxjs/toolkit";

const riderProfileSlice = createSlice({
  name: "riderProfile",
  initialState: {
    firstName: "",
    lastName: "",
    emailAddress: "",
    helpNumber: "",
    phoneNumber: "",
  },
  reducers: {
    updateFirstName(state, action) {
      state.firstName = action.payload;
    },
    updateLastName(state, action) {
      state.lastName = action.payload;
    },
    updateEmailAddress(state, action) {
      state.emailAddress = action.payload;
    },
    updateHelpNumber(state, action) {
      state.helpNumber = action.payload;
    },
    updateMobileNumber(state, action) {
      state.phoneNumber = action.payload;
    },
  },
});

export const riderProfileActions = riderProfileSlice.actions;
export default riderProfileSlice;
