import { useNavigate } from "react-router-dom";
import styles from "./RideInProgressAdvacnedRide.module.css";
import {
  useInterval,
  useNavigateOnEvent,
  usePreventBackBehaviour,
} from "../../../hooks";
import {
  ADV_POST_A_RIDE_TO_ROUTE,
  ADV_RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE,
} from "../../../constants/routeConstants";
import { useEffect, useState } from "react";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  PACKAGE_12_HOUR,
} from "../../../constants/storageConstants";
import Cookies from "js-cookie";
import {
  ADV_RIDER_GET_ACCEPTED_RIDE_DETAILS,
  ADV_RIDE_STATUS_GET,
  BASE_URL,
  RIDE_STATUS_CANCELLED,
  RIDE_STATUS_COMPLETED,
} from "../../../constants/apiConstants";
import { toast } from "react-toastify";
import { ONE_SECOND } from "../../../constants/timeConstants";
import {
  Button,
  MenuTextUserWrapper,
  NumberPlateCarNameRatingCard,
  SosButton,
  TopGreenCheckStatus,
} from "../../../components";
import { images } from "../../../images";
import { pushState } from "history-throttled";

const RideInProgressAdvacnedRide = () => {
  // const dispatch = useDispatch();
  usePreventBackBehaviour();
  const navigate = useNavigate();
  const navigateToPostRideTo = useNavigateOnEvent(
    ADV_POST_A_RIDE_TO_ROUTE,
    true
  );

  const [urlParams] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const rideId = searchParams.get("rideId");
    return { rideId: rideId };
  });

  const currentRideId = urlParams.rideId;

  const [allotedDriverDetails, setAllotedDriverDetails] = useState({
    vehicleNumber: "",
    vehicleName: "",
    driverName: "",
    phoneNumber: "",
    driverImage: "",
    vehicleImage: "",
    amount: "",
    otp: "",
    pickupLat: "",
    pickupLng: "",
    dropLat: "",
    dropLng: "",
    returnType: false,
    rideDistance: "",
  });

  useEffect(() => {
    const functionCall = () => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(`${BASE_URL}${ADV_RIDER_GET_ACCEPTED_RIDE_DETAILS}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ ride_id: parseInt(currentRideId) }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          // console.log("Selected bid details with number -", data);
          setAllotedDriverDetails({
            vehicleNumber: data?.registration_number,
            vehicleName: data?.car_name,
            driverName: data?.name,
            phoneNumber: data?.phone_number,
            driverImage: data?.driver_photo,
            vehicleImage: data?.car_photo,
            amount: data?.amount,
            otp: data?.otp,
            pickupLat: data?.pickup_point_latitude,
            pickupLng: data?.pickup_point_longitude,
            dropLat: data?.drop_lat,
            dropLng: data?.drop_lng,
            returnType: data?.return_type,
            rideDistance: data?.ride_distance,
          });
        })
        .catch((e) => {
          console.log("Data could not be fetched", e);
        })
        .finally(() => {});
    };
    functionCall();

    const interval = setInterval(() => {
      functionCall();
    }, ONE_SECOND * 300);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  const httpPolling = () => {
    fetch(`${BASE_URL}${ADV_RIDE_STATUS_GET}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: parseInt(currentRideId),
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((rideStatus) => {
        // console.log("Current ride Status", rideStatus);
        // dispatch(
        //   riderCurrentRideActions.setCurrentRideStatus(rideStatus?.Ride_Status)
        // );
        pushState(
          null,
          null,
          `#rideStatusFetch=${JSON.stringify(rideStatus?.Ride_Status)}`
        );

        if (rideStatus?.Ride_Status === RIDE_STATUS_COMPLETED) {
          // navigateToRideCompleted();
          navigate(
            `${ADV_RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE}?rideId=${rideStatus.ride_id}`
          );
        }
        if (
          rideStatus?.Ride_Status === RIDE_STATUS_CANCELLED
          // rideStatus?.Ride_Status === RIDE_STATUS_IN_PROGRESS ||
          // rideStatus?.Ride_Status === RIDE_STATUS_BID_CONFIRMED ||
          // rideStatus?.Ride_Status === RIDE_STATUS_PENDING ||
          // rideStatus?.Ride_Status === RIDE_STATUS_ACTIVE
        ) {
          //
          navigateToPostRideTo();
        } else {
          // navigateToSplashScreen();
          // console.log(rideStatus);
          // toast.dismiss();
          // toast.error(`Ride Status: ${rideStatus?.Ride_Status}`);
        }
      })
      .catch((e) => {
        console.log("Error in retrieving ride status", e);
      });
  };

  // TODO Change according to the ride time at later stages
  useInterval(httpPolling, ONE_SECOND * 30);

  // const originAddress = useSelector((state) => state.riderPostRide.inputOrigin);
  // const destinationAddress = useSelector(
  //   (state) => state.riderPostRide.inputDestination
  // );

  const openGoogleMaps = (lat, lng) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&travelmode=driving`;
    window.open(url, "_blank");
  };

  const navigateOnMapButtonHandler = () => {
    if (!allotedDriverDetails?.dropLat || !allotedDriverDetails?.dropLng) {
      toast.dismiss();
      toast.info("Please wait");
      return;
    }
    openGoogleMaps(
      allotedDriverDetails?.dropLat,
      allotedDriverDetails?.dropLng
    );
  };

  const [retunButton, setReturnButton] = useState(false);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setReturnButton(true);
    }, 10 * 60 * 1000);

    return () => clearTimeout(timerId);
  }, []);

  const navigateOnMapReturnButtonHandler = () => {
    if (!allotedDriverDetails?.dropLat || !allotedDriverDetails?.dropLng) {
      toast.dismiss();
      toast.info("Please wait");
      return;
    }
    openGoogleMaps(
      allotedDriverDetails?.pickupLat,
      allotedDriverDetails?.pickupLng
    );
  };

  return (
    <MenuTextUserWrapper logoHeadingText="Ride in Progress" bgGreenTextWhite>
      <div className={styles.rideArriving}>
        <TopGreenCheckStatus displayText="Your ride is in progress." />
        {
          <NumberPlateCarNameRatingCard
            vehicleNumber={allotedDriverDetails?.vehicleNumber || "Loading.."}
            vehicleName={allotedDriverDetails?.vehicleName || ""}
            driverName={allotedDriverDetails?.driverName || ""}
            phoneNumber={allotedDriverDetails?.phoneNumber || "NA"}
            vehicleImage={allotedDriverDetails?.vehicleImage}
            driverImage={
              allotedDriverDetails?.driverImage || images.userGeneric
            }
            cancelOnClick={() => {
              //
            }}
            cancelRideShow={false}
          />
        }
        {allotedDriverDetails.pickupLat && allotedDriverDetails.pickupLng && (
          <Button
            displayText="Navigate on maps"
            onClickFunction={navigateOnMapButtonHandler}
          />
        )}
      </div>

      {allotedDriverDetails.returnType && retunButton && (
        <Button
          style={{ marginTop: "0.7rem" }}
          displayText="Navigate - Home"
          onClickFunction={navigateOnMapReturnButtonHandler}
        />
      )}

      {allotedDriverDetails.returnType ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0rem",
          }}
        >
          <p
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "3rem",
            }}
          >
            {PACKAGE_12_HOUR}
            {`${PACKAGE_12_HOUR} (2 x ${
              allotedDriverDetails?.rideDistance
            }) + 10 = ${
              2 * allotedDriverDetails?.rideDistance.split(" ")[0]
            } Kms considered`}
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              // color: "red",
              textAlign: "center",
              // width: "75%",
              marginTop: "3rem",
            }}
          >
            *Extra Hrs Mini- ₹200/hr, Sedan- ₹215/hr, Suv- ₹260/hr
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "3rem",
            }}
          >
            *Extra kms Mini- ₹10/km, Sedan- ₹12/km, Suv- ₹14/km
          </p>
        </div>
      ) : (
        <></>
      )}
      {currentRideId && <SosButton currentRideId={currentRideId} />}
    </MenuTextUserWrapper>
  );
};

export default RideInProgressAdvacnedRide;
