import React from "react";
// eslint-disable-next-line
import styles from "./ErrorInNumber.module.css";

const ErrorInNumber = ({ displayText = "prop-ErrorInNumber" }) => {
  return (
    <>
      <p className={styles.errorInNumber}>{displayText}</p>
    </>
  );
};

export default ErrorInNumber;
