import React from "react";
// eslint-disable-next-line
import styles from "./WalletRidePoints.module.css";
import { images } from "../../images";
import { redeemCashBack } from "../../constants/storageConstants";

import { toast } from "react-toastify";

const WalletRidePoints = ({ totalAmount, totalRides, totalPoints }) => {
  const onWarning = () => {
    toast.warn(
      "you should complete minimum one ride & you should have minimum ₹ 500 cashback"
    );
  };

  return (
    <div className={styles.walletRidePoints}>
      <div className={styles.topRow}>
        <div className={styles.topBlocks}>
          <div className={styles.insideBlockTopRow}>
            <img
              className={styles.icons}
              src={images.walletVector}
              alt="wallet"
            />
            <p>Money Spent</p>
          </div>
          <p className={styles.amountDisplay}>
            {(totalAmount / 100).toFixed(1)}
          </p>
        </div>
        <div className={styles.topBlocks}>
          <div className={styles.insideBlockTopRow}>
            <img className={styles.icons} src={images.carVector} alt="car" />
            <p>Your Ride</p>
          </div>
          <p className={styles.amountDisplay}>{totalRides}</p>
        </div>
      </div>
      <div className={styles.bottomRow}>
        <div className={`${styles.topBlocks} ${styles.bgColorOverride}`}>
          <div className={styles.insideBlockTopRow}>
            <img
              className={styles.icons}
              src={images.clockVector}
              alt="wallet"
            />
            <p>Cash back ₹</p>
          </div>
          <p className={styles.amountDisplay}>
            {totalPoints?.toString().padStart(4, "0")}
          </p>
          <p>
            **Share with your friends and complete one ride.Then claim 500
            cashback points.
          </p>
        </div>
      </div>
      {totalPoints >= 500 && totalRides >= 1 ? (
        <button className={styles.button} onClick={redeemCashBack}>
          Redeem Cashback
        </button>
      ) : (
        <button className={styles.buttonDeactive} onClick={onWarning}>
          Redeem Cashback
        </button>
      )}
    </div>
  );
};

export default WalletRidePoints;
