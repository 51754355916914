import React, { useEffect, useState } from "react";
import {
  TopGreenCheckStatus,
  MenuTextUserWrapper,
  NumberPlateCarNameRatingCard,
  OtpAndPragraph,
  SosButton,
  GoogleMapArrivingSoonOtpDriverLocation,
  LogsBottomModal,
} from "../../../components";
import {
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
  RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE,
  RIDER_RIDE_IN_PROGRESS_ROUTE,
} from "../../../constants/routeConstants";
import {
  useInterval,
  useNavigateOnEvent,
  usePreventBackBehaviour,
  useRedirectAccToRide,
} from "../../../hooks";

// eslint-disable-next-line
import styles from "./RideArriving.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  BASE_URL,
  GOOGLE_MAPS_API_KEY,
  RIDER_CANCEL_WITHOUT_LIABILITY,
  RIDER_START_RIDE,
  RIDE_STATUS_COMPLETED,
  RIDE_STATUS_GET,
  RIDE_STATUS_IN_PROGRESS,
} from "../../../constants/apiConstants";
import { RIDER_GET_ACCEPTED_RIDE_DETAILS } from "../../../constants/apiConstants";
import Cookies from "js-cookie";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  libs,
} from "../../../constants/storageConstants";
import {
  riderBidActions,
  riderCreatedRideActions,
  riderCurrentRideActions,
  riderPostRideActions,
} from "../../../store/slice";
import { ONE_SECOND } from "../../../constants/timeConstants";
import { useJsApiLoader } from "@react-google-maps/api";
import { images } from "../../../images";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RideArriving = () => {
  useRedirectAccToRide();
  usePreventBackBehaviour();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libs,
  });

  const [urlParams] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const rideId = searchParams.get("rideId");
    const bidId = searchParams.get("bidId");
    // console.log("Param ride id", rideId, "");
    return { rideId: rideId, bidId: bidId };
  });

  const [pickupLongitude, setPickupLongitude] = useState("");
  const [pickupLatitude, setPickupLatitude] = useState("");

  // const [driverLocation, setDriverLocation] = useState();

  const [directionServiceResponse, setDirectionServiceResponse] = useState("");

  // const navigateToRideInProgress = useNavigateOnEvent(
  //   RIDER_RIDE_IN_PROGRESS_ROUTE,
  //   true
  // );

  const navigateToHomeScreen = useNavigateOnEvent(
    RIDER_HOME_POST_A_RIDE__TO_ROUTE
  );

  // const currentRideId = useSelector(
  //   (state) => state.riderCurrentRide.currentRIdeId
  // );
  const currentRideId = urlParams.rideId;

  // const selectedBidId = useSelector((state) => state.riderBid.selectedBidId);
  const selectedBidId = urlParams.bidId;

  // const selectedBidDetailsWithNumber = useSelector(
  //   (state) => state.riderBid.selectedBidDetailsWithNumber
  // );

  const [selectedBidDetailsWithNumber, setSelectedBidDetailsWithNumber] =
    useState({
      vehicleNumber: "",
      vehicleName: "",
      driverName: "",
      phoneNumber: "",
      driverImage: "",
      vehicleImage: "",
    });

  // console.log("selectedBidDetailsWithNumber", selectedBidDetailsWithNumber);

  useEffect(() => {
    const functionCall = () => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(`${BASE_URL}${RIDER_GET_ACCEPTED_RIDE_DETAILS}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ bid_id: parseInt(selectedBidId) }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          // console.log("Selected bid details with number -", data);
          setSelectedBidDetailsWithNumber({
            vehicleNumber: data?.car_details?.registration_number,
            vehicleName: data?.car_details?.car_name,
            driverName: data?.name,
            phoneNumber: data?.phone_number,
            driverImage: data?.driver_photo,
            vehicleImage: data?.car_details?.car_photo,
            driverLocationLat: parseFloat(data?.driver_location_lat),
            driverLocationLng: parseFloat(data?.driver_location_lng),
            amount: data?.amount,
          });
          // dispatch(riderCurrentRideActions.setCurrentRideId(data?.ride_id));
          if (!isLoaded) return;
          if (!parseFloat(pickupLatitude) || !parseFloat(pickupLongitude)) {
            return;
          }

          // eslint-disable-next-line no-undef
          const directionService = new google.maps.DirectionsService();
          if (!directionService) return;
          // console.log(
          //   "parseFloat(data?.driver_location_lat)",
          //   parseFloat(data?.driver_location_lat)
          // );
          // console.log(
          //   "parseFloat(data?.driver_location_lng),",
          //   parseFloat(data?.driver_location_lng)
          // );
          // setDriverLocation({
          //   latitude: parseFloat(data?.driver_location_lat),
          //   longitude: parseFloat(data?.driver_location_lng),
          // });
          // console.log("pickupLatitude", pickupLatitude);
          // console.log("pickupLongitude", pickupLongitude);
          directionService
            .route({
              origin: {
                lat: parseFloat(data?.driver_location_lat),
                lng: parseFloat(data?.driver_location_lng),
              },
              destination: {
                lat: parseFloat(pickupLatitude),
                lng: parseFloat(pickupLongitude),
              },
              // eslint-disable-next-line no-undef
              travelMode: google.maps.TravelMode.DRIVING,
            })
            .then((resultsDistanceDuration) => {
              // console.log(resultsDistanceDuration);
              setDirectionServiceResponse(resultsDistanceDuration);
              setCurrentEtaDistance(
                resultsDistanceDuration.routes[0].legs[0].distance.text
              );
              // console.log(
              //   resultsDistanceDuration.routes[0].legs[0].distance.text
              // );
              setCurrentEtaTime(
                resultsDistanceDuration.routes[0].legs[0].duration.text
              );
              // console.log(
              //   resultsDistanceDuration.routes[0].legs[0].duration.text
              // );
            });
        })
        .catch((e) => {
          console.log("Data could not be fetched", e);
        })
        .finally(() => {});
    };

    // console.log("fcuntion call...");
    functionCall();

    // calculateRoute();
    const interval = setInterval(() => {
      // console.log("fcuntion call interval set");
      functionCall();

      // calculateRoute();
      // can be changed to any time we want
    }, ONE_SECOND * 40);
    return () => {
      // console.log("fcuntion call interval release");
      clearInterval(interval);
    };
  }, [dispatch, selectedBidId, isLoaded, pickupLongitude, pickupLatitude]);

  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  const httpPolling = () => {
    fetch(`${BASE_URL}${RIDE_STATUS_GET}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: currentRideId,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((rideStatus) => {
        // console.log("Current ride Status", rideStatus);
        // dispatch(
        //   riderCurrentRideActions.setCurrentRideStatus(rideStatus?.Ride_Status)
        // );
        // window.history.pushState(
        //   null,
        //   null,
        //   `#rideStatusFetch=${JSON.stringify(rideStatus?.Ride_Status)}`
        // );
        if (rideStatus?.Ride_Status === RIDE_STATUS_IN_PROGRESS) {
          navigate(
            `${RIDER_RIDE_IN_PROGRESS_ROUTE}?rideId=${rideStatus.ride_id}&bidId=${rideStatus.bid_id}`,
            { replace: true }
          );
          // navigateToRideInProgress();
        }
        if (rideStatus?.Ride_Status === RIDE_STATUS_COMPLETED) {
          // move to ride completed
          // navigate to
          navigate(
            `${RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE}?rideId=${rideStatus.ride_id}&bidId=${rideStatus.bid_id}`
          );
        }
      })
      .catch((e) => {
        console.log("Error in retrieving ride status", e);
      });
  };

  useInterval(httpPolling, ONE_SECOND * 15);

  // eslint-disable-next-line no-unused-vars
  // const startRideHandler = () => {
  //   // console.log("Start ride function");

  //   const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  //   fetch(`${BASE_URL}${RIDER_START_RIDE}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //     body: JSON.stringify({
  //       ride_id: currentRideId,
  //     }),
  //   }).then((response) => {
  //     if (response.ok) {
  //       navigateToRideInProgress();
  //     }
  //   });
  // };

  const [rideOtpValue, setRideOtpValue] = useState("");

  useEffect(() => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const apiCallForOtp = async () => {
      try {
        const response = await fetch(`${BASE_URL}/ride/?filters=current_ride`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          // console.log("OTP call response Sucess");
          // console.log(data);
          if (data && data.hasOwnProperty("ride_otp")) {
            setRideOtpValue(data?.ride_otp);
            const pickupAddress = data?.pickup_address;
            const [pickupLatitude, pickupLongitude] = pickupAddress
              .split(",")
              .map(parseFloat);
            // console.log(pickupLatitude, pickupLongitude.toString());
            setPickupLatitude(pickupLatitude);
            setPickupLongitude(pickupLongitude);
          }
        }
      } catch (error) {
        console.log("Error message otp call: ");
        console.log(error);
      }
    };

    apiCallForOtp();
  }, []);

  const [currentEtaDistance, setCurrentEtaDistance] = useState("");
  const [currentEtaTime, setCurrentEtaTime] = useState("");

  const cancelButtonOnClickFunctionHandler = async () => {
    // console.log("Cancelling ride ...");

    // clear state for post ride
    dispatch(riderPostRideActions.clearPostRide());

    // API Call for
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const fetchUrl = `${BASE_URL}${RIDER_CANCEL_WITHOUT_LIABILITY}`;
    const fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: currentRideId,
        value: "OTP",
      }),
    };

    try {
      const response = await fetch(fetchUrl, fetchOptions);

      if (response.ok) {
        // console.log("Ride cancelled from server...");
        // console.log("Clearing local state...");
        // clear redux state for current ride
        dispatch(riderCreatedRideActions.clearCreateRideId());
        // console.log("Local state cleared ✔");
        // info notification
        // console.log("Sending info toast...");
        toast.info("Ride has been cancelled");
        // console.log("Toast sent ✔");
        // navigate to home screen for booking new ride
        // console.log("Navigating to home screen...");
        navigateToHomeScreen();
      }
    } catch (error) {
      console.log("Something went wrong. Printing error message...");
      console.log(error);
    }
  };

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  return (
    <MenuTextUserWrapper logoHeadingText="Meet at pick-up point">
      <div className={styles.rideArriving}>
        <LogsBottomModal
          // para={"This ride is not eligible for refund"}
          heading="Are you sure you want to cancel the ride ?"
          // heading="Cancel Ride ?"
          downloadButtonText={"Yes"}
          cancelButtonText={"No"}
          openLogDialogBox={isCancelDialogOpen}
          downloadLogsButton={() => {
            cancelButtonOnClickFunctionHandler();
          }}
          cancelButton={() => {
            setIsCancelDialogOpen(false);
          }}
        />
        <TopGreenCheckStatus
          displayText={`${
            !currentEtaTime && !currentEtaDistance
              ? "Arriving soon."
              : `Arriving in ${currentEtaTime} ( ${currentEtaDistance} away)`
          }`}
        />
        <NumberPlateCarNameRatingCard
          vehicleNumber={selectedBidDetailsWithNumber?.vehicleNumber}
          vehicleName={selectedBidDetailsWithNumber?.vehicleName}
          driverName={selectedBidDetailsWithNumber?.driverName}
          phoneNumber={selectedBidDetailsWithNumber?.phoneNumber}
          vehicleImage={selectedBidDetailsWithNumber?.vehicleImage}
          driverImage={selectedBidDetailsWithNumber?.driverImage}
          cancelOnClick={() => {
            setIsCancelDialogOpen(true);
          }}
        />
        <OtpAndPragraph
          price={selectedBidDetailsWithNumber.amount}
          otpNumber={rideOtpValue}
          pickUpAdress={(pickupLatitude, pickupLongitude)}
        />
        {/* <p style={{ fontSize: "16px", color: "white" }}>{currentEtaDistance}</p> */}
        {/* <p style={{ fontSize: "16px", color: "white" }}>{currentEtaTime}</p> */}
        {/* <GoogleMapComponent /> */}
        {/* <Button displayText="Start Ride" onClickFunction={startRideHandler} /> */}
        {isLoaded &&
          pickupLatitude &&
          pickupLongitude &&
          directionServiceResponse && (
            <GoogleMapArrivingSoonOtpDriverLocation
              mapCenterlatLng={{
                latitude: parseFloat(pickupLatitude),
                longitude: parseFloat(pickupLongitude),
              }}
              directionResponseList={[
                { id: 1, response: directionServiceResponse },
              ]}
              markerList={[
                {
                  id: 1,
                  position:
                    directionServiceResponse.routes[0].legs[0].end_location,
                  // latitude: pickupLatitude,
                  // longitude: pickupLongitude,
                  icon: images.startPointPinGreen,
                },
                {
                  id: 2,
                  position:
                    directionServiceResponse.routes[0].legs[0].start_location,
                  // latitude: driverLocation?.latitude,
                  // longitude: driverLocation?.longitude,
                  icon: images.startPointPin,
                },
              ]}
            />
          )}
      </div>
      <SosButton currentRideId={currentRideId} />
    </MenuTextUserWrapper>
  );
};

export default RideArriving;
