// testing URL
//
// export const BASE_URL = "https://api.taxiwars.in";
// export const GOOGLE_MAPS_API_KEY = "Create_Key_Not_Found_Error";
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const BASE_URL = process.env.REACT_APP_SERVER_URL;
export const WEB_SOCKET_BASE_URL_RIDER =
  process.env.REACT_APP_WEB_SOCKET_BASE_URL_RIDER;

// export const CCAVENUE_BASE_URL = process.env.REACT_APP_CCAVENUE_BASE_URL
export const CCAVENUE_BASE_URL_TEST = "https://test.ccavenue.com";
export const CCAVENUE_BASE_URL_LIVE = "https://secure.ccavenue.com";

export const RIDER_SIGN_UP = "/customer-signup/";
export const RIDER_GET_OTP = "/otp/";
export const RIDER_LOGIN_VERIFY_WITH_OTP = "/login/";
export const RIDER_PROFILE_DETAILS = "/customer_details/";
export const RIDER_CREATE_RIDE = "/ride/";
export const EXPORT_EMAIL = "/update_email/";
export const POST_POINTS = "/post_points/";

export const RIDER_FCM_TOKEN = "/fcm/";
export const RIDER_GET_FCM_TOKEN = "/getfcm/";

export const RIDER_RAZORPAY_PAYMENT_CREATE_ORDER = "/confirmbid/";
export const RIDER_RAZORPAY_PAYMENT_SUCCESS = "/payments/";
export const RIDER_PAYMENT_CASH_METHOD = "/cash-payment/";

export const RIDER_GET_ACCEPTED_RIDE_DETAILS = "/accepted-driver/";

export const RIDER_GET_HELP = "/customer-help/";

export const RIDER_PAST_RIDES = "/ride/?filters=all";
export const RIDER_ADV_PAST_RIDES = "/adv_ride_history/";

export const RIDER_START_RIDE = "/customer-start-ride/";
export const RIDER_CANCEL_WITHOUT_LIABILITY = "/cancel-ride/";

export const RIDER_DRIVER_AVAILABILITY = "/driver-available/";

export const RIDER_GET_CURRENT_RIDE_STATUS = "/ride/?filters=current_ride";

export const DRIVER_SIGN_UP = "/driver/signup/";
export const DRIVER_GET_OTP = "/driver/otp/";
export const DRIVER_LOGIN_VERIFY_WITH_OTP = "/driver/login/";
export const DRIVER_PROFILE_DETAILS = "/driver/details/";
export const DRIVER_CAR_DETAILS = "/driver/car/";

export const DRIVER_VIEW_ALL_RIDES = "/driver/rides/";
export const DRIVER_VIEW_ALL_BIDS = "/driver/bid/";

export const DRIVER_RAZORPAY_BID_SECURITY_CREATE_ORDER = "/driver/createorder/";
export const DRIVER_BID_SECURITY_RAZORPAY_PAYMENT_SUCCESS =
  "/driver/security-deposit/";

export const DRIVER_VIEW_ACCEPTED_CUSTOMER_BID = "/driver/accepted-customer/";

export const DRIVER_FCM_TOKEN = "/fcm/";
export const DRIVER_GET_FCM_TOKEN = "/getfcm/";
export const SEND_FCM_TOKEN = "/openfcm/";

export const DRIVER_GET_HELP = "/driver/help/";

export const DRIVER_START_RIDE = "/driver/start-ride/";
export const DRIVER_END_RIDE = "/driver/end-ride/";

export const DRIVER_GET_RIDE_STATUS = "/driver/driver-ride-status/";

export const CODE_502 = "502";
export const CODE_500 = "500";
export const CODE_200 = "200";
export const CODE_300 = "300";
export const CODE_301 = "301";
export const CODE_302 = "302";

export const RAZORPAY_CHECKOUT_V1_JS =
  "https://checkout.razorpay.com/v1/checkout.js";

export const RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;
// export const RAZORPAY_KEY_ID = "rzp_test_fhPo9Odgfta9R9";

export const RIDE_STATUS_BID_CONFIRMED = "Bid Confirmed";
export const RIDE_STATUS_IN_PROGRESS = "In-Progress";
export const RIDE_STATUS_COMPLETED = "Completed";
export const RIDE_STATUS_CANCELLED = "Cancelled";
export const RIDE_STATUS_ACTIVE = "Active";
export const RIDE_STATUS_PENDING = "Pending";
export const RIDE_STATUS_SCHEDULED = "Scheduled";

export const TERMS_AND_CONDITIONS = "/termsandconditons/";
export const REFUND_POLICY = "/refundpolicy/";
export const RIDE_STATUS_GET = "/ride-status/";
export const SEND_RATING = "/driver-rating/";
export const RIDE_DETAILS_COMPLETED = "/ride-completed-details/";
export const RIDE_EXTRA_SPENT_TOTAL_DETAILS = "/ride-spent-total/";

// SOS
export const RIDER_SOS = "/sos-help/";

// scheduled ride
export const GET_SCHEDULED_RIDE_DETAILS = "/schedule-ride/";

export const RIDE_DETAILS_FROM_RIDE_ID = "/ride-response/";
export const CC_AVENUE_STATUS_AFTER_UPI = "/cc/status";

export const DELETE_ACCOUT = "/deleteaccount/";

// advance booking api requierment
export const RIDER_CREATE_RIDE_ADVANCE_BOOKING = "/adv_booking/adv_ride/";
export const RIDER_GET_RIDE_DETAILS_ADVANCE_BOOKING =
  "/adv_booking/adv_ride_get/";
export const ADV_RIDER_CANCEL_RIDE = "/adv_booking/adv_ride_cancel/";
export const ADV_RIDER_CANCEL_WITHOUT_LIABILITY =
  "/adv_booking/adv_ride_cancel/";
export const ADV_RIDER_GET_ACCEPTED_RIDE_DETAILS =
  "/adv_booking/adv_acceppted_ride/";
export const ADV_RIDE_STATUS_GET = "/adv_booking/adv_ride_status/";
export const ADV_SEND_RATING = "/adv_booking/adv_ride_rating/";
export const ADV_RIDE_DETAILS_COMPLETED = "/adv_booking/adv_completed_ride/";
export const ADV_CC_AVENUE_REQUEST_HANDLER = "/cc/adv_ccavRequestHandler";
export const ADV_CC_AVENUE_STATUS_AFTER_UPI = "/cc/adv_status";
export const ADV_RIDER_GET_CURRENT_RIDE_STATUS =
  "/adv_booking/adv_ride/?filters=current_ride";
export const ADV_GET_PAYMENT_STATUS = "/adv_booking/adv_payment_status/";

// advance booking automate
export const RIDER_CREATE_RIDE_ADVANCE_BOOKING_AUTOMATE =
  "/adv_booking/adv_ride/";

export const RIDER_GET_BIDS_ADVANCE_BOOKING_AUTOMATE =
  "/adv_booking/adv_get_bids/?filters=all";

export const RIDER_PAYMENT_CASH_METHOD_ADVANCE_BOOKING_AUTOMATE =
  "/adv_booking/adv_cash/";
