import { useDispatch, useSelector } from "react-redux";
import { GOOGLE_MAPS_API_KEY } from "../../../constants/apiConstants";
import { ONE_SECOND } from "../../../constants/timeConstants";
import styles from "./PostRideFromAdvanceBooking.module.css";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useAdvRedirectAcc, useNavigateOnEvent } from "../../../hooks";
import { useEffect, useState } from "react";
import { riderPostRideAdvanceBookingActions } from "../../../store/slice";
import {
  AddressListFromSearch,
  BookRideWithBack,
  Button,
  GoogleMapComponent,
  GridFromToTextBox,
  HorizontalLine,
  SelectOnMap,
} from "../../../components";
import {
  ADV_POST_A_RIDE_MAP_VIEW_ROUTE,
  ADV_POST_A_RIDE_TO_ROUTE,
} from "../../../constants/routeConstants";

const PostRideFrom = () => {
  useAdvRedirectAcc();
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    debounce: ONE_SECOND,
    options: {
      componentRestrictions: { country: "IN" },
    },
  });
  const dispatch = useDispatch();
  const navigateBack = useNavigateOnEvent(ADV_POST_A_RIDE_TO_ROUTE, true);
  const navigateToOrderNow = useNavigateOnEvent(ADV_POST_A_RIDE_MAP_VIEW_ROUTE);
  const navigateToPostRideTo = useNavigateOnEvent(
    ADV_POST_A_RIDE_TO_ROUTE,
    true
  );

  const destinationAddressText = useSelector(
    (state) => state.riderPostRideAdvanceBooking.inputDestinationText
  );

  const originAddressText = useSelector(
    (state) => state.riderPostRideAdvanceBooking.inputOriginText
  );

  const [isMapSelectOpen, setIsMapSelectOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setSearchTerm(originAddressText.mainAddress);
  }, [originAddressText]);

  const inputChangeHandler = (e) => {
    setSearchTerm(e.target.value);
    // console.log(e.target.value);
    if (e.target.value.trim().length > 5) {
      // console.log(e.target.value);
      getPlacePredictions({ input: e.target.value });
    }
  };

  const selectAddressFromListHandler = (address) => {
    placesService.getDetails(
      {
        placeId: address.place_id,
        fields: ["geometry"],
      },
      (placeResult, status) => {
        if (status === "OK" && placeResult) {
          const {
            geometry: { location },
          } = placeResult;
          // console.log("Place details:", { location });
          // console.log("location", location.lat(), location.lng());

          const lat = location.lat();
          const lng = location.lng();
          dispatch(
            riderPostRideAdvanceBookingActions.updateOriginAddress({
              latitude: lat || "",
              longitude: lng || "",
            })
          );
        } else {
          console.log("Something went wrong");
        }
      }
    );
    dispatch(
      riderPostRideAdvanceBookingActions.updateOriginPlaceId(address.place_id)
    );
    dispatch(
      riderPostRideAdvanceBookingActions.updateOriginAddressText({
        mainAddress: address.structured_formatting.main_text || "",
        subAddress: address.structured_formatting.secondary_text || "",
      })
    );

    navigateToOrderNow();
  };

  const openMapHandler = () => {
    setIsMapSelectOpen(true);
  };

  const mapSelectProceedButtonHandler = () => {
    // After map dispatches the address to store
    navigateToOrderNow();
  };

  const originAddressOnFocus = () => {
    setIsMapSelectOpen(false);
  };

  const addressLiftUpHandler = (address, selectedLocation) => {
    dispatch(
      riderPostRideAdvanceBookingActions.updateOriginAddress({
        latitude: selectedLocation.lat || "",
        longitude: selectedLocation.lng || "",
      })
    );
    const mainText =
      address && address.substring(0, Math.ceil(address.length * 0.4)); // first 40% of the address
    const secondaryText =
      address && mainText && address.substring(mainText.length).trim(); // remaining 60% of the address
    dispatch(
      riderPostRideAdvanceBookingActions.updateOriginAddressText({
        mainAddress: mainText || "",
        subAddress: secondaryText || "",
      })
    );

    setSearchTerm(mainText + secondaryText);
  };

  const destinationInputOnClickHandler = () => {
    navigateToPostRideTo();
  };

  return (
    <div className={styles.postRideFrom}>
      <BookRideWithBack
        textHeading="Advance Booking"
        enableBackButton={true}
        backButtonFunction={navigateBack}
        textHeadingColorGreen
        fullGreenWhiteText
      />
      <GridFromToTextBox
        originAddress={searchTerm}
        originAddressOnFocus={originAddressOnFocus}
        originAddressOnChange={inputChangeHandler}
        destinationAddress={destinationAddressText.mainAddress}
        destinationInputDisabled
        destinationInputOnClick={destinationInputOnClickHandler}
      />
      <HorizontalLine />
      {!isMapSelectOpen && (
        <div onClick={openMapHandler}>
          <SelectOnMap />
        </div>
      )}
      {isMapSelectOpen && (
        <div className={styles.mapBox}>
          <GoogleMapComponent addressLiftUp={addressLiftUpHandler} />
          <Button
            displayText="Confirm Location"
            onClickFunction={mapSelectProceedButtonHandler}
          />
        </div>
      )}

      <div className={styles.list}>
        <AddressListFromSearch
          data={placePredictions}
          loading={isPlacePredictionsLoading}
          selectAddressFromListHandler={selectAddressFromListHandler}
        />
        <div></div>
      </div>
    </div>
  );
};

export default PostRideFrom;
