import { useDispatch } from "react-redux";
import {
  useInterval,
  useNavigateOnEvent,
  usePreventBackBehaviour,
} from "../../../hooks";
import styles from "./AssignedDriverAfterPaymentConfirmation.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  PACKAGE_12_HOUR,
  libs,
} from "../../../constants/storageConstants";
import Cookies from "js-cookie";
import {
  ADV_RIDER_CANCEL_WITHOUT_LIABILITY,
  ADV_RIDER_GET_ACCEPTED_RIDE_DETAILS,
  ADV_RIDER_GET_CURRENT_RIDE_STATUS,
  ADV_RIDE_STATUS_GET,
  BASE_URL,
  GOOGLE_MAPS_API_KEY,
  RIDE_STATUS_COMPLETED,
  RIDE_STATUS_IN_PROGRESS,
} from "../../../constants/apiConstants";
import { ONE_SECOND } from "../../../constants/timeConstants";
import { riderPostRideAdvanceBookingActions } from "../../../store/slice";
import { toast } from "react-toastify";
import {
  GoogleMapArrivingSoonOtpDriverLocation,
  LogsBottomModal,
  MenuTextUserWrapper,
  NumberPlateCarNameRatingCard,
  OtpAndPragraph,
  SosButton,
  TopGreenCheckStatus,
} from "../../../components";
import {
  ADV_POST_A_RIDE_TO_ROUTE,
  ADV_RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE,
  ADV_RIDER_RIDE_IN_PROGRESS_ROUTE,
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
} from "../../../constants/routeConstants";
import { images } from "../../../images";
import { useJsApiLoader } from "@react-google-maps/api";

const AssignedDriverAfterPaymentConfirmation = () => {
  usePreventBackBehaviour();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libs,
  });

  const [urlParams] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const rideId = searchParams.get("rideId");
    // console.log("Param ride id", rideId, "");
    return { rideId: rideId };
  });

  const [allotedDriverDetails, setAllotedDriverDetails] = useState({
    vehicleNumber: "",
    vehicleName: "",
    driverName: "",
    phoneNumber: "",
    driverImage: "",
    vehicleImage: "",
    amount: "",
    otp: "",
    returnType: false,
    pickupLatitude: "",
    pickupLongitude: "",
    rideDistance: "",
  });

  const [currentEtaDistance, setCurrentEtaDistance] = useState("");
  const [currentEtaTime, setCurrentEtaTime] = useState("");

  const [directionServiceResponse, setDirectionServiceResponse] = useState("");

  const navigateToHomeScreen = useNavigateOnEvent(ADV_POST_A_RIDE_TO_ROUTE);

  const currentRideId = urlParams.rideId;

  useEffect(() => {
    const functionCall = () => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(`${BASE_URL}${ADV_RIDER_GET_ACCEPTED_RIDE_DETAILS}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ ride_id: parseInt(currentRideId) }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          // console.log("Selected bid details with number -", data);
          setAllotedDriverDetails({
            vehicleNumber: data?.registration_number,
            vehicleName: data?.car_name,
            driverName: data?.name,
            phoneNumber: data?.phone_number,
            driverImage: data?.driver_photo,
            vehicleImage: data?.car_photo,
            amount: data?.amount,
            otp: data?.otp,
            returnType: data?.return_type,
            pickupLatitude: data?.pickup_point_latitude,
            pickupLongitude: data?.pickup_point_longitude,
            rideDistance: data?.ride_distance,
          });
        })
        .catch((e) => {
          console.log("Data could not be fetched", e);
        })
        .finally(() => {});
    };
    functionCall();

    const interval = setInterval(() => {
      functionCall();
    }, ONE_SECOND * 30);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

      if (!accessToken) {
        return; // If access token is not available, don't proceed with the fetch
      }

      try {
        const response = await fetch(
          `${BASE_URL}${ADV_RIDER_GET_CURRENT_RIDE_STATUS}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          setData(responseData);
        }
      } catch (error) {
        console.error("Error fetching ride status:", error);
      }
    };

    fetchData();
  }, []);

  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  const httpPolling = () => {
    fetch(`${BASE_URL}${ADV_RIDE_STATUS_GET}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: parseInt(currentRideId),
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((rideStatus) => {
        if (rideStatus?.Ride_Status === RIDE_STATUS_IN_PROGRESS) {
          navigate(
            `${ADV_RIDER_RIDE_IN_PROGRESS_ROUTE}?rideId=${rideStatus.ride_id}`,
            { replace: true }
          );
          // navigateToRideInProgress();
        }
        if (rideStatus?.Ride_Status === RIDE_STATUS_COMPLETED) {
          // move to ride completed
          // navigate to
          navigate(
            `${ADV_RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE}?rideId=${rideStatus.ride_id}`
          );
        }
        //
        if (!isLoaded) return;
        if (
          !parseFloat(allotedDriverDetails?.pickupLatitude) ||
          !parseFloat(allotedDriverDetails?.pickupLongitude)
        ) {
          return;
        }

        // eslint-disable-next-line no-undef
        const directionService = new google.maps.DirectionsService();
        if (!directionService) return;

        directionService
          .route({
            origin: {
              lat: parseFloat(rideStatus?.driver_lat),
              lng: parseFloat(rideStatus?.driver_lng),
            },
            destination: {
              lat: parseFloat(allotedDriverDetails?.pickupLatitude),
              lng: parseFloat(allotedDriverDetails?.pickupLongitude),
            },
            // eslint-disable-next-line no-undef
            travelMode: google.maps.TravelMode.DRIVING,
          })
          .then((resultsDistanceDuration) => {
            // console.log(resultsDistanceDuration);
            setDirectionServiceResponse(resultsDistanceDuration);
            setCurrentEtaDistance(
              resultsDistanceDuration.routes[0].legs[0].distance.text
            );
            // console.log(
            //   resultsDistanceDuration.routes[0].legs[0].distance.text
            // );
            setCurrentEtaTime(
              resultsDistanceDuration.routes[0].legs[0].duration.text
            );
            // console.log(
            //   resultsDistanceDuration.routes[0].legs[0].duration.text
            // );
          });
      })
      .catch((e) => {
        console.log("Error in retrieving ride status", e);
      });
  };

  useInterval(httpPolling, ONE_SECOND * 30);

  const cancelButtonOnClickFunctionHandler = async () => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const fetchUrl = `${BASE_URL}${ADV_RIDER_CANCEL_WITHOUT_LIABILITY}`;
    const fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: parseInt(currentRideId),
        value: "OTP",
      }),
    };

    try {
      const response = await fetch(fetchUrl, fetchOptions);

      if (response.ok) {
        dispatch(riderPostRideAdvanceBookingActions.clearPostRide());
        toast.info("Ride has been cancelled");
        navigateToHomeScreen();
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Something went wrong!");
      console.log("Something went wrong. Printing error message...");
      console.log(error);
    }
  };

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  function formatDateLabel(dateString) {
    // Check if dateString is undefined or null
    if (!dateString) {
      return "...";
    }

    // If dateString is not in the expected format, return it as is
    if (!dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return dateString;
    }

    const currentDate = new Date();
    const targetDate = new Date(dateString);

    // Get the year, month, and day of the current date
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    // Get the year, month, and day of the target date
    const targetYear = targetDate.getFullYear();
    const targetMonth = targetDate.getMonth();
    const targetDay = targetDate.getDate();

    // Check if the target date is today
    if (
      currentYear === targetYear &&
      currentMonth === targetMonth &&
      currentDay === targetDay
    ) {
      return "Today";
    }

    // Calculate tomorrow's date
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(currentDate.getDate() + 1);

    // Check if the target date is tomorrow
    if (
      targetYear === tomorrowDate.getFullYear() &&
      targetMonth === tomorrowDate.getMonth() &&
      targetDay === tomorrowDate.getDate()
    ) {
      return "Tomorrow";
    }

    // If the date is not today or tomorrow, return the formatted date
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  function convertTo12HourFormat(time24) {
    // Check if time24 is defined and is a valid string
    if (typeof time24 !== "string" || !time24.match(/^\d{2}:\d{2}:\d{2}$/)) {
      return "...";
    }
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);

    // Determine if it's AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours from 24-hour to 12-hour format
    const hours12 = hours % 12 || 12;

    // Format the time in 12-hour format
    const time12 = `${hours12}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return time12;
  }

  const timeDifferenceInMinutes = () => {
    const currentTime = new Date();
    const scheduledDateTime = new Date(
      `${data?.schedule_date}T${data?.schedule_time}`
    );

    return Math.round(
      (scheduledDateTime.getTime() - currentTime.getTime()) / (1000 * 60)
    );
  };

  return (
    <MenuTextUserWrapper
      logoHeadingText="Advance Booking - Driver Details"
      bgGreenTextWhite
    >
      <div className={styles.rideArriving}>
        <LogsBottomModal
          // para={"This ride is not eligible for refund"}
          heading=" Are you sure you want to cancel the ride ?"
          // heading="Cancel Ride ?"
          downloadButtonText={"Yes"}
          cancelButtonText={"No"}
          openLogDialogBox={isCancelDialogOpen}
          downloadLogsButton={() => {
            cancelButtonOnClickFunctionHandler();
          }}
          cancelButton={() => {
            setIsCancelDialogOpen(false);
          }}
        />
        {timeDifferenceInMinutes() < 60 ? (
          <TopGreenCheckStatus
            displayText={`${
              !currentEtaTime && !currentEtaDistance
                ? "Arriving soon."
                : `Arriving in ${currentEtaTime} (${currentEtaDistance} away)`
            }`}
          />
        ) : (
          data && (
            <>
              <TopGreenCheckStatus
                displayText={`Your Ride scheduled ${formatDateLabel(
                  data?.schedule_date
                )} at ${convertTo12HourFormat(data?.schedule_time)}`}
              />
              <p
                style={{
                  fontSize: "1.5rem",
                }}
              >
                Driver will arrive at scheduled time
              </p>
            </>
          )
        )}
        {/* <TopGreenCheckStatus
          displayText={`${
            allotedDriverDetails.driverName
              ? "Your driver has been assigned"
              : `Your driver will be assigned soon`
          }`}
        /> */}
        {
          <NumberPlateCarNameRatingCard
            vehicleNumber={
              allotedDriverDetails?.vehicleNumber || "Driver Not Assigned"
            }
            vehicleName={allotedDriverDetails?.vehicleName || ""}
            driverName={allotedDriverDetails?.driverName || ""}
            phoneNumber={allotedDriverDetails?.phoneNumber || "NA"}
            vehicleImage={allotedDriverDetails?.vehicleImage}
            driverImage={
              allotedDriverDetails?.driverImage || images.userGeneric
            }
            cancelOnClick={() => {
              setIsCancelDialogOpen(true);
            }}
          />
        }{" "}
        {
          <OtpAndPragraph
            price={allotedDriverDetails.amount}
            otpNumber={allotedDriverDetails.otp}
          />
        }
        {isLoaded &&
          allotedDriverDetails?.pickupLatitude &&
          allotedDriverDetails?.pickupLongitude &&
          directionServiceResponse && (
            <GoogleMapArrivingSoonOtpDriverLocation
              mapCenterlatLng={{
                latitude: parseFloat(allotedDriverDetails?.pickupLatitude),
                longitude: parseFloat(allotedDriverDetails?.pickupLongitude),
              }}
              directionResponseList={[
                { id: 1, response: directionServiceResponse },
              ]}
              markerList={[
                {
                  id: 1,
                  position:
                    directionServiceResponse.routes[0].legs[0].end_location,
                  // latitude: pickupLatitude,
                  // longitude: pickupLongitude,
                  icon: images.startPointPinGreen,
                },
                {
                  id: 2,
                  position:
                    directionServiceResponse.routes[0].legs[0].start_location,
                  // latitude: driverLocation?.latitude,
                  // longitude: driverLocation?.longitude,
                  icon: images.startPointPin,
                },
              ]}
            />
          )}
      </div>
      {allotedDriverDetails.returnType ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0rem",
          }}
        >
          <p
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "3rem",
            }}
          >
            {`${PACKAGE_12_HOUR} (2 x ${
              allotedDriverDetails?.rideDistance
            }) + 10 = ${
              2 * allotedDriverDetails?.rideDistance.split(" ")[0]
            } Kms considered`}
          </p>
          {console.log(allotedDriverDetails)}
          <p
            style={{
              fontSize: "1.2rem",
              // color: "red",
              textAlign: "center",
              // width: "75%",
              marginTop: "3rem",
            }}
          >
            *Extra Hrs Mini- ₹200/hr, Sedan- ₹215/hr, Suv- ₹260/hr
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "3rem",
            }}
          >
            *Extra kms Mini- ₹10/km, Sedan- ₹12/km, Suv- ₹14/km
          </p>
        </div>
      ) : (
        <></>
      )}
      <SosButton currentRideId={currentRideId} />
      <BackToHome />
    </MenuTextUserWrapper>
  );
};

export default AssignedDriverAfterPaymentConfirmation;

const BackToHome = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        zIndex: 5,
        position: "absolute",
        bottom: "calc(4rem + 75px)",
        right: "3rem",
        fontSize: "1.8rem",
      }}
      className={`${styles.floatingAction} ${styles.right}`}
    >
      <button
        onClick={() => {
          navigate(`${RIDER_HOME_POST_A_RIDE__TO_ROUTE}`);
        }}
        style={{
          border: "none",
          background: "none",
          // padding: 0,
          margin: 0,
          font: "inherit",
          cursor: "pointer",
          // outline: "inherit",

          /* Custom */
          backgroundColor: "#419c00",
          padding: "1.6rem",
          outline: "none",
          borderRadius: "30px",
          minWidth: "130px",
          color: "#fff",
          fontWeight: " bold",
          // -webkit-tap-highlight-color: transparent;
          /* transition: background-color 0.3s ease-in; */
          userSelect: "none",
        }}
        className={styles.floatingActionButton}
      >
        <p
          style={{
            fontSize: "1.6rem",
          }}
          className=""
        >
          Book Current Ride
        </p>
        <p
          style={{
            fontSize: "1.4rem",
          }}
        >
          {/* (Outstation) */}
        </p>
      </button>
    </div>
  );
};
