import { useEffect } from "react";
import { requestForToken } from "../firebase";
import Cookies from "js-cookie";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  USER_LOCAL_STORAGE,
} from "../constants/storageConstants";
import { RIDER_FCM_TOKEN } from "../constants/apiConstants";
import { getMobileOperatingSystem } from "../lib";

const useNotificationToken = () => {
  useEffect(() => {
    if (getMobileOperatingSystem() !== "iOS") return;
    console.log("token sent");

    const sendNotificationTokenToServer = async ({ token }) => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

      try {
        const response = await fetch(RIDER_FCM_TOKEN, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json", // Set the appropriate content type
          },
          body: JSON.stringify({
            user_id:
              JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE))?.user_id ||
              undefined,
            fcm: token,
          }),
        });

        if (!response.ok) {
          throw new Error(`Error while sending the token: ${response.status}`);
        }
      } catch (error) {
        console.log("Error while sending the token:", error);
      }
    };

    const getTokenAndUseIt = async () => {
      try {
        const token = await requestForToken();
        sendNotificationTokenToServer({ token });
        // Perform other actions with the token
      } catch (error) {
        console.error("Error while getting the token:", error);
      }
    };

    getTokenAndUseIt();
  }, []);

  return null;
};

export { useNotificationToken };
