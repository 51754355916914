import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TopGreenCheckStatus,
  BackPageHeadingWrapper,
  TotalFareMakePaymentCard,
  Button,
  BottomMessagePara,
  ErrorInNumber,
  LoadingSpinner,
} from "../../../components";
import {
  BASE_URL,
  CODE_200,
  RAZORPAY_CHECKOUT_V1_JS,
  RAZORPAY_KEY_ID,
  RIDER_CANCEL_WITHOUT_LIABILITY,
  RIDER_DRIVER_AVAILABILITY,
  RIDER_PROFILE_DETAILS,
  RIDER_RAZORPAY_PAYMENT_CREATE_ORDER,
  RIDER_RAZORPAY_PAYMENT_SUCCESS,
} from "../../../constants/apiConstants";
import {
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
  RIDER_RIDE_ARRIVING_ROUTE,
} from "../../../constants/routeConstants";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  NULL,
  UNDEFINED,
  USER_LOCAL_STORAGE,
} from "../../../constants/storageConstants";
import { useNavigateOnEvent } from "../../../hooks";
import { images } from "../../../images";

// eslint-disable-next-line
import styles from "./MakePayment.module.css";
import { toast } from "react-toastify";
import { riderCreatedRideActions } from "../../../store/slice";
import { RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE } from "../../../constants/routeConstants";
import { useNavigate } from "react-router-dom";

const MakePayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [customerPaymentAssurance, setCustomerPaymentAssurance] =
    useState(false);
  const [paymentLoadingSpinedMessage, setPaymentLoadingSpinedMessage] =
    useState(false);

  const [paymentLoadingWithoutMessage, setPaymentLoadingWithoutMessage] =
    useState(false);
  // const [userDataFromLocal, setUserDataFromLocal] = useState({});

  const [urlParams] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const rideId = searchParams.get("rideId");
    const bidId = searchParams.get("bidId");
    // console.log("Param ride id", rideId);
    return { rideId: rideId, bidId: bidId };
  });

  // const selectedBidDetails = useSelector(
  //   (state) => state.riderBid.selectedBidDetails
  // );

  const [selectedBidDetails, setSelectedBidDetails] = useState({
    bidId: "",
    driverName: "",
    amount: "",
    carName: "",
    carRegistrationNumber: "",
    carPhoto: "",
    driverPhoto: "",
    etaTime: "",
    driverRating: "",
  });

  const getBidDetails = async () => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const searchParams = new URLSearchParams(window.location.search);
    const bidId = searchParams.get("bidId");

    const url = `${BASE_URL}${"/bid-response/"}`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        bid_id: bidId,
      }),
    };

    const response = await fetch(url, options);

    if (response.ok) {
      const data = await response.json();

      console.log(data);
      setSelectedBidDetails({
        bidId: data?.bid_id,
        driverName: data?.driver_name,
        amount: data?.amount,
        carName: data?.car_name,
        carRegistrationNumber: data?.car_registration_number,
        carPhoto: data?.car_photo,
        driverPhoto: data?.driver_photo,
        etaTime: data?.eta_time,
        driverRating: data?.driver_rating,
        driverOnlinePayment: data?.customer_online_payment,
      });
    }
  };

  useEffect(() => {
    getBidDetails();
  }, []);

  const createdRideId = urlParams.rideId;

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  // const navigateToRideArrivingScreen = useNavigateOnEvent(
  //   RIDER_RIDE_ARRIVING_ROUTE
  // );

  const navigateToHomeScreen = useNavigateOnEvent(
    RIDER_HOME_POST_A_RIDE__TO_ROUTE
  );

  // const navigateToWaitingForBidScreen = useNavigateOnEvent(
  //   RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE,
  //   true
  // );
  // const [orderData, setOrderData] = useState();

  async function onClickPaymentAndNavigationHandler() {
    // checking driver availbality
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

    // fetch driver available url and options
    const urlDriverAvailable = `${BASE_URL}${RIDER_DRIVER_AVAILABILITY}`;
    const driverAvailableFetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        bid_id: `${selectedBidDetails.bidId}`,
      }),
    };

    const responseDriverAvailable = await fetch(
      urlDriverAvailable,
      driverAvailableFetchOptions
    );

    const dataDriverAvailable = await responseDriverAvailable.json();

    const urlForCustomerDetails = `${BASE_URL}${RIDER_PROFILE_DETAILS}`;
    const optionsGetCustomerDetails = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const customerDetailsResponse = await fetch(
      urlForCustomerDetails,
      optionsGetCustomerDetails
    );

    const customerData = await customerDetailsResponse.json();

    if (dataDriverAvailable && dataDriverAvailable?.driver_available === true) {
      // console.log("DATA - dataDriverAvailable");
      // console.log(dataDriverAvailable);
      // setPaymentLoadingSpinedMessage(true);
      setPaymentLoadingWithoutMessage(true);
      const res = await loadScript(RAZORPAY_CHECKOUT_V1_JS);

      if (!res) {
        // alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      // create order - call to backend

      fetch(`${BASE_URL}${RIDER_RAZORPAY_PAYMENT_CREATE_ORDER}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        method: "POST",
        body: JSON.stringify({
          bid_id: `${selectedBidDetails.bidId}`,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          // console.log("Something went wrong - Network error");
          throw response;
        })
        .then((data) => {
          // remember to change the property name checking to a constant
          if (data) {
            // setOrderData(data);
            // console.log("The data from raxor pay create order", data);
            const options = {
              key: RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
              amount: data?.amount,
              currency: "INR",
              name: "Taxiwars",
              description: "Booking Ride",
              image: images.splashScreenTaxiwarsLogo,
              order_id: data?.order_id,
              handler: async function (response) {
                const data1 = {
                  // orderCreationId: orderData?.order_id,
                  // debug_key: "",
                  payment_id: response.razorpay_payment_id,
                  amount: data?.amount,
                  user_id:
                    JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE))
                      ?.user_id || undefined,
                  ride_id: createdRideId,
                  bid_id: `${selectedBidDetails.bidId}`,
                  // razorpayOrderId: response.razorpay_order_id,
                  // razorpaySignature: response.razorpay_signature,
                };
                setPaymentLoadingSpinedMessage(true);
                // console.log(
                //   "RazorPat Payment id",
                //   response.razorpay_payment_id
                // );
                // console.log(
                //   "Data sending for confirmation for payment success",
                //   data1
                // );
                // const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
                fetch(`${BASE_URL}${RIDER_RAZORPAY_PAYMENT_SUCCESS}`, {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                  },
                  method: "POST",
                  body: JSON.stringify(data1),
                })
                  .then((response) => {
                    if (response.ok) {
                      return response.json();
                    }
                    // console.log("Something went wrong - Network error");
                    throw response;
                  })
                  .then((data) => {
                    // remember to change the property name checking to a constant
                    // console.log("Success payment data", data);
                    if (data && data.Code === CODE_200) {
                      // setOrderData(data);
                      // console.log("Success", data);
                      toast.success("Payment Success");
                      // navigateToDriverHomeViewAllBids();
                      setPaymentLoadingSpinedMessage(true);

                      navigate(
                        `${RIDER_RIDE_ARRIVING_ROUTE}?rideId=${data.ride_id}&bidId=${data.bid_id}`
                      );
                      // navigateToRideArrivingScreen();
                      return;
                    } else {
                      setCustomerPaymentAssurance(true);
                      toast.error("Something went wrong");
                      // console.log("Error", data);
                      // navigateToDriverCompleteProfile();
                    }
                  })
                  .catch((e) => {
                    toast.error("Something went wrong");
                    // setIsNetworkError(true);
                    setCustomerPaymentAssurance(true);
                    console.log("Catch error 1 - ", e.json());
                    console.log("Catch error 2 - ", e);
                  });

                // const result = await axios.post(
                //   "http://localhost:5000/payment/success",
                //   data
                // );

                // alert(result.data.msg);
              },
              prefill: {
                name: `${customerData?.first_name} ${customerData?.last_name}`,
                email: `${customerData?.email}`,
                contact: `${customerData?.phone_number}`,
              },
              notes: {
                bidid: `${selectedBidDetails.bidId}`,
                rideid: createdRideId,
              },
              theme: {
                color: "#000",
              },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
            // navigateToDriverHomeViewAllBids();
            return;
          } else {
            console.log("Error", data);
            // navigateToDriverCompleteProfile();
          }
        })
        .catch((e) => {
          // setIsNetworkError(true);
          console.log("Catch error 3 - ", e);
        })
        .finally(() => {
          // setIsSending(false);
          setPaymentLoadingSpinedMessage(false);
        });

      // order data destructuring
      // console.log(orderData);
    } else if (
      dataDriverAvailable &&
      dataDriverAvailable?.driver_available === false
    ) {
      // console.log("DATA - dataDriverAvailable");
      // console.log(dataDriverAvailable);
      // message to user driver not availabl - toast
      toast.error("Driver unavailable, Select another bid");

      // navigate to waitng bid screen
      // navigateToWaitingForBidScreen();
      navigate(
        `${RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE}?rideId=${urlParams.rideId}`
      );
    } else {
      // console.log("Both cases not appplicable, Data received - ");
      // console.log(dataDriverAvailable);
    }
  }

  const onCancelClickHandler = async () => {
    // console.log("Cancelling ride ...");
    // API Call for
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const fetchUrl = `${BASE_URL}${RIDER_CANCEL_WITHOUT_LIABILITY}`;
    const fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: createdRideId,
      }),
    };

    try {
      const response = await fetch(fetchUrl, fetchOptions);

      if (response.ok) {
        // console.log("Ride cancelled from server...");
        // console.log("Clearing local state...");
        // clear redux state for current ride
        dispatch(riderCreatedRideActions.clearCreateRideId());
        // console.log("Local state cleared ✔");
        // info notification
        // console.log("Sending info toast...");
        toast.info("Ride has been cancelled");
        // console.log("Toast sent ✔");
        // navigate to home screen for booking new ride
        // console.log("Navigating to home screen...");
        navigateToHomeScreen();
      }
    } catch (error) {
      console.log("Something went wrong. Printing error message...");
      console.log(error);
    }
  };

  // console.log(selectedBidDetails);
  return (
    <BackPageHeadingWrapper logoHeadingText="Make payment">
      <div className={styles.makePayment}>
        <TopGreenCheckStatus displayText="Pay and confirm your ride." />
        {selectedBidDetails && selectedBidDetails.bidId && (
          <TotalFareMakePaymentCard
            bidId={selectedBidDetails.bidId}
            driverName={selectedBidDetails.driverName}
            driverRating={selectedBidDetails.driverRating}
            price={selectedBidDetails.amount}
            vehicleName={selectedBidDetails.carName}
            vehicleNumber={selectedBidDetails.carRegistrationNumber}
            timeToReach={selectedBidDetails.etaTime}
            vehicleImage={selectedBidDetails.carPhoto}
            driverImage={selectedBidDetails.driverPhoto}
            onCancelClick={onCancelClickHandler}
          />
        )}
        {(paymentLoadingWithoutMessage || paymentLoadingSpinedMessage) && (
          <LoadingSpinner />
        )}
        {paymentLoadingSpinedMessage && (
          <>
            <BottomMessagePara displayText="Please wait while we confirm your payment from our end." />
          </>
        )}

        {/* <GoogleMapComponent /> */}
        {customerPaymentAssurance && (
          <>
            <BottomMessagePara displayText="Your money has been refunded to you." />
            <BottomMessagePara displayText="Bank will credit your amount within a day." />
          </>
        )}
        {/* <BottomMessagePara displayText="Don't worry, Your money, if any will be refunded back to you within 3 days." /> */}
        <ErrorInNumber displayText="" />
        <div className={styles.nominalFeeButton}>
          <div className={styles.infoBar}>
            <span>
              ₹{" "}
              {parseFloat(
                selectedBidDetails?.driverOnlinePayment / 100
              ).toFixed(1)}
            </span>
            <span>Nominal fee</span>
          </div>
          <Button
            displayText="Proceed to Pay"
            onClickFunction={onClickPaymentAndNavigationHandler}
          />
        </div>
        <BottomMessagePara displayText="Please pay the booking amount and confirm your ride. Pay the remaining amount after arriving at the destination." />
      </div>
    </BackPageHeadingWrapper>
  );
};

export default MakePayment;
