import React from "react";
import classes from "./LoadingSpinner.module.css";

const LoadingSpinner = ({ useLargeSpinner = false }) => {
  return (
    <div
      className={`${!useLargeSpinner ? classes.spinner : classes.largeSpinner}`}
      // style={{}}
    ></div>
  );
};

export default LoadingSpinner;
