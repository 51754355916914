import React from "react";
import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./NumberPlateCarNameRatingCard.module.css";

const NumberPlatecarNameRatingCard = ({
  vehicleNumber,
  vehicleName,
  driverName,
  driverImage = images.userGeneric,
  phoneNumber,
  vehicleImage = images.cab,
  cancelRideShow = true,
  cancelOnClick = () => {
    console.log("Not Connected");
  },
}) => {
  return (
    <div className={styles.numberPlateCarNameRatingCard}>
      <div className={styles.upperPart}>
        <div className={styles.writtenContent}>
          <div className={styles.numberPlateCarName}>
            <p>{vehicleNumber}</p>
            <p>{vehicleName}</p>
          </div>
          <div className={styles.nameRatingCarDetails}>
            <p>
              {driverName}
              <span>
                | <img src={images.orangeStar} alt="orange star" />{" "}
              </span>
            </p>
          </div>
        </div>
        <div className={styles.imageContent}>
          <img
            className={styles.cab}
            src={vehicleImage || images.cab}
            alt="car"
          />
          <img
            className={styles.userGeneric}
            src={driverImage || images.userGeneric}
            alt="user profile"
          />
        </div>
      </div>
      <hr className={styles.hrDivider} />
      <div className={styles.lowerPart}>
        <button>
          <a
            style={{ textDecoration: "none", color: "#000", fontSize: "1.5rem" }}
            href={`tel:${phoneNumber}`}
          >
            <span className={styles.flashingText} style={{fontSize:"2.5rem",fontWeight:"700"}}> Call Driver-</span>{" "}
            {phoneNumber}
          </a>
        </button>
        {cancelRideShow && (
          <>
            <hr />
            {/* <div></div> */}
          </>
        )}
        {cancelRideShow && <button onClick={cancelOnClick}>Cancel Ride</button>}
      </div>
    </div>
  );
};

export default NumberPlatecarNameRatingCard;
