import React from "react";
// eslint-disable-next-line
import styles from "./BottomMessagePara.module.css";

const BottomMessagePara = ({
  displayText = "para-BottomMessagePara",
  customStyle,
}) => {
  return (
    <div className={styles.displayText} style={customStyle}>
      {displayText}
    </div>
  );
};

export default BottomMessagePara;
