import { useEffect } from "react";
import Cookies from "js-cookie";
import {
  BASE_URL,
  RIDER_GET_CURRENT_RIDE_STATUS,
  RIDE_STATUS_ACTIVE,
  RIDE_STATUS_BID_CONFIRMED,
  RIDE_STATUS_COMPLETED,
  RIDE_STATUS_IN_PROGRESS,
  RIDE_STATUS_PENDING,
} from "../constants/apiConstants";
import { ACCESS_TOKEN_COOKIE_NAME } from "../constants/storageConstants";
import { useDispatch } from "react-redux";
import {
  riderBidActions,
  riderCreatedRideActions,
  riderPostRideActions,
} from "../store/slice";
import { useNavigateOnEvent } from "./use-navigateOnEvent";
import {
  RIDER_RIDE_ARRIVING_ROUTE,
  RIDER_RIDE_IN_PROGRESS_ROUTE,
  RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE,
} from "../constants/routeConstants";
import { useNavigate } from "react-router-dom";

function useRedirectAccToRide() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    //

    const getCurrentRideAndNavigate = async () => {
      //

      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      const fetchUrl = `${BASE_URL}${RIDER_GET_CURRENT_RIDE_STATUS}`;
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(fetchUrl, fetchOptions);

      if (!response.ok) {
        // console.log(fetchUrl, "responsenot ok");
        throw response;
      }

      const data = await response.json();
      // console.log(fetchUrl, "response ok");

      if (!data && !data?.id) {
        // console.log(data);
        return;
      }

      if (data === "No current ride.") {
        // console.log(data);
        return;
      }

      // we recieve ride id in "id" key field and customer id in "customer" key field
      const rideId = data?.id;
      const bidId = data?.bid_id;

      // active + pending = wait for bid screen
      if (
        data?.ride_status === RIDE_STATUS_ACTIVE ||
        data?.ride_status === RIDE_STATUS_PENDING
      ) {
        // navigate to Waiting For Bid Screen
        navigate(
          `${RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE}?rideId=${rideId}&bidId=${bidId}`,
          { replace: true }
        );
      }

      // RIDE_STATUS_BID_CONFIRMED and RIDE_STATUS_IN_PROGRESS
      // may seem redundant but they are necessary as in future their behaviour may change
      // so working on seperate iff's is the right way to go
      // bid confirmed = OTP display screen / ride arriving screen
      if (data?.ride_status === RIDE_STATUS_BID_CONFIRMED) {
        // clearing create ride actions
        dispatch(riderPostRideActions.clearPostRide());

        // navigateToRideArrivingScreen();
        navigate(
          `${RIDER_RIDE_ARRIVING_ROUTE}?rideId=${rideId}&bidId=${bidId}`,
          { replace: true }
        );
        // other data like number etc is being drawn from caling the api on page itself
      }

      if (data?.ride_status === RIDE_STATUS_IN_PROGRESS) {
        // clearing create ride actions
        dispatch(riderPostRideActions.clearPostRide());

        navigate(
          `${RIDER_RIDE_IN_PROGRESS_ROUTE}?rideId=${rideId}&bidId=${bidId}`,
          { replace: true }
        );
      }
    };

    getCurrentRideAndNavigate();
  }, []);
}

export { useRedirectAccToRide };
