import React from "react";

import { LoadingSpinner, VisitedHistoryList } from "../../components";

const AddressListFromSearch = ({
  loading,
  error,
  data,
  selectAddressFromListHandler,
}) => {
  return (
    <>
      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {data?.map((eachAddress) => (
              <div
                key={eachAddress.place_id}
                onClick={() => selectAddressFromListHandler(eachAddress)}
              >
                <VisitedHistoryList
                  addressLineOne={eachAddress.structured_formatting.main_text}
                  addressLineTwo={
                    eachAddress.structured_formatting.secondary_text
                  }
                  showLocationIcon
                />
              </div>
            ))}
            {/* {!loading && data && data.length !== 0 && (
              <VisitedHistoryList
                addressLineOne={"Please select from Map Above"}
                addressLineTwo={"Search Not accesible"}
                showLocationIcon
              />
            )} */}
          </>
        )}
      </div>
    </>
  );
};

export default AddressListFromSearch;
