import { createSlice } from "@reduxjs/toolkit";

const riderAuthSlice = createSlice({
  name: "riderAuth",
  initialState: {
    inputNumber: undefined,
    otp: "",
    isRegistered: false,
  },
  reducers: {
    updateInputMobileNumber(state, action) {
      state.inputNumber = action.payload;
    },
    otpInputBox(state, action) {
      state.otp = action.payload;
    },
    clearOTP(state) {
      state.otp = null;
    },
    setIsRegisteredTrue(state) {
      state.isRegistered = true;
    },
    setIsRegisteredFalse(state) {
      state.isRegistered = false;
    },
  },
});

export const riderAuthActions = riderAuthSlice.actions;
export default riderAuthSlice;
