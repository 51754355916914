import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { GOOGLE_MAPS_API_KEY } from "../../constants/apiConstants";
import { images } from "../../images";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
// eslint-disable-next-line
import styles from "./GoogleMapComponentPolyLineOriginDestination.module.css";
import { useCallback } from "react";
import { libs } from "../../constants/storageConstants";

const GoogleMapComponentPolyLineOriginDestination = ({
  distanceAndTimeLiftUp,
}) => {
  const origin = useSelector((state) => state.riderPostRide.inputOrigin);
  const destination = useSelector(
    (state) => state.riderPostRide.inputDestination
  );
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libs,
  });

  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [driectionServiceState, setDriectionServiceState] = useState();

  useEffect(() => {
    while (!isLoaded) return;

    // eslint-disable-next-line no-undef
    const directionService = () => new google.maps.DirectionsService();
    setDriectionServiceState(directionService);
  }, [isLoaded]);

  const calculateRoute = useCallback(async () => {
    // console.log("Origin- ", { lat: origin?.latitude, lng: origin?.longitude });
    // console.log("Destination- ", {
    //   lat: destination?.latitude,
    //   lng: destination?.longitude,
    // });
    if (!driectionServiceState) return;
    if (!origin.latitude) return;
    if (!origin.longitude) return;
    if (!destination.latitude) return;
    if (!destination.longitude) return;

    const results = await driectionServiceState.route({
      origin: { lat: origin?.latitude, lng: origin?.longitude },
      destination: {
        lat: destination?.latitude,
        lng: destination?.longitude,
      },
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
    // console.log(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
  }, [driectionServiceState, origin, destination]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    // const directionService = () => new google.maps.DirectionsService();
    while (!isLoaded) return;
    calculateRoute();
    distanceAndTimeLiftUp(distance, duration);
  }, [calculateRoute, distanceAndTimeLiftUp, distance, duration, isLoaded]);

  return (
    <div className={styles.googleMapComponentPolyLineOriginDestination}>
      {!isLoaded || !origin?.latitude || !origin?.longitude ? (
        <LoadingSpinner />
      ) : (
        <GoogleMap
          center={{ lat: origin?.latitude, lng: origin?.longitude }}
          zoom={15}
          mapContainerStyle={{
            height: "100%",
            width: "100%",
            borderRadius: "5px",
          }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            gestureHandling: "greedy",
            restriction: {
              latLngBounds: {
                south: 5.4627, // Latitude of the southernmost point of India with an extra margin
                west: 67.1114, // Longitude of the westernmost point of India with an extra margin
                north: 38.0841, // Latitude of the northernmost point of India with an extra margin
                east: 98.3956, // Longitude of the easternmost point of India with an extra margin
              },
              strictBounds: true,
            },
          }}
        >
          {directionsResponse && driectionServiceState && origin && (
            <Marker
              position={directionsResponse.routes[0].legs[0].start_location}
              icon={{
                url: images.startPointPinGreen,
                scaledSize: new window.google.maps.Size(32, 32),
              }}
            />
          )}
          {directionsResponse && driectionServiceState && destination && (
            <Marker
              position={directionsResponse.routes[0].legs[0].end_location}
              icon={{
                url: images.endPointPin,
                scaledSize: new window.google.maps.Size(14, 14),
              }}
            />
          )}
          {driectionServiceState &&
            directionsResponse &&
            driectionServiceState &&
            destination && (
              <DirectionsRenderer
                options={{
                  suppressMarkers: true,
                  polylineOptions: {
                    strokeColor: "#000",
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  },
                }}
                directions={directionsResponse}
              />
            )}
        </GoogleMap>
      )}
      {/* <button onClick={calculateRoute}>Calculateroute</button> */}
    </div>
  );
};

export default GoogleMapComponentPolyLineOriginDestination;
