import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { GOOGLE_MAPS_API_KEY } from "../constants/apiConstants";
import { libs } from "../constants/storageConstants";

function useReverseGeocoding(latitude, longitude) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libs,
  });

  const [address, setAddress] = useState(null);

  useEffect(() => {
    while (!isLoaded) return;
    const geocoder = new window.google.maps.Geocoder();
    const location = { lat: latitude, lng: longitude };
    if (!location.lat || !location.lng) return;

    geocoder.geocode({ location }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          setAddress(results[0].formatted_address);
        } else {
          console.log("No results found");
        }
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  }, [latitude, longitude, isLoaded]);

  return address;
}

export { useReverseGeocoding };
