import { useEffect, useRef } from "react";

const useCustomLogger = () => {
  const logMessagesRef = useRef([]);

  // useEffect(() => {
  //   const originalLog = console.log;
  //   console.log = (...args) => {
  //     originalLog(...args);
  //     const logArgs = args.map((arg) => {
  //       if (typeof arg === "object" && arg !== null) {
  //         return JSON.stringify(arg);
  //       }
  //       return arg;
  //     });
  //     const timestamp = new Date().toLocaleString(); // Add timestamp
  //     logMessagesRef.current.push(`[${timestamp}] ${logArgs.join(" ")}`);
  //     // logMessagesRef.current.push(logArgs.join(" "));
  //     //   logMessagesRef.current.push(args.join(" "));
  //   };

  //   const originalError = console.error;
  //   console.error = (...args) => {
  //     originalError(...args);
  //     const timestamp = new Date().toLocaleString(); // Add timestamp
  //     logMessagesRef.current.push(`[${timestamp}] ${args.join(" ")}`);
  //     logMessagesRef.current.push(args.join(" "));
  //   };

  //   return () => {
  //     console.log = originalLog; // Restore the original console.log
  //     console.error = originalError; // Restore the original console.error
  //   };
  // }, []);

  useEffect(() => {
    const originalFetch = window.fetch;

    window.fetch = async (...args) => {
      const [url, options] = args;
      const timestamp = new Date().toLocaleString();

      try {
        const response = await originalFetch(url, options);
        const status = response.status;
        const message = `[${timestamp}] URL ${url} - Status: ${status}`;
        logMessagesRef.current.push(message);
        return response;
      } catch (error) {
        const errorMessage = `[${timestamp}] URL ${url} - Error: ${error.message}`;
        logMessagesRef.current.push(errorMessage);
        throw error;
      }
    };

    return () => {
      window.fetch = originalFetch;
    };
  }, []);

  return {
    exportLogs: () => {
      const message = logMessagesRef.current.join("\n");
      const logFile = new Blob([message], { type: "text/plain" });
      const url = URL.createObjectURL(logFile);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "console_logs.txt");
      document.body.appendChild(link);
      link.click();
    },
  };
};

export { useCustomLogger };
