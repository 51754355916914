import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import styles from "./MakePaymentCcAvenue.module.css";
import {
  BackPageHeadingWrapper,
  BottomMessagePara,
  Button,
  TopGreenCheckStatus,
  TotalFareMakePaymentCard,
} from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  USER_LOCAL_STORAGE,
} from "../../../constants/storageConstants";
import {
  BASE_URL,
  CCAVENUE_BASE_URL_LIVE,
  CCAVENUE_BASE_URL_TEST,
  RIDER_CANCEL_WITHOUT_LIABILITY,
  RIDER_DRIVER_AVAILABILITY,
  RIDER_PROFILE_DETAILS,
} from "../../../constants/apiConstants";
import {
  riderCreatedRideActions,
  riderPostRideActions,
} from "../../../store/slice";
import { toast } from "react-toastify";
import { useLogoutHandler, useNavigateOnEvent } from "../../../hooks";
import {
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
  RIDER_RIDE_ARRIVING_ROUTE,
  RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE_WAITING_PAGE,
  RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE,
} from "../../../constants/routeConstants";
import { useNavigate } from "react-router-dom";

const MakePaymentCcAvenue = () => {
  const navigate = useNavigate();
  const logoutFunction = useLogoutHandler();
  const dispatch = useDispatch();
  // const navigateToRideArrivingScreen = useNavigateOnEvent(
  //   RIDER_RIDE_ARRIVING_ROUTE
  // );

  const [urlParams] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const rideId = searchParams.get("rideId");
    const bidId = searchParams.get("bidId");
    // console.log("Param ride id", rideId);
    return { rideId: rideId, bidId: bidId };
  });

  // const navigateToWaitingForBidScreen = useNavigateOnEvent(
  //   RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE,
  //   true
  // );

  // const [urlParams] = useState(() => {
  //   const searchParams = new URLSearchParams(window.location.search);
  //   const ride = searchParams.get("ride");
  //   const bid = searchParams.get("bid");
  //   const status = searchParams.get("status");

  //   console.log("Redirected url params", ride, bid, status);
  // });

  const [selectedBidDetails, setSelectedBidDetails] = useState({
    bidId: "",
    driverName: "",
    amount: "",
    carName: "",
    carRegistrationNumber: "",
    carPhoto: "",
    driverPhoto: "",
    etaTime: "",
    driverRating: "",
  });

  const getBidDetails = async () => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const searchParams = new URLSearchParams(window.location.search);
    const bidId = searchParams.get("bidId");
    const bidIdFromBackend = searchParams.get("bid");

    const url = `${BASE_URL}${"/bid-response/"}`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        bid_id: bidId || bidIdFromBackend,
      }),
    };

    const response = await fetch(url, options);

    if (response.ok) {
      const data = await response.json();

      console.log(data);
      setSelectedBidDetails({
        bidId: data?.bid_id,
        driverName: data?.driver_name,
        amount: data?.amount,
        carName: data?.car_name,
        carRegistrationNumber: data?.car_registration_number,
        carPhoto: data?.car_photo,
        driverPhoto: data?.driver_photo,
        etaTime: data?.eta_time,
        driverRating: data?.driver_rating,
        driverOnlinePayment: data?.customer_online_payment,
      });
    }
  };

  useEffect(() => {
    getBidDetails();
  }, []);

  const createdRideId = urlParams.rideId;
  const navigateToHomeScreen = useNavigateOnEvent(
    RIDER_HOME_POST_A_RIDE__TO_ROUTE
  );
  // const navigateToWaitingForBidScreen = useNavigateOnEvent(
  //   RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE,
  //   true
  // );

  useEffect(() => {
    const confirmDataAndAuth = async (ride, bid, user, mobile, paystatus) => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      const fetchConfirmUrl = `${BASE_URL}${RIDER_PROFILE_DETAILS}`;
      const fetchConfirmOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(fetchConfirmUrl, fetchConfirmOptions);

      if (response.status !== 200) {
        logoutFunction();
      }

      // console.log("Response", response);

      if (response.ok) {
        const data = await response.json();
        const phoneNumber = data.phone_number;
        const userId = data.user_id;
        console.log("payment data", data);
        if (
          phoneNumber.toString() !== mobile.toString() &&
          userId.toString() !== user.toString()
        ) {
          logoutFunction();
          return;
        }
        if (
          phoneNumber.toString() === mobile.toString() &&
          userId.toString() === user.toString() &&
          paystatus.toString() === "Success"
        ) {
          toast.success("Payment Success");
          // navigateToRideArrivingScreen();
          navigate(`${RIDER_RIDE_ARRIVING_ROUTE}?rideId=${ride}&bidId=${bid}`, {
            replace: true,
          });
          return;
        }
        if (
          phoneNumber.toString() === mobile.toString() &&
          userId.toString() === user.toString() &&
          paystatus.toString() === "Failure"
        ) {
          toast.error("Payment Failed");
          // navigateToWaitingForBidScreen();
          navigate(
            `${RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE}?rideId=${ride}`,
            {
              replace: true,
            }
          );
          return;
        }
        // if (
        //   phoneNumber.toString() === mobile.toString() &&
        //   userId.toString() === user.toString() &&
        //   paystatus.toString() === "erroroccured"
        // ) {
        //   toast.error("Error occured");
        //   navigateToWaitingForBidScreen();
        // }
        // if (
        //   phoneNumber.toString() === mobile.toString() &&
        //   userId.toString() === user.toString() &&
        //   paystatus.toString() !== "Failure" &&
        //   paystatus.toString() !== "Success"
        // ) {
        //   toast.error("Something went wrong");
        //   navigateToWaitingForBidScreen();
        // }
        toast.info("Waiting...");
        navigate(
          `${RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE_WAITING_PAGE}?rideId=${ride}&bidId=${bid}`,
          {
            replace: true,
          }
        );
      }
    };

    const searchParams = new URLSearchParams(window.location.search);
    const ride = searchParams.get("ride");
    const bid = searchParams.get("bid");
    const user = searchParams.get("user");
    const mobile = searchParams.get("mobile");
    const paystatus = searchParams.get("paystatus");

    if (ride && bid && user && mobile && paystatus) {
      confirmDataAndAuth(ride, bid, user, mobile, paystatus);
    }
    console.log("Redirected url params", ride, bid, user, mobile, paystatus);
  }, []);

  const loadIframe = (src) => {
    return new Promise((resolve) => {
      const iframe = document.createElement("iframe");
      iframe.src = src;
      iframe.onload = () => {
        resolve(true);
      };
      iframe.onerror = () => {
        resolve(false);
      };
      iframe.style.position = "fixed";
      iframe.style.top = "0";
      //   iframe.style.left = "0";
      //   iframe.style.transform = "translateX(50%)";
      //   iframe.style.right = "0";
      iframe.style.border = `none`;
      iframe.style.padding = `5px`;
      //   iframe.style.paddingTop = `5px`;
      iframe.style.borderRadius = `25px`;
      //   iframe.style.overflow = "hidden";
      iframe.style.width = `min(425px, 100vw)`;
      iframe.style.height = "100%";
      iframe.style.zIndex = "9999"; // Set a high z-index value
      iframe.style.background = "white";
      document.body.appendChild(iframe);
    });
  };

  const onCancelClickHandler = async () => {
    // console.log("Cancelling ride ...");
    // API Call for
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const fetchUrl = `${BASE_URL}${RIDER_CANCEL_WITHOUT_LIABILITY}`;
    const fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: createdRideId,
        value: "",
      }),
    };

    try {
      const response = await fetch(fetchUrl, fetchOptions);

      if (response.ok) {
        // console.log("Ride cancelled from server...");
        // console.log("Clearing local state...");
        // clear redux state for current ride
        dispatch(riderPostRideActions.clearPostRide());
        dispatch(riderCreatedRideActions.clearCreateRideId());
        // console.log("Local state cleared ✔");
        // info notification
        // console.log("Sending info toast...");
        toast.info("Ride has been cancelled");
        // console.log("Toast sent ✔");
        // navigate to home screen for booking new ride
        // console.log("Navigating to home screen...");
        navigateToHomeScreen();
      }
    } catch (error) {
      console.log("Something went wrong. Printing error message...");
      console.log(error);
    }
  };

  const onClickPaymentAndNavigationHandler = async () => {
    const pollingPaymentresponse = async () => {
      const url = `${BASE_URL}${"/cc/payments-avenue/"}`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          ride_id: createdRideId,
          bid_id: `${selectedBidDetails?.id}`,
          amount: "",
          user_id:
            JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE))?.user_id ||
            undefined,
        }),
      };

      const response = await fetch(url, options);

      if (response.ok) {
        const data = await response.json();

        console.log(data);

        if (data?.Message === "Success") {
          //
          console.log(data);
          return;
        }
        if (data?.Message === "Pending") {
          //
          console.log(data);
          return;
        }
        if (data?.Message === "Failure") {
          //
          console.log(data);
          return;
        }
        if (data?.Message === "Not-Confirmed") {
          //
          console.log(data);
          return;
        }
      }
    };

    // setInterval(() => {
    //   pollingPaymentresponse();
    // }, 5000);

    //
    // checking driver availbality
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

    // fetch driver available url and options
    const urlDriverAvailable = `${BASE_URL}${RIDER_DRIVER_AVAILABILITY}`;
    const driverAvailableFetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        bid_id: `${selectedBidDetails.bidId}`,
      }),
    };

    const responseDriverAvailable = await fetch(
      urlDriverAvailable,
      driverAvailableFetchOptions
    );

    const dataDriverAvailable = await responseDriverAvailable.json();

    const urlForCustomerDetails = `${BASE_URL}${RIDER_PROFILE_DETAILS}`;
    const optionsGetCustomerDetails = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const customerDetailsResponse = await fetch(
      urlForCustomerDetails,
      optionsGetCustomerDetails
    );

    const customerData = await customerDetailsResponse.json();

    if (
      dataDriverAvailable &&
      dataDriverAvailable?.driver_available === false
    ) {
      //
      toast.error("Driver unavailable, Please select another bid");
      // navigateToWaitingForBidScreen();
      navigate(
        `${RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE}?rideId=${urlParams.rideId}`,
        {
          replace: true,
        }
      );
      return;
    } else if (
      dataDriverAvailable &&
      dataDriverAvailable?.driver_available === true
    ) {
      //

      const fetchEncryptedDataUrl = `${BASE_URL}${"/cc/ccavRequestHandler"}`;
      const fetchEncryptedDataOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          ride_id: createdRideId,
          bid_id: `${selectedBidDetails.bidId}`,
          amount: 1000,
        }),
      };

      const encDataResponse = await fetch(
        fetchEncryptedDataUrl,
        fetchEncryptedDataOptions
      );

      if (!encDataResponse.ok) {
        console.log("something wen twrong");
        return;
      }

      const encData = await encDataResponse.json();
      console.log(encData);

      try {
        const ifr = `${CCAVENUE_BASE_URL_LIVE}/transaction/transaction.do?command=initiateTransaction&merchant_id=2492482&encRequest=${encData.encryption}&access_code=${encData.accessCode}`;
        // const iframeSrc = "https://www.google.com/webhp?igu=1";
        const success = await loadIframe(ifr);
        if (success) {
          console.log("Iframe loaded successfully");
        } else {
          console.error("Error loading iframe");
        }
      } catch (error) {
        console.error("Error loading iframe:", error);
      }

      //   loadIframeAsync("https://www.google.com/webhp?igu=1");
    }
  };
  return (
    <BackPageHeadingWrapper logoHeadingText="Make payment">
      <div className={styles.makePaymentCcAvenue}>
        <TopGreenCheckStatus displayText="Pay and confirm your ride." />
        <TotalFareMakePaymentCard
          bidId={selectedBidDetails.bidId}
          driverName={selectedBidDetails.driverName}
          driverRating={selectedBidDetails.driverRating}
          price={selectedBidDetails.amount}
          vehicleName={selectedBidDetails.carName}
          vehicleNumber={selectedBidDetails.carRegistrationNumber}
          timeToReach={selectedBidDetails.etaTime}
          vehicleImage={selectedBidDetails.carPhoto}
          driverImage={selectedBidDetails.driverPhoto}
          onCancelClick={onCancelClickHandler}
        />

        <div className={styles.nominalFeeButton}>
          <div className={styles.infoBar}>
            <span>
              ₹{" "}
              {parseFloat(
                selectedBidDetails?.driverOnlinePayment / 100
              ).toFixed(1)}
            </span>
            <span>Nominal fee</span>
          </div>
          <Button
            displayText="Proceed to Pay"
            onClickFunction={onClickPaymentAndNavigationHandler}
          />
        </div>
        <BottomMessagePara displayText="Please pay the booking amount and confirm your ride. Pay the remaining amount after arriving at the destination." />
      </div>
    </BackPageHeadingWrapper>
  );
};

export default MakePaymentCcAvenue;
