import { createSlice } from "@reduxjs/toolkit";

const bids = "bids";
const selectedBidId = "selectedBidId";
const selectedBidDetails = "selectedBidDetails";
const selectedBidDetailsWithNumber = "selectedBidDetailsWithNumber";

const riderBidSlice = createSlice({
  name: "riderBid",
  initialState: {
    isCreateConnectionForReceivingBid: false,
    bids: JSON.parse(localStorage.getItem(bids)) || [],
    selectedBidId: localStorage.getItem(selectedBidId) || "",
    selectedBidDetails:
      JSON.parse(localStorage.getItem(selectedBidDetails)) || {},
    selectedBidDetailsWithNumber:
      JSON.parse(localStorage.getItem(selectedBidDetailsWithNumber)) || {},
  },
  reducers: {
    /**
     * Reducer function that adds a single bid to the state.
     * Payload = single bid object
     */
    addSingleBid(state, action) {
      state.bids.push(action.payload);
      localStorage.setItem(bids, JSON.stringify(state.bids));
    },
    /**
     * Reducer function that adds multiple bids to the state.
     * Payload = List of bid object
     */
    addMultipleBids(state, action) {
      state.bids = [...action.payload];
      localStorage.setItem(bids, JSON.stringify(state.bids));
    },

    /**
     * Reducer function sets the selected bid Id.
     * Payload = id of the selected bid
     */
    setSelectedBid(state, action) {
      state.selectedBidId = action.payload;
      localStorage.setItem(selectedBidId, action.payload);

      state.selectedBidDetails = state.bids.find(
        (bid) => bid?.id === action.payload
      );

      localStorage.setItem(
        selectedBidDetails,
        JSON.stringify(state.selectedBidDetails || {})
      );
    },
    /**
     * Reducer function sets the selected bid details after phone number.
     * Payload = selected Bid details
     */

    setSelectedBidDetailsWithNumber(state, action) {
      state.selectedBidDetailsWithNumber = {};
      state.selectedBidDetailsWithNumber = action.payload;
      localStorage.setItem(
        selectedBidDetailsWithNumber,
        JSON.stringify(action.payload)
      );
    },
    /**
     * clear state.
     */
    clearBidSlice(state) {
      state.bids = [];
      localStorage.removeItem(bids);
      state.selectedBidId = "";
      localStorage.removeItem(selectedBidId);
      state.selectedBidDetails = {};
      localStorage.removeItem(selectedBidDetails);
      state.selectedBidDetailsWithNumber = {};
      localStorage.removeItem(selectedBidDetailsWithNumber);
    },
  },
});

export const riderBidActions = riderBidSlice.actions;
export default riderBidSlice;
