// import React, { useState } from "react";
// eslint-disable-next-line
import styles from "./UpdateRequestModal.module.css";

const UpdateRequestModal = ({
  openBool = false,
  openBoolFunction = () => {},
}) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  //   const isPaymentSelectionModalOpen = useSelector(
  //     (state) => state.customModal.isPaymentSelectionModalOpen
  //   );

  //   const closCustomModal = () => {
  //     console.log("Close modal");
  //     // dispatch(customModalActions.closeCustomModalDialogBox());
  //     // openBoolFunction(false);
  //   };

  return (
    <>
      {openBool && (
        <div className={styles.customModal}>
          <div className={styles.modalBackdrop}>
            <div className={styles.visibleModal}>
              <h3 className={styles.modalMainHeading}>
                Please close and open your app
              </h3>
              <p className={styles.modalSubHeading}>(App Updated !)</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateRequestModal;
