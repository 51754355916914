import { createSlice } from "@reduxjs/toolkit";

const riderRatingAdvancedRideSlice = createSlice({
  name: "riderRatingAdvancedRide",
  initialState: {
    punctuality: 0,
    cleanliness: 0,
    professionalism: 0,
  },
  reducers: {
    /**
     *
     * @param {rating number range between 1 to 5 } action
     * default 0 means not rated
     */
    setPunctuality(state, action) {
      state.punctuality = action.payload;
    },
    /**
     *
     * @param {rating number range between 1 to 5 } action
     * default 0 means not rated
     */
    setCleanliness(state, action) {
      state.cleanliness = action.payload;
    },
    /**
     *
     * @param {rating number range between 1 to 5 } action
     * default 0 means not rated
     */
    setProfessionalism(state, action) {
      state.professionalism = action.payload;
    },
  },
});

export const riderRatingAdvancedRideActions =
  riderRatingAdvancedRideSlice.actions;
export default riderRatingAdvancedRideSlice;
