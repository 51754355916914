import React, { useState } from "react";
import Button from "../../Button/Button";
// eslint-disable-next-line
import styles from "./SelectBidContinue.module.css";

import { BidCard } from "../../../components";
import { useDispatch } from "react-redux";
import { customModalActions } from "../../../store/slice";
import { images } from "../../../images";
import { toast } from "react-toastify";
import {
  FILTER_CONSTANT_AUTO,
  FILTER_CONSTANT_BOTH,
  FILTER_CONSTANT_TAXI,
} from "../../../constants/storageConstants";

const SelectBidContinue = ({ bidData, setSelectedBidUplift }) => {
  const dispatch = useDispatch();
  // const navigateToMakePayment = useNavigateOnEvent(
  //   RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE
  // );
  const [selectedBid, setSelectedBid] = useState("");

  const [filterTerms, setFilterTerms] = useState(FILTER_CONSTANT_BOTH);

  const setSelectedBidHandler = (bidId) => {
    // console.log("Selected bid id", bidId);
    setSelectedBid(bidId);
  };

  const onClickFunctionHandler = () => {
    if (!selectedBid) {
      // ui slice notifications
      // please select a bid
      // return;
      toast.info("Please select and confirm.");
      return;
    }
    //Offline
    // TODO open modal
    //send selected bid id one level above
    dispatch(customModalActions.openCustomModalDialogBox());
    setSelectedBidUplift(selectedBid);

    // Now this should be done in modal selection button
    // dispatch(riderBidActions.setSelectedBid(selectedBid));
    // navigateToMakePayment();

    //go without selecting a bid
    // navigateToMakePayment();
  };

  // console.log("The bid data in this component", bidData);
  // return;

  const onClickAutoButtonHandler = () => {
    console.log("auto button ...");
    // deselect the bid
    setSelectedBid("");
    // case
    setFilterTerms((prevState) => {
      // taxi or none then make it auto
      if (
        prevState === FILTER_CONSTANT_TAXI ||
        prevState === FILTER_CONSTANT_BOTH
      ) {
        return FILTER_CONSTANT_AUTO;
      }
      // if auto then make it none
      if (prevState === FILTER_CONSTANT_AUTO) {
        return FILTER_CONSTANT_BOTH;
      }
    });
    // filter only array
    // maintain state after reloading
    // de select selected bid after changing filters
  };

  const onClickTaxiButtonHandler = () => {
    // console.log("taxi button ...");
    // deselect the bid
    setSelectedBid("");
    // case
    setFilterTerms((prevState) => {
      // taxi or none then make it auto
      if (
        prevState === FILTER_CONSTANT_AUTO ||
        prevState === FILTER_CONSTANT_BOTH
      ) {
        return FILTER_CONSTANT_TAXI;
      }
      // if auto then make it none
      if (prevState === FILTER_CONSTANT_TAXI) {
        return FILTER_CONSTANT_BOTH;
      }
    });
  };

  return (
    <>
      <div className={styles.selectBidContinue}>
        <div className={styles.bidHeadingAndSelect}>
          <div className={styles.heading}>
            Select and Confirm the Bid <span>({bidData?.length} recieved)</span>
          </div>

          {/* <div className={styles.sortDivBase}>
            <button
              onClick={onClickTaxiButtonHandler}
              className={`${styles.filterButton} ${styles.leftButton} ${
                filterTerms === FILTER_CONSTANT_TAXI && styles.selectedFilter
              }`}
            >
              Taxi
            </button>
            <button
              onClick={onClickAutoButtonHandler}
              className={`${styles.filterButton} ${styles.rightButton}  ${
                filterTerms === FILTER_CONSTANT_AUTO && styles.selectedFilter
              }`}
            >
              Auto
            </button>

            <p className={styles.sortText}>Sort</p>
            <img
              className={styles.arrowSortUpDown}
              src={images.arrowSortUpDown}
              alt="arrow up down"
            />
          </div> */}
        </div>
        <div className={styles.bidCardDiv}>
          {Array.isArray(bidData) &&
            bidData.length > 0 &&
            bidData?.map((eachVehicleBid) => {
              if (
                filterTerms === FILTER_CONSTANT_BOTH ||
                eachVehicleBid?.car_details?.vehicle_type
                  .toLowerCase()
                  .slice(0, 4) === filterTerms.toLowerCase()
              ) {
                return (
                  <React.Fragment key={eachVehicleBid?.id}>
                    {/* <BidCard
                  key={eachVehicleBid?.amount?.id}
                  bidId={eachVehicleBid?.amount?.id}
                  price={eachVehicleBid?.amount?.amount}
                  timeToReach={5}
                  driverName={eachVehicleBid?.driver_name}
                  driverRating={"5"}
                  driverImage={eachVehicleBid.driverImage || images.userGeneric}
                  vehicleNumber={
                    eachVehicleBid?.car_details?.registration_number
                  }
                  vehicleName={eachVehicleBid?.car_details?.car_name}
                  vehicleImage={eachVehicleBid?.vehicleImage || images.cab}
                  selectedBidId={selectedBid}
                  setSelectedBid={() =>
                    setSelectedBidHandler(eachVehicleBid?.amount?.id)
                  }
                /> */}
                    <BidCard
                      key={eachVehicleBid?.id}
                      bidId={eachVehicleBid?.id}
                      price={parseFloat(eachVehicleBid?.amount / 100)
                        .toFixed(1)
                        .toString()}
                      timeToReach={eachVehicleBid?.eta_time}
                      driverName={eachVehicleBid?.driver_name}
                      driverRating={5}
                      driverImage={
                        eachVehicleBid?.driver_photo || images.userGeneric
                      }
                      vehicleNumber={
                        eachVehicleBid?.car_details?.registration_name
                      }
                      vehicleName={eachVehicleBid?.car_details?.car_name}
                      vehicleImage={
                        eachVehicleBid?.car_details?.car_photo || images.cab
                      }
                      vehicleType={eachVehicleBid?.car_details?.vehicle_type}
                      selectedBidId={selectedBid}
                      setSelectedBid={() =>
                        setSelectedBidHandler(eachVehicleBid?.id)
                      }
                    />
                  </React.Fragment>
                );
              }
              return null;
            })}
        </div>
        <div className={styles.bottomButton}>
          <Button
            displayText="Confirm Bid"
            onClickFunction={onClickFunctionHandler}
          />
        </div>
      </div>
    </>
  );
};

export default SelectBidContinue;
