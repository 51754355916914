import React from "react";
import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./PastRideCard.module.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

const PastRideCard = ({
  cancelled = false,
  rideAmount,
  time,
  vehicleNumber,
  pickupAddressText,
  dropAddressText,
  driverPhoto,
  otp,
  carImage,
  driverName,
  driverPhoneNumber,
}) => {
  dayjs.extend(customParseFormat);
  const formattedDateTime = dayjs(time, "YYYY-DD-MM HH:mm:ss").format(
    "MMM DD hh:mm A"
  );
  return (
    <>
      <div className={styles.pastRideCard}>
        <div className={styles.left}>
          <img className={styles.cab} src={carImage || images.cab} alt="cab " />
        </div>
        <div className={styles.middle}>
          <div className={styles.upper}>
            <p className={styles.line}>
              <span>{formattedDateTime}</span>
            </p>
            <p className={styles.line}>
              <span>{vehicleNumber}</span>
              <span>{`OTP - ${otp}`}</span>
            </p>
          </div>
          <div className={styles.lower}>
            <div className={styles.bottomTwo}>
              <span className={styles.dotGreen}>Origin</span>
              <p>{pickupAddressText}</p>
            </div>
            <div className={styles.bottomTwo}>
              <span className={styles.dotRed}>Destination</span>
              <p>{dropAddressText}</p>
            </div>
          </div>
          <div className={styles.driverNameNumberLine}>
            <div>{driverName}</div>
            <a className={styles.anchorReset} href={`tel:${driverPhoneNumber}`}>
              {driverPhoneNumber}
            </a>
          </div>
        </div>
        <div className={styles.right}>
          {cancelled ? (
            <p className={styles.cancelled}>Cancelled</p>
          ) : (
            <p className={styles.rideAmount}>{`₹${rideAmount}`}</p>
          )}
          <img src={driverPhoto || images.userGenericInvert} alt="driver" />
        </div>
      </div>
    </>
  );
};

export default PastRideCard;
