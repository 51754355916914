import { useDispatch, useSelector } from "react-redux";
import {
  useAdvRedirectAcc,
  useLogoutHandler,
  useNavigateOnEvent,
  useReverseGeocoding,
} from "../../../hooks";
import styles from "./PostRideFromToMapSelectionAdvanceBooking.module.css";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { riderPostRideAdvanceBookingActions } from "../../../store/slice";
import Cookies from "js-cookie";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  libs,
} from "../../../constants/storageConstants";
import { toast } from "react-toastify";
import {
  BookRideWithBack,
  Button,
  ErrorInNumber,
  GridFromToTextBox,
  HorizontalLine,
  LoadingSpinner,
  SelectTimeDiv,
} from "../../../components";
import {
  BASE_URL,
  CODE_200,
  GOOGLE_MAPS_API_KEY,
  // RIDER_CREATE_RIDE_ADVANCE_BOOKING,
  RIDER_CREATE_RIDE_ADVANCE_BOOKING_AUTOMATE,
} from "../../../constants/apiConstants";
import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { images } from "../../../images";
import {
  ADV_POST_A_RIDE_FROM_ROUTE,
  ADV_POST_A_RIDE_TO_ROUTE,
  ADV_RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE,
} from "../../../constants/routeConstants";
import dayjs from "dayjs";

// const vehicleTypes = [
//   {
//     id: "mini",
//     displayText: "Taxi-Mini",
//   },
//   {
//     id: "sedan",
//     displayText: "Taxi-Sedan",
//   },
//   {
//     id: "suv",
//     displayText: "Taxi-SUV",
//   },
//   {
//     id: "innova",
//     displayText: "Innova",
//   },
// ];

const PostRideFromToMapSelection = () => {
  useAdvRedirectAcc();
  const logoutFunction = useLogoutHandler();
  const dispatch = useDispatch();

  const [originAdTextState, setOriginAdTextState] = useState("");

  const [tapBookRideCount, setTapBookRideCount] = useState(0);

  const [scheduleTime, setScheduleTime] = useState({
    date: null,
    time: null,
  });
  const [scheduleRideBool, setScheduleRideBool] = useState(false);

  const [isBookRideDataLoadedStatus, setIsBookRideDataLoadedStatus] =
    useState("LOADING");

  const [openTaxiTypeModal, setOpenTaxiTypeModal] = useState(false);
  const [vehicleType, setVehicleType] = useState("mini"); // "mini" // "sedan" // "suv" // "innova"
  const [isRideReturn, setIsRideReturn] = useState(false);

  const navigateBack = useNavigateOnEvent(-1);
  // const navigateBackToHome = useNavigateOnEvent(-2);
  // * CODE_REFACTOR_SEPERATION_OF_CONCERN
  const navigate = useNavigate();
  // const navigateToWaitingForBid = useNavigateOnEvent(
  //   RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE
  // );
  const navigateToPostRideTo = useNavigateOnEvent(
    ADV_POST_A_RIDE_TO_ROUTE,
    true
  );
  const navigateToPostRideFromTo = useNavigateOnEvent(
    ADV_POST_A_RIDE_FROM_ROUTE,
    true
  );
  const origin = useSelector(
    (state) => state.riderPostRideAdvanceBooking.inputOrigin
  );
  const destination = useSelector(
    (state) => state.riderPostRideAdvanceBooking.inputDestination
  );

  const originAddress = useSelector(
    (state) => state.riderPostRideAdvanceBooking.inputOrigin
  );

  const originAddressTextFromReverseGeoCoding = useReverseGeocoding(
    originAddress.latitude || "",
    originAddress.longitude || ""
  );

  const destinationAddressText = useSelector(
    (state) => state.riderPostRideAdvanceBooking.inputDestinationText
  );

  const originAddressText = useSelector(
    (state) => state.riderPostRideAdvanceBooking.inputOriginText
  );

  useEffect(() => {
    if (`${originAddressText.mainAddress}${originAddressText.subAddress}`) {
      setOriginAdTextState(
        `${originAddressText.mainAddress}${" "}${
          originAddressText.subAddress
        }`.slice?.(0, 95)
      );
      return;
    }

    if (originAddressTextFromReverseGeoCoding) {
      setOriginAdTextState(
        originAddressTextFromReverseGeoCoding?.slice?.(0, 95)
      );
    }
  }, [
    originAddressTextFromReverseGeoCoding,
    originAddressText.mainAddress,
    originAddressText.subAddress,
  ]);

  const [notAuthoorisedError, setNotAuthoorisedError] = useState(false);
  const [distance, setDistance] = useState("");
  const [distanceMetres, setDistanceMetres] = useState("");
  const [duration, setDuration] = useState("");

  const resetInputsForPostRide = () => {
    dispatch(
      riderPostRideAdvanceBookingActions.updateDestinationAddressText({
        mainAddress: "",
        subAddress: "",
      })
    );
    dispatch(
      riderPostRideAdvanceBookingActions.updateOriginAddress({
        latitude: "",
        longitude: "",
      })
    );
  };

  useEffect(() => {
    if (
      origin &&
      origin.latitude &&
      origin.longitude &&
      destination &&
      destination.latitude &&
      destination.longitude &&
      distance
    ) {
      setIsBookRideDataLoadedStatus("COMPLETE");
    } else {
      setIsBookRideDataLoadedStatus("LOADING");
    }
  }, [destination, distance, origin]);

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const [confirmationDialofValues, setConfirmationDialofValues] = useState({
    pickup_address: `${origin.latitude},${origin.longitude}`,
    drop_address: `${destination.latitude},${destination.longitude}`,
    pickup_address_text: originAdTextState,
    drop_address_text: `${destinationAddressText.mainAddress}${" "}${
      destinationAddressText.subAddress
    }`.slice(0, 95),
    distance: distance,
    duration: duration,
    distanceInMetres: distanceMetres,
    schedule_date: scheduleTime.date,
    schedule_time: scheduleTime.time,
    return_type: isRideReturn,
    vehicle_type: vehicleType,
  });

  // SCHEDULE TIME
  const getDateText = (date) => {
    const today = dayjs();
    const tomorrow = today.add(1, "day");
    const dayAfterTomorrow = today.add(2, "day");

    if (date.isSame(today, "day")) {
      return {
        displaytext: "Today",
        date: date.format("DD-MM-YYYY"),
        dataFromDayjs: today.format("DD-MM-YYYY"),
      };
    } else if (date.isSame(tomorrow, "day")) {
      return {
        displaytext: "Tomorrow",
        date: date.format("DD-MM-YYYY"),
        dataFromDayjs: tomorrow.format("DD-MM-YYYY"),
      };
    } else {
      const day = date.format("D");
      // console.log(day);
      let suffix = "th";

      if (day.endsWith("1") && day.slice(-2) !== "11") {
        suffix = "st";
      } else if (day.endsWith("2") && day.slice(-2) !== "12") {
        suffix = "nd";
      } else if (day.endsWith("3") && day.slice(-2) !== "13") {
        suffix = "rd";
      }
      // console.log(suffix);
      const displayDate = date.format(`ddd, ${day}`);
      return {
        displaytext: `${displayDate}${suffix}`,
        date: date.format("DD-MM-YYYY"),
        dataFromDayjs: dayAfterTomorrow.format("DD-MM-YYYY"),
      };
    }
  };

  const [selectedDate, setSelectedDate] = useState(1);
  const today = dayjs();
  const tomorrow = today.add(1, "day");
  const thirdDay = today.add(2, "day");
  const dateText = [
    getDateText(today),
    getDateText(tomorrow),
    getDateText(thirdDay),
  ];

  const HOURS = Array.from({ length: 12 }, (_, index) =>
    (index + 1).toString().padStart(2, "0")
  );
  const MINUTES = Array.from({ length: 60 }, (_, index) =>
    index.toString().padStart(2, "0")
  );

  const [selecetedTime, setSelecetedTime] = useState({
    hours: "",
    minutes: "",
    amPm: "PM",
  });

  // SCHEDULE TIME
  const confirmationDialogOpen = () => {
    if (
      origin &&
      origin.latitude &&
      origin.longitude &&
      destination &&
      destination.latitude &&
      destination.longitude &&
      distance &&
      distanceMetres &&
      scheduleTime.time &&
      scheduleTime.date
    ) {
      setIsConfirmationDialogOpen(true);
      return;
    }
    toast.dismiss();
    toast.info("Please select time & date");
  };

  const bookRideButtonHandler = () => {
    if (tapBookRideCount === 1) return;
    setTapBookRideCount(1);
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    // Check whether we have the location or not
    // else redirect them to post ride to page(home for driver)
    setIsConfirmationDialogOpen(true);

    toast.success("Creating your ride", {
      toastId: "book",
      autoClose: false,
      icon: <LoadingSpinner />,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    if (
      origin &&
      origin.latitude &&
      origin.longitude &&
      destination &&
      destination.latitude &&
      destination.longitude &&
      distance &&
      distanceMetres &&
      scheduleTime.time &&
      scheduleTime.date
    ) {
      fetch(`${BASE_URL}${RIDER_CREATE_RIDE_ADVANCE_BOOKING_AUTOMATE}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        method: "POST",
        body: JSON.stringify({
          pickup_address: `${origin.latitude},${origin.longitude}`,
          drop_address: `${destination.latitude},${destination.longitude}`,
          pickup_address_text: originAdTextState,
          drop_address_text: `${destinationAddressText.mainAddress}${" "}${
            destinationAddressText.subAddress
          }`.slice(0, 95),
          distance: distance,
          duration: duration,
          distance_in_metres: distanceMetres,
          schedule_date: scheduleTime.date,
          schedule_time: scheduleTime.time,
          return_type: isRideReturn,
          vehicle_type: vehicleType,
          ride_time: new Date().toLocaleTimeString("en-US", { hour12: false }),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            // console.log("Response after creating a ride - ", response);
            return response.json();
          } else if (response.status === 401) {
            console.log("401 Response after creating a ride- ");
            setNotAuthoorisedError(true);
            // toast
            toast.error("Please Login");
            // log off
            // const logoutFunction = useLogoutHandler();
            logoutFunction();
          }
          throw response;
        })
        .then((data) => {
          if (data === "No Drivers around you") {
            toast.error("No drivers found ! Try after sometime", {
              toastId: "error",
              // autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            // console.log(data);
            //navigation
            return;
          } else if (data && data?.Code === CODE_200) {
            // * CODE_REFACTOR_SEPERATION_OF_CONCERN
            // dispatch(riderCreatedRideActions.setCreatedRideId(data?.ride_id));
            // dispatch(riderPostRideActions.clearPostRide());
            toast.dismiss("loading");
            toast.dismiss("error");
            toast.update("book", {
              render: "Please pay and confirm your booking",
              type: toast.TYPE.SUCCESS,
              autoClose: 20000,
              closeButton: true,
              icon: null,
              // transition: Slide,
              position: toast.POSITION.TOP_CENTER,
            });

            dispatch(riderPostRideAdvanceBookingActions.clearPostRide());

            navigate(
              `${ADV_RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE}?rideId=${data?.ride_id}`
            );
            // navigation to payment page with ride details total fare and other things

            return;
          }
        })
        .catch((e) => {
          console.log("Error", e);
          toast.update("book", {
            render: "Ride not booked",
            type: toast.TYPE.ERROR,
            autoClose: 20000,
            closeButton: true,
            icon: null,
            // transition: Slide,
            position: toast.POSITION.TOP_CENTER,
          });
          setTapBookRideCount(0);
        })
        .finally(() => {
          // setIsLoadingError(false);
        });

      //navigating and bypassing the create ride check
      // navigateToWaitingForBid();
    }
  };

  const distanceAndTimeLiftUpHandler = (distance, duration, distanceMetres) => {
    // console.log("Distance", distance, "Duration", duration);
    setDistance(distance);
    setDistanceMetres(distanceMetres);
    setDuration(duration);
  };

  const destinationInputOnClickHandler = () => {
    //go the the screen where we can select the destination
    // which the home screen Post Ride To
    navigateToPostRideTo();
  };

  const originInputOnClickHandler = () => {
    //go the the screen where we can select the origin
    // which is the Post Ride From To screen
    navigateToPostRideFromTo();
  };

  const onScheduleTimeAccept = (time) => {
    console.log("time", time);
    // setScheduleTime(time);
    setScheduleRideBool(true);
  };

  const onCancelResetSchedule = () => {
    console.log("schedule cancelled");
    // setScheduleTime(null);
    setScheduleRideBool(false);
  };

  const [isDateTimeModalOpen, setIsDateTimeModalOpen] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState("");

  const setSelectedDateTimeHandler = () => {
    // console.log("dd");
    if (
      selecetedTime.hours.toString() === "" ||
      selecetedTime.minutes.toString() === ""
    ) {
      // console.log("ddd");
      toast.dismiss();
      toast.info("Please select time");
      return;
    }

    if (dateText[selectedDate - 1]?.date === "") {
      toast.dismiss();
      toast.info("Please select date");
      return;
    }

    const HH = parseInt(selecetedTime.hours);
    const MM = parseInt(selecetedTime.minutes);
    const AMPM = selecetedTime.amPm;

    const [dd, mm, yyyy] = dateText[selectedDate - 1]?.date.split("-");

    let hour24 = HH;

    if (AMPM === "PM" && HH !== 12) {
      hour24 = HH + 12;
    } else if (AMPM === "AM" && HH === 12) {
      hour24 = 0;
    }

    const date = `${yyyy}-${mm}-${dd}`;
    const time24 = `${hour24
      .toString()
      .padStart(2, "0")}:${MM.toString().padStart(2, "0")}:00`;

    setScheduleTime({
      date: date,
      time: time24,
    });

    // console.log(currentHours);
    const selectedDateTime = new Date(`${yyyy}-${mm}-${dd}T${time24}`);
    const currentDateTime = new Date();
    // const currentDateTime = new Date("2023-12-12T23:00:00");

    // code for restricitng today 11 59 pm booking
    const today11_59PM = new Date();
    today11_59PM.setHours(23, 59, 0, 0);

    const timeDifferenceInMilliseconds = selectedDateTime - currentDateTime;
    const timeDifferenceInMinutes = timeDifferenceInMilliseconds / (1000 * 60);

    if (timeDifferenceInMinutes < 61) {
      // toast.dismiss();
      // toast.info("You can book for, after 1 hours minimum");
      // return;
      toast.dismiss();
      toast.info("You can book at least 1 hour before");
      console.log("Booking not allowed. Selected time 1 hour more");
      return;
    }
    // if (selectedDateTime > today11_59PM) {
    //   // toast.dismiss();
    //   // toast.info("You can book for, after 1 hours minimum");
    //   // return;
    //   toast.dismiss();
    //   toast.info("You can book for today only");
    //   console.log(
    //     "Booking not allowed. Selected time is beyond 11:59 PM of the current day."
    //   );
    //   return;
    // }

    console.log("Booking allowed. Selected time is within the allowed range.");
    const displayDate = `${dd}-${mm}-${yyyy}, ${HH.toString().padStart(
      2,
      "0"
    )}:${MM.toString().padStart(2, "0")} ${AMPM}`;
    setSelectedDateTime(displayDate);

    // HH:MM:SS ?? and date is YYYY-MM-DD ( format to send )

    // console.log(date, time24);
    setIsDateTimeModalOpen(false);
  };

  return (
    <>
      <div className={styles.postRideFromToMapSelection}>
        <ConfirmationDialog
          bookRideButtonHandler={bookRideButtonHandler}
          setIsConfirmationDialogOpen={setIsConfirmationDialogOpen}
          openLogDialogBox={isConfirmationDialogOpen}
          pickupAddress={`${origin.latitude},${origin.longitude}`}
          dropAddress={`${destination.latitude}${destination.longitude}`}
          pickupAddressText={originAdTextState}
          dropAddressText={`${destinationAddressText.mainAddress}${" "}${
            destinationAddressText.subAddress
          }`.slice(0, 95)}
          distance={distance}
          duration={duration}
          distanceInMetres={distanceMetres}
          scheduleDate={scheduleTime.date}
          scheduleTime={scheduleTime.time}
          returnType={isRideReturn}
          vehicleType={vehicleType}
        />
        <LogsBottomModal
          openLogDialogBox={openTaxiTypeModal}
          vehicleType={vehicleType}
          setVehicleType={setVehicleType}
          setOpenTaxiTypeModal={setOpenTaxiTypeModal}
        />
        {
          <ModalDateTimeSelection
            setIsDateTimeModalOpen={setIsDateTimeModalOpen}
            isDateTimeModalOpen={isDateTimeModalOpen}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selecetedTime={selecetedTime}
            setSelecetedTime={setSelecetedTime}
            dateText={dateText}
            HOURS={HOURS}
            MINUTES={MINUTES}
            setSelectedDateTime={setSelectedDateTimeHandler}
          />
        }
        <BookRideWithBack
          textHeading="Advance Booking"
          enableBackButton={true}
          backButtonFunction={navigateBack}
          textHeadingColorGreen
          fullGreenWhiteText
        />
        <div
          style={{
            width: "92%",
          }}
          className={styles.timeSelectionAlignment}
        >
          {isBookRideDataLoadedStatus === "LOADING" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LoadingSpinner />
              </div>
            </>
          )}

          <SelectTimeDiv
            vehicleType={vehicleType}
            setOpenTaxiTypeModal={setOpenTaxiTypeModal}
            selectedDateTime={selectedDateTime}
            setIsDateTimeModalOpen={setIsDateTimeModalOpen}
            isRideReturn={isRideReturn}
            setIsRideReturn={setIsRideReturn}
            distanceMetres={distanceMetres}
          />
        </div>
        <GridFromToTextBox
          originAddress={originAdTextState}
          originInputDisabled
          destinationAddress={`${destinationAddressText.mainAddress}${" "}${
            destinationAddressText.subAddress
          }`}
          destinationInputDisabled
          originInputOnClick={originInputOnClickHandler}
          destinationInputOnClick={destinationInputOnClickHandler}
        />
        <HorizontalLine />
        <div className={styles.distanceDurationRow}>
          <p>Distance: {distance}</p>
          <p>Duration: {duration}</p>
        </div>
        <GoogleMapComponentPolyLineOriginDestinationAdvanceBooking
          distanceAndTimeLiftUp={distanceAndTimeLiftUpHandler}
        />
        {/* <div></div> */}
        {/* <Button displayText="Cancel" onClickFunction={cancelButtonHandler} /> */}
      </div>
      {notAuthoorisedError && <ErrorInNumber displayText="Unauthorised" />}
      {tapBookRideCount === 0 && isBookRideDataLoadedStatus === "COMPLETE" && (
        <Button
          displayText="Proceed"
          onClickFunction={confirmationDialogOpen}
        />
      )}
      {isBookRideDataLoadedStatus === "LOADING" && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingSpinner />
          </div>
        </>
      )}
      <div className={styles.bottomMessagePara}>
        This will notify the nearby drivers and you will receive bids for this
        ride.
      </div>
    </>
  );
};

export default PostRideFromToMapSelection;

const GoogleMapComponentPolyLineOriginDestinationAdvanceBooking = ({
  distanceAndTimeLiftUp,
}) => {
  const origin = useSelector(
    (state) => state.riderPostRideAdvanceBooking.inputOrigin
  );
  const destination = useSelector(
    (state) => state.riderPostRideAdvanceBooking.inputDestination
  );
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libs,
  });

  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [distanceMetres, setDistanceMetres] = useState("");
  const [duration, setDuration] = useState("");
  const [driectionServiceState, setDriectionServiceState] = useState();

  useEffect(() => {
    while (!isLoaded) return;

    // eslint-disable-next-line no-undef
    const directionService = () => new google.maps.DirectionsService();
    setDriectionServiceState(directionService);
  }, [isLoaded]);

  const calculateRoute = useCallback(async () => {
    // console.log("Origin- ", { lat: origin?.latitude, lng: origin?.longitude });
    // console.log("Destination- ", {
    //   lat: destination?.latitude,
    //   lng: destination?.longitude,
    // });
    if (!driectionServiceState) return;
    if (!origin.latitude) return;
    if (!origin.longitude) return;
    if (!destination.latitude) return;
    if (!destination.longitude) return;

    const results = await driectionServiceState.route({
      origin: { lat: origin?.latitude, lng: origin?.longitude },
      destination: {
        lat: destination?.latitude,
        lng: destination?.longitude,
      },
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
    // console.log("results", results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDistanceMetres(results.routes[0].legs[0].distance.value);
    setDuration(results.routes[0].legs[0].duration.text);
  }, [driectionServiceState, origin, destination]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    // const directionService = () => new google.maps.DirectionsService();
    while (!isLoaded) return;
    calculateRoute();
    distanceAndTimeLiftUp(distance, duration, distanceMetres);
  }, [
    calculateRoute,
    distanceAndTimeLiftUp,
    distance,
    duration,
    isLoaded,
    distanceMetres,
  ]);

  return (
    <div className={styles.googleMapComponentPolyLineOriginDestination}>
      {!isLoaded || !origin?.latitude || !origin?.longitude ? (
        <LoadingSpinner />
      ) : (
        <GoogleMap
          center={{ lat: origin?.latitude, lng: origin?.longitude }}
          zoom={15}
          mapContainerStyle={{
            height: "100%",
            width: "100%",
            borderRadius: "5px",
          }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            gestureHandling: "greedy",
            restriction: {
              latLngBounds: {
                south: 5.4627, // Latitude of the southernmost point of India with an extra margin
                west: 67.1114, // Longitude of the westernmost point of India with an extra margin
                north: 38.0841, // Latitude of the northernmost point of India with an extra margin
                east: 98.3956, // Longitude of the easternmost point of India with an extra margin
              },
              strictBounds: true,
            },
          }}
        >
          {directionsResponse && driectionServiceState && origin && (
            <Marker
              position={directionsResponse.routes[0].legs[0].start_location}
              icon={{
                url: images.startPointPinGreen,
                scaledSize: new window.google.maps.Size(32, 32),
              }}
            />
          )}
          {directionsResponse && driectionServiceState && destination && (
            <Marker
              position={directionsResponse.routes[0].legs[0].end_location}
              icon={{
                url: images.endPointPin,
                scaledSize: new window.google.maps.Size(14, 14),
              }}
            />
          )}
          {driectionServiceState &&
            directionsResponse &&
            driectionServiceState &&
            destination && (
              <DirectionsRenderer
                options={{
                  suppressMarkers: true,
                  polylineOptions: {
                    strokeColor: "#000",
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  },
                }}
                directions={directionsResponse}
              />
            )}
        </GoogleMap>
      )}
    </div>
  );
};

const LogsBottomModal = ({
  openLogDialogBox = false,
  vehicleType,
  setVehicleType,
  setOpenTaxiTypeModal,
}) => {
  const vehicleTypes = [
    {
      id: "mini",
      displayText: "Taxi-Mini",
    },
    {
      id: "sedan",
      displayText: "Taxi-Sedan",
    },
    {
      id: "suv",
      displayText: "Taxi-SUV",
    },
    {
      id: "innova",
      displayText: "Innova",
    },
  ];

  return (
    <>
      {openLogDialogBox && (
        <div className={styles.logsBottomModal}>
          <div className={styles.modalBackdrop}>
            <div className={styles.visibleModal}>
              <h3 className={styles.modalMainHeading}>
                {"Select Vehicle Type"}
              </h3>
              {vehicleTypes.map((each) => (
                <p
                  onClick={() => {
                    //
                    // select this id
                    setVehicleType(each.id);
                  }}
                  style={{
                    width: "100%",
                    fontSize: "1.8rem",
                    border: `${vehicleType === each.id ? "2px" : "1px"} solid ${
                      vehicleType === each.id ? "orange" : "gray"
                    }`,
                    padding: "1rem 1rem",
                    borderRadius: "5px",
                  }}
                >
                  {each.displayText}
                </p>
              ))}

              <button
                style={{
                  width: "min-content",
                  margin: "auto",
                }}
                onClick={() => {
                  setOpenTaxiTypeModal(false);
                }}
                className={styles.button}
              >
                {"Done"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ConfirmationDialog = ({
  openLogDialogBox = false,
  bookRideButtonHandler,
  setIsConfirmationDialogOpen,
  pickupAddressText,
  dropAddressText,
  distance,
  duration,
  scheduleDate,
  scheduleTime,
  returnType,
  vehicleType,
}) => {
  function formatDate(inputDate) {
    const parts = inputDate.split("-");
    if (parts.length === 3) {
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    } else {
      return "Invalid date format";
    }
  }

  function convertTo12HourFormat(inputTime) {
    const [hours, minutes, seconds] = inputTime.split(":");
    let period = "AM";

    let hours12 = parseInt(hours, 10);

    if (hours12 >= 12) {
      period = "PM";
      if (hours12 > 12) {
        hours12 -= 12;
      }
    }

    if (hours12 === 0) {
      hours12 = 12;
    }

    return `${hours12}:${minutes} ${period}`;
  }
  return (
    <>
      {openLogDialogBox && (
        <div
          style={{
            fontSize: "1.6rem",
          }}
          className={styles.logsBottomModal}
        >
          <div className={styles.modalBackdrop}>
            <div className={styles.visibleModal}>
              <h3 className={styles.modalMainHeading}>
                {"Confirm Ride Details"}
              </h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                className=""
              >
                <p>
                  <span
                    style={{
                      color: "yellow",
                    }}
                  >
                    Pickup address:
                  </span>{" "}
                  {pickupAddressText}
                </p>
                <p>
                  <span
                    style={{
                      color: "yellow",
                    }}
                  >
                    Drop address:
                  </span>{" "}
                  {dropAddressText}
                </p>
                <p className="">
                  <span
                    style={{
                      color: "yellow",
                    }}
                  >
                    Date:{" "}
                  </span>
                  {formatDate(scheduleDate)},&nbsp;
                </p>
                <p className="">
                  <span
                    style={{
                      color: "yellow",
                    }}
                  >
                    Time:{" "}
                  </span>
                  {convertTo12HourFormat(scheduleTime)}
                </p>
                <p className="">
                  <span
                    style={{
                      color: "yellow",
                    }}
                  >
                    Return Trip:{" "}
                  </span>
                  {returnType ? "Yes" : "No"}
                </p>
                <p className="">
                  {vehicleType === "mini"
                    ? "Taxi-Mini"
                    : vehicleType === "sedan"
                    ? "Taxi-Sedan"
                    : vehicleType === "suv"
                    ? "Taxi-SUV"
                    : vehicleType === "innova"
                    ? "Innova"
                    : ""}
                </p>
              </div>
              <div className={styles.distanceDurationRow}>
                <p>
                  <span
                    style={{
                      color: "yellow",
                    }}
                  >
                    Distance:
                  </span>{" "}
                  {distance}
                </p>
                <p>
                  <span
                    style={{
                      color: "yellow",
                    }}
                  >
                    Duration:
                  </span>{" "}
                  {duration}
                </p>
              </div>
              <div className={styles.buttonRow}>
                <button
                  onClick={() => {
                    //
                    setIsConfirmationDialogOpen(false);
                  }}
                  className={styles.button}
                >
                  {"Edit"}
                </button>
                <button
                  style={{
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    // setOpenTaxiTypeModal(false);
                    bookRideButtonHandler();
                  }}
                  className={styles.button}
                >
                  {"Next"}
                </button>
              </div>
              <p
                style={{
                  textAlign: "center",
                  marginTop: "-3rem",
                }}
                className=""
              >
                Click{" "}
                <span
                  style={{
                    fontWeight: "bolder",
                  }}
                >
                  Next
                </span>{" "}
                to see fare
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ModalDateTimeSelection = ({
  isDateTimeModalOpen = false,
  setIsDateTimeModalOpen,
  selectedDate,
  setSelectedDate,
  dateText,
  selecetedTime,
  setSelecetedTime,
  HOURS,
  MINUTES,
  setSelectedDateTime,
}) => {
  return (
    <>
      {isDateTimeModalOpen && (
        <div className={styles.logsBottomModal}>
          <div className={styles.modalBackdrop}>
            <div className={styles.visibleModal}>
              <h3
                style={{
                  fontSize: "2.2rem",
                }}
                className={styles.modalMainHeading}
              >
                {"Select date & time"}
              </h3>
              {/* date */}
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#525252",
                  // height: "3rem",
                  display: "flex",
                  borderRadius: "5px",
                }}
                className="w-full bg-zinc-900 rounded-md flex h-12 mt-3"
              >
                <button
                  onClick={() => {
                    setSelectedDate(1);
                    // setSelectedTimeSlot({
                    //   displaySlotTime: null,
                    //   sendToServerSlotTime: null,
                    // });
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    margin: "0.5rem",
                    backgroundColor: selectedDate === 1 ? "#15803D" : "",
                  }}
                  className={`${styles.buttonReset}  w-full rounded-sm m-2 ${
                    selectedDate === 1 ? "bg-green-700" : ""
                  }`}
                >
                  {dateText[0].displaytext}
                </button>
                <button
                  onClick={() => {
                    setSelectedDate(2);
                    // setSelectedTimeSlot({
                    //   displaySlotTime: null,
                    //   sendToServerSlotTime: null,
                    // });
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    margin: "0.5rem",
                    backgroundColor: selectedDate === 2 ? "#15803D" : "",
                  }}
                  className={`${styles.buttonReset} w-full m-2 ${
                    selectedDate === 2 ? "bg-green-700" : ""
                  }`}
                >
                  {dateText[1].displaytext}
                </button>
                <button
                  onClick={() => {
                    setSelectedDate(3);
                    // setSelectedTimeSlot({
                    //   displaySlotTime: null,
                    //   sendToServerSlotTime: null,
                    // });
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    margin: "0.5rem",
                    backgroundColor: selectedDate === 3 ? "#15803D" : "",
                  }}
                  className={`${styles.buttonReset} w-full m-2 ${
                    selectedDate === 3 ? "bg-green-700" : ""
                  }`}
                >
                  {dateText[2].displaytext}
                </button>
              </div>
              {/* date */}
              {/* Time */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  fontSize: "1.6rem",
                }}
              >
                <div>
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Hrs
                  </p>
                  <div
                    style={{
                      height: "100px",
                      backgroundColor: "#525252",
                      padding: "0.5rem 1rem",
                      overflowY: "auto",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                      borderRadius: "5px",
                    }}
                  >
                    {HOURS.map((each) => (
                      <p
                        style={{
                          padding: "3px",
                          backgroundColor:
                            selecetedTime.hours.toString() === each.toString()
                              ? "#15803D"
                              : "",
                          borderRadius: "4px",
                        }}
                        key={each}
                        onClick={() => {
                          setSelecetedTime((prev) => ({
                            ...prev,
                            hours: each,
                          }));
                        }}
                      >
                        {each}
                      </p>
                    ))}
                  </div>
                </div>
                <div>
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Min
                  </p>
                  <div
                    style={{
                      height: "100px",
                      overflowY: "auto",
                      backgroundColor: "#525252",
                      padding: "0.5rem 1rem",
                      textAlign: "center",
                      borderRadius: "5px",
                    }}
                  >
                    {MINUTES.map((each) => (
                      <p
                        style={{
                          padding: "3px",
                          backgroundColor:
                            selecetedTime.minutes.toString() === each.toString()
                              ? "#15803D"
                              : "",
                          borderRadius: "4px",
                        }}
                        key={each}
                        onClick={() => {
                          setSelecetedTime((prev) => ({
                            ...prev,
                            minutes: each,
                          }));
                        }}
                      >
                        {each}
                      </p>
                    ))}
                  </div>
                </div>
                <div>
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    AM/ PM
                  </p>
                  <div
                    style={{
                      height: "100px",
                      backgroundColor: "#525252",
                      padding: "0.5rem ",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      borderRadius: "5px",
                    }}
                  >
                    <p
                      style={{
                        backgroundColor:
                          selecetedTime.amPm === "AM" ? "#15803D" : "",
                        padding: "0.5rem",
                        borderRadius: "4px",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        setSelecetedTime((prev) => ({ ...prev, amPm: "AM" }));
                      }}
                    >
                      AM
                    </p>
                    <p
                      style={{
                        backgroundColor:
                          selecetedTime.amPm === "PM" ? "#15803D" : "",
                        padding: "0.5rem",
                        borderRadius: "4px",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        setSelecetedTime((prev) => ({ ...prev, amPm: "PM" }));
                      }}
                    >
                      PM
                    </p>
                  </div>
                </div>
              </div>
              {/* Time */}
              <div
                style={{
                  fontSize: "1.8rem",
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "2px",
                }}
              >
                <p className="">
                  {dateText[selectedDate - 1]?.date || "Select date"}{" "}
                </p>
                {selecetedTime?.hours &&
                  selecetedTime?.minutes &&
                  selecetedTime?.amPm && (
                    <p className="">
                      {selecetedTime.hours}:{selecetedTime.minutes}
                      &nbsp;
                      {selecetedTime.amPm}
                    </p>
                  )}
              </div>
              <button
                style={{
                  width: "min-content",
                  margin: "auto",
                  marginBottom: "1.5rem",
                }}
                onClick={() => {
                  // setOpenTaxiTypeModal(false);
                  setSelectedDateTime();
                }}
                className={styles.button}
              >
                {"Set"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
