import React from "react";
// eslint-disable-next-line
import styles from "./LogsBottomModal.module.css";

const LogsBottomModal = ({
  openLogDialogBox = true,
  downloadLogsButton,
  downloadButtonText = "Downlaod",
  cancelButton,
  cancelButtonText = "Close dialog",
  heading = "Log File Created",
  para,
  isSupportMainVisible = false,
}) => {
  return (
    <>
      {openLogDialogBox && (
        <div className={styles.logsBottomModal}>
          <div className={styles.modalBackdrop}>
            <div className={styles.visibleModal}>
              <h3 className={styles.modalMainHeading}>{heading}</h3>
              {isSupportMainVisible && (
                <p className={styles.email}>support@taxiwars.in</p>
              )}
              {para && <p className={styles.para}>{para}</p>}
              <div className={styles.buttonRow}>
                {cancelButtonText ? (
                  <button onClick={cancelButton} className={styles.button}>
                    {cancelButtonText}
                  </button>
                ) : (
                  <></>
                )}
                <button onClick={downloadLogsButton} className={styles.button}>
                  {downloadButtonText}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LogsBottomModal;
