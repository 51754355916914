import { createSlice } from "@reduxjs/toolkit";

// add new modals and their state here only
const customModalSlice = createSlice({
  name: "customModal",
  initialState: {
    isPaymentSelectionModalOpen: false,
  },
  reducers: {
    // do not add persistence in modals
    openCustomModalDialogBox(state) {
      state.isPaymentSelectionModalOpen = true;
    },
    closeCustomModalDialogBox(state) {
      state.isPaymentSelectionModalOpen = false;
    },
  },
});

export const customModalActions = customModalSlice.actions;
export default customModalSlice;
