// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBiKlC5hK8-7oydcdRwj3Rwm8Ri_lfumBk",
  authDomain: "taxiwars-firebase-customer.firebaseapp.com",
  projectId: "taxiwars-firebase-customer",
  storageBucket: "taxiwars-firebase-customer.appspot.com",
  messagingSenderId: "765151776586",
  appId: "1:765151776586:web:1d54be8eca54bb2d66bbb3",
  measurementId: "G-GRP57WFE47",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BLAzwDUDTN9tBS2UsE9QDIrh_XOFyWMcK5OpLrdIFqhtfmmsblOqRjzpOgvGu2gHW16-DBidWprM8Z2pto3-h1o",
    });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      return currentToken;
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
