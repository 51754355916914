import React from "react";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Outlet } from "react-router-dom";
import { GOOGLE_MAPS_API_KEY } from "../../../constants/apiConstants";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

const RiderAuthWrapper = () => {
  const { isPlacePredictionsLoading } = usePlacesAutocompleteService({
    apiKey: GOOGLE_MAPS_API_KEY,
    debounce: 500,
  });
  return <>{isPlacePredictionsLoading ? <LoadingSpinner /> : <Outlet />}</>;
};

export default RiderAuthWrapper;
