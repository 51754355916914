import { createSlice } from "@reduxjs/toolkit";

const createdRideId = "createdRideId";

const riderCreatedRideSlice = createSlice({
  name: "riderCreatedRide",
  initialState: {
    createdRideId: localStorage.getItem(createdRideId) || "",
  },
  reducers: {
    setCreatedRideId(state, action) {
      state.createdRideId = action.payload;
      localStorage.setItem(createdRideId, action.payload);
    },

    clearCreateRideId(state) {
      state.createdRideId = "";
      localStorage.removeItem(createdRideId);
    },
  },
});

export const riderCreatedRideActions = riderCreatedRideSlice.actions;
export default riderCreatedRideSlice;
