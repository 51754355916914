import React from "react";
// eslint-disable-next-line
import styles from "./Button.module.css";

const Button = ({
  style,
  displayText = "prop-Button",
  onClickFunction = () => {
    console.warn("TODO - Function not connected");
  },
}) => {
  return (
    <div style={style} className={styles.button} onClick={onClickFunction}>
      {displayText}
    </div>
  );
};

export default Button;
