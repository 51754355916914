import React from "react";
// eslint-disable-next-line
import styles from "./CampaignDialogBox.module.css";
import { images } from "../../images";
import { useNavigate } from "react-router-dom";
import { ADV_POST_A_RIDE_TO_ROUTE } from "../../constants/routeConstants";

const CampaignDialogBox = ({ visibility = false, onCloseClick = () => {} }) => {
  // const navigate = useNavigate();
  // const advanceBookingPageClickHnalder = () => {
  //   //navigation
  //   navigate(`${ADV_POST_A_RIDE_TO_ROUTE}`);

  //   onCloseClick();
  // };

  return (
    <>
      {visibility && (
        <div className={styles.campaignDialogBox}>
          <div className={styles.modalBackdrop}>
            <div className={styles.visibleModal}>
              <p style={{ fontWeight: "bold", fontSize: "1.8rem" }}>
                🚖{" "}
                <span style={{ fontWeight: "bold" }}>
                  *Special Offer for First-Time Users!*
                </span>{" "}
                🎉
                <br />
                <br /> Use code{" "}
                <span style={{ fontWeight: "bold" }}>*TaxiFirstoffer*:</span>
                <br />
                <br />
                ✅ ₹20 cashback on your first local ride
                <br />
                ✅ ₹50 cashback on your first intercity ride
                <br />
                <br />
                Book now and save!{" "}
                <span style={{ fontWeight: "bold" }}>Terms apply.</span>
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "1.8rem",
                  gap: "0.6rem",
                }}
              >
                {/* <p onClick={advanceBookingPageClickHnalder}>
                  Tap to book your ride
                </p> */}
                <p style={{ textAlign: "center" }}>To claim cashback</p>
                <p style={{ textAlign: "center" }}>
                  Go to Menu {">"} Claim cashback
                </p>
              </div>
              <div className={styles.buttonRow}>
                <button
                  style={{
                    marginTop: "-1rem",
                    marginBottom: "-1rem",
                  }}
                  onClick={onCloseClick}
                  className={styles.button}
                >
                  {"Close"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CampaignDialogBox;
