import React from "react";
import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./VisitedHistoryList.module.css";

const VisitedHistoryList = ({
  addressLineOne,
  addressLineTwo,
  showClockIcon = false,
  showLocationIcon = false,
}) => {
  return (
    <div className={styles.visitedHistoryList}>
      <div className={styles.row}>
        {showClockIcon && (
          <img className={styles.starImage} src={images.clockThin} alt="star" />
        )}
        {showLocationIcon && (
          <img className={styles.mapThin} src={images.mapThin} alt="star" />
        )}
        <div className={styles.textPara}>
          <p className={styles.textLine}>{addressLineOne}</p>
          <p className={styles.subTextLine}>{addressLineTwo}</p>
        </div>
      </div>
      <hr className={styles.hr} />
    </div>
  );
};

export default VisitedHistoryList;
