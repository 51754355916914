import React from "react";
import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./BidCard.module.css";

const BidCard = ({
  bidId,
  selectedBidId,
  price = "NA",
  timeToReach = "5",
  driverName = "NA",
  driverRating = "5",
  driverImage = images.userGeneric,
  vehicleNumber = "NA",
  vehicleName = "NA",
  vehicleType = "",
  vehicleImage = images.cab,
  setSelectedBid = () => {
    console.warn("prop-Function not connected");
  },
}) => {
  const onClick = () => {
    setSelectedBid(bidId);
  };
  return (
    <div
      onClick={onClick}
      className={`${styles.bidCard} ${
        bidId === selectedBidId ? styles.selected : ""
      }`}
    >
      <div className={styles.writtenContent}>
        <div className={styles.priceAway}>
          <p className={styles.priceText}>
            ₹{price}
            <span className={styles.vehicleTypeSpanStyle}>{vehicleType}</span>
          </p>
          <p>{timeToReach} away</p>
        </div>
        <div className={styles.nameRatingCarDetails}>
          <p>
            {driverName} | <img src={images.orangeStar} alt="orange star" />{" "}
            {driverRating}
          </p>
          <p>
            {vehicleNumber} | {vehicleName}
          </p>
        </div>
      </div>
      <div className={styles.imageContent}>
        <img className={styles.cab} src={vehicleImage} alt="car" />
        <img
          className={styles.userGeneric}
          src={driverImage}
          alt="user profile"
        />
      </div>
    </div>
  );
};

export default BidCard;
