import React from "react";
import {
  BackPageHeadingWrapper,
  Button,
  ErrorInNumber,
  InputBox,
  InputBoxLargeMessage,
  LoadingSpinner,
} from "../../../components";
// eslint-disable-next-line
import styles from "./RiderHelp.module.css";
import {
  BASE_URL,
  RIDER_GET_HELP,
  RIDER_PROFILE_DETAILS,
} from "../../../constants/apiConstants";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { RIDER_HOME_POST_A_RIDE__TO_ROUTE } from "../../../constants/routeConstants";
import { useNavigateOnEvent } from "../../../hooks";

const RiderHelp = () => {
  const navigateToPostRideTo = useNavigateOnEvent(
    RIDER_HOME_POST_A_RIDE__TO_ROUTE,
    true
  );

  const [helpMessage, setHelpMessage] = useState("");
  const [value, setValue] = useState({});
  const [boolShowError, setBoolShowError] = useState("");
  const loadData = () => {
    const accessToken = Cookies.get("access");

    fetch(`${BASE_URL}${RIDER_PROFILE_DETAILS}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setValue(data);
      })
      .catch((e) => {
        console.log("Error occured", e);
      })
      .finally(() => {
        // console.log("Finally");
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const submitButtonHandler = () => {
    // return ;
    if (!helpMessage) {
      setBoolShowError("Please type your message");
      return;
    }
    toast.success("Sending message...", {
      toastId: "message",
      autoClose: false,
      icon: <LoadingSpinner />,
      hideProgressBar: false,
      closeOnClick: true,
      // pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    fetch(`${BASE_URL}${RIDER_GET_HELP}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ message: helpMessage }),
    })
      .then((response) => {
        if (response.ok) {
          toast.update("message", {
            render: "Message Sent ",
            type: toast.TYPE.SUCCESS,
            autoClose: 20000,
            closeButton: true,
            icon: null,
            // transition: Slide,
            position: toast.POSITION.TOP_CENTER,
          });
          navigateToPostRideTo();
          return response.json();
        }
        throw response;
      })
      .catch((error) => {
        toast.update("message", {
          render: "Something went wrong",
          type: toast.TYPE.ERROR,
          autoClose: 20000,
          closeButton: true,
          icon: null,
          // transition: Slide,
          position: toast.POSITION.TOP_CENTER,
        });
        navigateToPostRideTo();
        console.log("Error in sending help: ", error);
        setBoolShowError("Something went wrong!");
      });
  };

  const helpMessageOnChangeHandler = (e) => {
    setHelpMessage(e.target.value);
    setBoolShowError("");
  };
  return (
    <BackPageHeadingWrapper logoHeadingText="Help">
      <div className={styles.riderHelp}>
        <InputBox
          idAndName="name"
          placeholderText="Enter your name"
          value={value?.first_name}
          onChange={() => {}}
        />
        <InputBox
          idAndName="emailAdress"
          type="email"
          placeholderText="Enter email address"
          onChange={() => {}}
          value={value?.email}
        />
        <InputBox
          idAndName="phoneNumber"
          type="tel"
          placeholderText="Enter phone number"
          value={value?.phone_number}
          onChange={() => {}}
        />
        <InputBoxLargeMessage
          value={helpMessage}
          onChange={helpMessageOnChangeHandler}
        />
        {boolShowError && <ErrorInNumber displayText={boolShowError} />}
        <Button displayText="Submit" onClickFunction={submitButtonHandler} />
      </div>
    </BackPageHeadingWrapper>
  );
};

export default RiderHelp;
