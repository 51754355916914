import { createSlice } from "@reduxjs/toolkit";

const riderCurrentRideSlice = createSlice({
  name: "riderCurrentRide",
  initialState: {
    currentRIdeId: "",
    currentRIdeIdDetails: {},
    currentRideStatus: "",
  },
  reducers: {
    /**
     * Set the current ride id
     * @param {*} state
     * @param {rider id} action
     */
    setCurrentRideId(state, action) {
      state.currentRIdeId = action.payload;
    },

    /**
     *
     * @param {current Ride details} state
     * @param {Object curretn ride details} action
     */
    setCurrentRideIdDetails(state, action) {
      state.currentRIdeIdDetails = action.payload;
    },

    /**
     *
     * @param {ride status} action
     */
    setCurrentRideStatus(state, action) {
      state.currentRideStatus = action.payload;
    },
  },
});

export const riderCurrentRideActions = riderCurrentRideSlice.actions;
export default riderCurrentRideSlice;
