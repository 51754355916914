import React from "react";
// eslint-disable-next-line
import styles from "./OtpAndPragraph.module.css";

const OtpAndPragraph = ({ price, otpNumber }) => {
  return (
    <div className={styles.otpAndParagraph}>
      <p className={styles.otpOnly}>
        ₹ {parseFloat(price / 100).toFixed(1)} ( OTP - {otpNumber} )
      </p>
      <p className={styles.paragraphOnly}>
        Please share this OTP with driver to start your trip
      </p>
    </div>
  );
};

export default OtpAndPragraph;
