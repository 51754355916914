import React from "react";
import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./SelectOnMap.module.css";

const SelectOnMap = () => {
  return (
    <div className={styles.selectOnMap}>
      <div className={styles.row}>
        <img className={styles.starImage} src={images.location} alt="star" />
        <p className={styles.textLine}>Select destination on Map</p>
      </div>
      <hr className={styles.hr} />
    </div>
  );
};

export default SelectOnMap;
