import styles from "./PaidAcknowledgementCheckbox.module.css";

const PaidAcknowledgementCheckbox = ({
  label,
  isChecked,
  onCheckboxChange,
}) => {
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    onCheckboxChange(isChecked);
  };
  return (
    <div className={styles.paidAcknowledgementCheckbox}>
      <label className={styles.labelContainer}>
        <span></span>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span className={styles.checkmark}></span>
        {label}
      </label>
    </div>
  );
};

export default PaidAcknowledgementCheckbox;
