import { useNavigate } from "react-router-dom";
import styles from "./MakePaymentCcAvenueAdvanceBooking.module.css";
import {
  useInterval,
  useLogoutHandler,
  useNavigateOnEvent,
  usePreventBackBehaviour,
} from "../../../hooks";
import { useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  FILTER_CONSTANT_AUTO,
  FILTER_CONSTANT_BOTH,
  FILTER_CONSTANT_TAXI,
  PACKAGE_12_HOUR,
  USER_LOCAL_STORAGE,
} from "../../../constants/storageConstants";
import Cookies from "js-cookie";
import {
  ADV_CC_AVENUE_REQUEST_HANDLER,
  ADV_GET_PAYMENT_STATUS,
  ADV_RIDER_CANCEL_WITHOUT_LIABILITY,
  BASE_URL,
  CCAVENUE_BASE_URL_LIVE,
  CODE_200,
  CODE_301,
  CODE_302,
  RIDER_GET_BIDS_ADVANCE_BOOKING_AUTOMATE,
  RIDER_GET_RIDE_DETAILS_ADVANCE_BOOKING,
  RIDER_PAYMENT_CASH_METHOD_ADVANCE_BOOKING_AUTOMATE,
  RIDER_PROFILE_DETAILS,
} from "../../../constants/apiConstants";
import {
  BackPageHeadingWrapper,
  BidCard,
  Button,
  LoadingBids,
  LoadingSpinner,
  LogsBottomModal,
  TopGreenCheckStatus,
} from "../../../components";
import { toast } from "react-toastify";
import {
  ADV_POST_A_RIDE_TO_ROUTE,
  ADV_RIDER_RIDE_ARRIVING_ROUTE_ASSIGNED_DRIVER_ROUTE,
  ADV_RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE,
  ADV_RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE_WAITING_PAGE,
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
} from "../../../constants/routeConstants";
import { riderPostRideAdvanceBookingActions } from "../../../store/slice";
import { ONE_SECOND } from "../../../constants/timeConstants";
import { images } from "../../../images";

const MakePaymentCcAvenueAdvanceBooking = () => {
  usePreventBackBehaviour();
  const iframeRef = useRef(null);

  const navigate = useNavigate();
  const logoutFunction = useLogoutHandler();
  const dispatch = useDispatch();
  const [urlParams] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const rideId = searchParams.get("rideId");
    return { rideId: rideId };
  });

  // rideId: "12",
  //   pickupAddressText: "pickup adddres  d hjkl jkh h jkhk kjhkjhk k ",
  //   dropAddressText: "pickup adddres  d hjkl jkh h jkhk kjhkjhk k ",
  //   rideDistance: "25 km",
  //   rideDuration: "25 min",
  //   estimatedFare: "5100", // in paise
  //   tokenMoney: "1100", // in paise
  //   paymentStatus: "pending", // "pending" | "confirmed"
  //   scheduledDate: "2022-11-22",
  //   scheduledTime: "13:55:15",
  const [currentRideDetails, setCurrentRideDetails] = useState({
    rideId: "",
    pickupAddressText: "",
    dropAddressText: "",
    rideDistance: "",
    rideDuration: "",
    estimatedFare: "", // in paise
    tokenMoney: "", // in paise
    paymentStatus: "", // "pending" | "confirmed"
    scheduledDate: "",
    scheduledTime: "",
    returnType: false,
    estimatedFareLowerLimit: "",
    estimatedFareUpperLimit: "",
  });

  const getRideDetails = async () => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const url = `${BASE_URL}${RIDER_GET_RIDE_DETAILS_ADVANCE_BOOKING}`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: parseInt(urlParams.rideId),
      }),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      const data = await response.json();
      // console.log(data);
      setCurrentRideDetails({
        pickupAddressText: data?.pickup_address_text,
        dropAddressText: data?.drop_address_text,
        rideDistance: data?.ride_distance,
        rideDuration: data?.ride_duration,
        estimatedFare: data?.estimated_fare,
        tokenMoney: data?.token_money,
        paymentStatus: data?.payment_status,
        scheduledDate: data?.scheduled_date,
        scheduledTime: data?.scheduled_time,
        rideId: data?.ride_id,
        returnType: data?.return_type,
        estimatedFareLowerLimit: data?.estimated_fare_lower_limit,
        estimatedFareUpperLimit: data?.esitmated_fare_upper_limit,
      });
    }
  };

  useEffect(() => {
    getRideDetails();
  }, []);

  const createdRideId = urlParams.rideId;
  const navigateToHomeScreen = useNavigateOnEvent(ADV_POST_A_RIDE_TO_ROUTE);

  useEffect(() => {
    const confirmDataAndAuth = async (ride, user, mobile, paystatus) => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      const fetchConfirmUrl = `${BASE_URL}${RIDER_PROFILE_DETAILS}`;
      const fetchConfirmOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(fetchConfirmUrl, fetchConfirmOptions);

      if (response.status !== 200) {
        logoutFunction();
      }

      // console.log("Response", response);

      if (response.ok) {
        const data = await response.json();
        const phoneNumber = data.phone_number;
        const userId = data.user_id;
        console.log("payment data", data);
        if (
          phoneNumber.toString() !== mobile.toString() &&
          userId.toString() !== user.toString()
        ) {
          logoutFunction();
          return;
        }
        if (
          phoneNumber.toString() === mobile.toString() &&
          userId.toString() === user.toString() &&
          paystatus.toString() === "Success"
        ) {
          toast.success("Payment Success - Booking Confirmed");
          // navigateToRideArrivingScreen();
          navigate(
            `${ADV_RIDER_RIDE_ARRIVING_ROUTE_ASSIGNED_DRIVER_ROUTE}?rideId=${ride}`,
            {
              replace: true,
            }
          );
          return;
        }
        if (
          phoneNumber.toString() === mobile.toString() &&
          userId.toString() === user.toString() &&
          paystatus.toString() === "Failure"
        ) {
          toast.error("Payment Failed");
          // navigateToWaitingForBidScreen();
          navigate(
            `${ADV_RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE}?rideId=${ride}`,
            {
              replace: true,
            }
          );
          return;
        }

        toast.info("Waiting...");
        navigate(
          `${ADV_RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE_WAITING_PAGE}?rideId=${ride}`,
          {
            replace: true,
          }
        );
      }
    };

    const searchParams = new URLSearchParams(window.location.search);
    const ride = searchParams.get("ride");

    const user = searchParams.get("user");
    const mobile = searchParams.get("mobile");
    const paystatus = searchParams.get("paystatus");

    if (ride && user && mobile && paystatus) {
      confirmDataAndAuth(ride, user, mobile, paystatus);
    }
    console.log("Redirected url params", ride, user, mobile, paystatus);
  }, []);

  const loadIframe = (src) => {
    return new Promise((resolve) => {
      const iframe = document.createElement("iframe");
      iframe.src = src;
      iframe.onload = () => {
        resolve(true);
      };
      iframe.onerror = () => {
        resolve(false);
      };
      iframe.style.position = "fixed";
      iframe.style.top = "0";
      //   iframe.style.left = "0";
      //   iframe.style.transform = "translateX(50%)";
      //   iframe.style.right = "0";
      iframe.style.border = `none`;
      iframe.style.padding = `5px`;
      //   iframe.style.paddingTop = `5px`;
      iframe.style.borderRadius = `25px`;
      //   iframe.style.overflow = "hidden";
      iframe.style.width = `min(425px, 100vw)`;
      iframe.style.height = "100%";
      iframe.style.zIndex = "9999"; // Set a high z-index value
      iframe.style.background = "white";
      iframeRef.current = iframe;
      document.body.appendChild(iframe);
    });
  };

  const onClickPaymentAndNavigationHandler = async () => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

    const fetchEncryptedDataUrl = `${BASE_URL}${ADV_CC_AVENUE_REQUEST_HANDLER}`;
    const fetchEncryptedDataOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: parseInt(createdRideId),
      }),
    };

    const encDataResponse = await fetch(
      fetchEncryptedDataUrl,
      fetchEncryptedDataOptions
    );

    if (!encDataResponse.ok) {
      console.log("Encrypted response not okay");
      return;
    }

    const encData = await encDataResponse.json();

    try {
      const ifr = `${CCAVENUE_BASE_URL_LIVE}/transaction/transaction.do?command=initiateTransaction&merchant_id=2492482&encRequest=${encData.encryption}&access_code=${encData.accessCode}`;
      // const iframeSrc = "https://www.google.com/webhp?igu=1";
      const success = await loadIframe(ifr);
      if (success) {
        console.log("Iframe loaded successfully");
      } else {
        console.error("Error loading iframe");
      }
    } catch (error) {
      console.error("Error loading iframe:", error);
    }
  };

  const cancelButtonOnClickFunctionHandler = async () => {
    // console.log("Cancelling ride ...");

    // clear state for post ride
    dispatch(riderPostRideAdvanceBookingActions.clearPostRide());

    // API Call for
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const fetchUrl = `${BASE_URL}${ADV_RIDER_CANCEL_WITHOUT_LIABILITY}`;
    const fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: parseInt(urlParams.rideId),
        value: "OTP",
      }),
    };

    try {
      const response = await fetch(fetchUrl, fetchOptions);

      if (response.ok) {
        toast.info("Ride has been cancelled");
        // console.log("Toast sent ✔");
        // navigate to home screen for booking new ride
        // console.log("Navigating to home screen...");
        navigateToHomeScreen();
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log("Something went wrong. Printing error message...");
      console.log(error);
    }
  };
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isPaymentSelectionDialogOpen, setIsPaymentSelectionDialogOpen] =
    useState(false);

  //
  const [
    selectedBidFromSelectBidContinue,
    setSelectedBidFromSelectBidContinue,
  ] = useState("");

  const setSelectedBidUpliftHandler = (selectedBidFromSelectBidContinue) => {
    setSelectedBidFromSelectBidContinue(selectedBidFromSelectBidContinue);
    setIsPaymentSelectionDialogOpen(true);
  };

  const cashPaymentMethodHandler = async () => {
    toast.success("Booking your ride", {
      toastId: "cashbookAdvanceAutomatic",
      autoClose: false,
      icon: <LoadingSpinner />,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    // console.log("cash...");
    const searchParams = new URLSearchParams(window.location.search);
    // somewhere while navigating the ride id is passed as ride instead of rideId so
    // we added the or statement to safegaurd against that case
    const ride = searchParams.get("ride");
    const rideID = parseInt(urlParams.rideId) || parseInt(ride);

    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const fetchCashUrl = `${BASE_URL}${RIDER_PAYMENT_CASH_METHOD_ADVANCE_BOOKING_AUTOMATE}`;
    const fetchCashOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        user_id:
          JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE))?.user_id ||
          undefined,
        ride_id: urlParams.rideId,
        bid_id: selectedBidFromSelectBidContinue,
      }),
    };

    const responseCash = await fetch(fetchCashUrl, fetchCashOptions);

    if (!responseCash.ok) {
      toast.update("cashbookAdvanceAutomatic", {
        render: "Network error !",
        type: toast.TYPE.ERROR,
        autoClose: 20000,
        closeButton: true,
        icon: null,
        // transition: Slide,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const dataFromCash = await responseCash.json();

    if (dataFromCash.Code === CODE_200) {
      // console.log(dataFromCash);

      // get bid id  and ride id from backend then navigate
      toast.update("cashbookAdvanceAutomatic", {
        render: "Ride Booked !",
        type: toast.TYPE.SUCCESS,
        autoClose: 20000,
        closeButton: true,
        icon: null,
        // transition: Slide,
        position: toast.POSITION.TOP_CENTER,
      });

      // route from adv booking
      navigate(
        `${ADV_RIDER_RIDE_ARRIVING_ROUTE_ASSIGNED_DRIVER_ROUTE}?rideId=${rideID}`,
        {
          replace: true,
        }
      );

      // route from spot booking
      // navigateToRideArrivingScreen();
      // navigate(
      //   `${RIDER_RIDE_ARRIVING_ROUTE}?rideId=${dataFromCash.ride_id}&bidId=${dataFromCash.bid_id}`
      // );
      return;
    }

    if (dataFromCash.Code === "400") {
      toast.update("cashbookAdvanceAutomatic", {
        render: dataFromCash?.message?.toString() || "Something went wrong!",
        type: toast.TYPE.ERROR,
        autoClose: 20000,
        closeButton: true,
        icon: null,
        // transition: Slide,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    toast.update("cashbookAdvanceAutomatic", {
      render: "Something went wrong !",
      type: toast.TYPE.ERROR,
      autoClose: 20000,
      closeButton: true,
      icon: null,
      // transition: Slide,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [bidsList, setBidsList] = useState([]);

  // Bid start
  useEffect(() => {
    const getBdList = () => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(`${BASE_URL}${RIDER_GET_BIDS_ADVANCE_BOOKING_AUTOMATE}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            // console.log("response ok ");

            return response.json();
          }
          console.log("response not ok ");
          throw response;
        })
        .then((data) => {
          // console.log("Bid Data", data);
          if (
            data &&
            Array.isArray(data?.payload) &&
            data?.payload.length > 0
          ) {
            setBidsList(data?.payload);
            // dispatch(riderBidActions.addMultipleBids(data.payload));
          } else if (data && data?.code === CODE_301) {
            setBidsList([]);
            // dispatch(riderCreatedRideActions.clearCreateRideId());
            // dispatch(riderPostRideActions.clearPostRide());
            // dispatch(riderBidActions.addMultipleBids([]));
            // console.log("Data is this - ", data);

            toast.info(
              data?.message || "No drivers available recreate your ride"
            );
            navigateToHomeScreen();
          } else if (data && data?.code === CODE_302) {
            setBidsList([]);
            // dispatch(riderCreatedRideActions.clearCreateRideId());
            // dispatch(riderPostRideActions.clearPostRide());
            // toast.info(
            //   data?.message || "Bid expired, Please recreate your ride"
            // );

            // dispatch(riderBidActions.addMultipleBids([]));
            // console.log("Data is this - ", data);
            navigateToHomeScreen();
          } else {
            setBidsList([]);
            // console.log("CODE ELSE IN BID FILTER ALL Data : ", data);
            // dispatch(riderBidActions.addMultipleBids([]));
          }
        })
        .catch((e) => {
          console.log("error occured", e);
        });
    };

    getBdList();

    const interval = setInterval(() => {
      getBdList();
      // can be changed to any time we want
    }, ONE_SECOND * 5);

    return () => clearInterval(interval);
  }, []);
  // Bid end

  return (
    <BackPageHeadingWrapper
      logoHeadingText="Waiting for bids"
      diableBackButton
      bgGreenTextWhite
    >
      <div className={styles.makePaymentCcAvenue}>
        <LogsBottomModal
          // para={"This ride is not eligible for refund"}
          heading=" Are you sure you want to cancel the ride ?"
          // heading="Cancel Ride ?"
          downloadButtonText={"Yes"}
          cancelButtonText={"No"}
          openLogDialogBox={isCancelDialogOpen}
          downloadLogsButton={() => {
            cancelButtonOnClickFunctionHandler();
          }}
          cancelButton={() => {
            setIsCancelDialogOpen(false);
          }}
          // para={"Cancel Ride ?"}
          // heading="Cancel Ride ?"
          // isSupportMainVisible
        />
        <LogsBottomModal
          heading="Please select your payment method"
          downloadButtonText={"Cash"}
          downloadLogsButton={() => {
            cashPaymentMethodHandler();
          }}
          cancelButtonText={""}
          openLogDialogBox={isPaymentSelectionDialogOpen}
          para={""}
        />
        <TopGreenCheckStatus displayText="" biggerSize />
        <RideCard
          currentRideDetails={currentRideDetails}
          cancelFunction={() => {
            setIsCancelDialogOpen(true);
          }}
        />
      </div>
      {/* Bid Recieving start */}
      {Array.isArray(bidsList) && bidsList.length > 0 ? (
        <SelectBidContinue
          bidData={bidsList}
          setSelectedBidUplift={setSelectedBidUpliftHandler}
        />
      ) : (
        <LoadingBids loadingBidsText={"Wait for drivers to bid"} />
      )}
      {Array.isArray(bidsList) && bidsList.length === 0 ? (
        <HomeButton />
      ) : (
        <></>
      )}
      {/* Bid Recieving end */}
      {currentRideDetails.returnType ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0rem",
          }}
        >
          <p
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "3rem",
            }}
          >
            {`${PACKAGE_12_HOUR} (2 x ${
              currentRideDetails?.rideDistance
            }) + 10 = ${
              2 * currentRideDetails?.rideDistance.split(" ")[0]
            } Kms considered`}
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              // color: "red",
              textAlign: "center",
              // width: "75%",
              marginTop: "3rem",
            }}
          >
            *Extra Hrs Mini- ₹200/hr, Sedan- ₹215/hr, Suv- ₹260/hr
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "3rem",
            }}
          >
            *Extra kms Mini- ₹10/km, Sedan- ₹12/km, Suv- ₹14/km
          </p>
        </div>
      ) : (
        <></>
      )}
    </BackPageHeadingWrapper>
  );
};

export default MakePaymentCcAvenueAdvanceBooking;

const RideCard = ({ currentRideDetails, cancelFunction }) => {
  function formatDate(inputDate) {
    const parts = inputDate.split("-");
    if (parts.length === 3) {
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    } else {
      return "Invalid date format";
    }
  }

  function convertTo12HourFormat(inputTime) {
    const [hours, minutes, seconds] = inputTime.split(":");
    let period = "AM";

    let hours12 = parseInt(hours, 10);

    if (hours12 >= 12) {
      period = "PM";
      if (hours12 > 12) {
        hours12 -= 12;
      }
    }

    if (hours12 === 0) {
      hours12 = 12;
    }

    return `${hours12}:${minutes} ${period}`;
  }
  return (
    <div className={styles.riderNamePickupDropCancelCard}>
      <div className={styles.upperPart}>
        <div className={styles.writtenContent}>
          <div className={styles.riderNameArrivingTime}>
            <p>{"Your ride details"}</p>
          </div>
          <div className={styles.pickupDropPoint}>
            <div
              style={{
                marginBottom: "1rem",
              }}
              className={styles.pickup}
            >
              <span className={`${styles.dot} ${styles.green}`}></span>
              <div>
                <div>
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Pickup -{" "}
                  </span>
                  {`${currentRideDetails?.pickupAddressText}`}
                </div>
              </div>
            </div>
            <div className={styles.drop}>
              <span className={`${styles.dot} ${styles.red}`}></span>
              <div className="">
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Drop -{" "}
                </span>
                {`${currentRideDetails?.dropAddressText}`}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "black",
              fontSize: "1.6rem",
              gap: "2rem",
            }}
          >
            <p>
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                Distance:{" "}
              </span>{" "}
              {currentRideDetails?.rideDistance},&nbsp;
            </p>
            <p>
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                Duration:
              </span>{" "}
              {currentRideDetails?.rideDuration}
            </p>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "centerF",
              color: "black",
              fontSize: "1.6rem",
              gap: "1rem",
              fontWeight: "bold",
            }}
          >
            <p>Scheduled date & time:</p>
            <p>
              {formatDate(currentRideDetails?.scheduledDate)},&nbsp;
              {convertTo12HourFormat(currentRideDetails?.scheduledTime)}
            </p>
            <p></p>
          </div>

          <div
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: "green",
            }}
          >
            <p>
              Approximate Fare: ₹
              {(parseFloat(currentRideDetails?.estimatedFare) / 100).toFixed(1)}
            </p>
            {/* <p
              style={{
                fontSize: "2rem",
                fontWeight: "bold",
              }}
            >
              Booking Amount: ₹
              {(parseFloat(currentRideDetails?.tokenMoney) / 100).toFixed(1)}
            </p> */}
          </div>
          {/* Cancel ride functionality */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              onClick={cancelFunction}
              style={{
                padding: "0.4rem 0.8rem",
                fontSize: "1.6rem",
                width: "max-content",
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Cancel Ride
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

//
const SelectBidContinue = ({ bidData, setSelectedBidUplift }) => {
  const dispatch = useDispatch();
  // const navigateToMakePayment = useNavigateOnEvent(
  //   RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE
  // );
  const [selectedBid, setSelectedBid] = useState("");

  const [filterTerms, setFilterTerms] = useState(FILTER_CONSTANT_BOTH);

  const setSelectedBidHandler = (bidId) => {
    // console.log("Selected bid id", bidId);
    setSelectedBid(bidId);
  };

  const onClickFunctionHandler = () => {
    toast.dismiss();
    if (!selectedBid) {
      // ui slice notifications
      // please select a bid
      // return;
      toast.info("Please select and confirm.");
      return;
    }
    //Offline
    // TODO open modal
    //send selected bid id one level above
    // dispatch(customModalActions.openCustomModalDialogBox());
    setSelectedBidUplift(selectedBid);

    // Now this should be done in modal selection button
    // dispatch(riderBidActions.setSelectedBid(selectedBid));
    // navigateToMakePayment();

    //go without selecting a bid
    // navigateToMakePayment();
  };

  // console.log("The bid data in this component", bidData);
  // return;

  const onClickAutoButtonHandler = () => {
    console.log("auto button ...");
    // deselect the bid
    setSelectedBid("");
    // case
    setFilterTerms((prevState) => {
      // taxi or none then make it auto
      if (
        prevState === FILTER_CONSTANT_TAXI ||
        prevState === FILTER_CONSTANT_BOTH
      ) {
        return FILTER_CONSTANT_AUTO;
      }
      // if auto then make it none
      if (prevState === FILTER_CONSTANT_AUTO) {
        return FILTER_CONSTANT_BOTH;
      }
    });
    // filter only array
    // maintain state after reloading
    // de select selected bid after changing filters
  };

  const onClickTaxiButtonHandler = () => {
    // console.log("taxi button ...");
    // deselect the bid
    setSelectedBid("");
    // case
    setFilterTerms((prevState) => {
      // taxi or none then make it auto
      if (
        prevState === FILTER_CONSTANT_AUTO ||
        prevState === FILTER_CONSTANT_BOTH
      ) {
        return FILTER_CONSTANT_TAXI;
      }
      // if auto then make it none
      if (prevState === FILTER_CONSTANT_TAXI) {
        return FILTER_CONSTANT_BOTH;
      }
    });
  };

  return (
    <>
      <div className={styles.selectBidContinue}>
        <div className={styles.bidHeadingAndSelect}>
          <div className={styles.heading}>
            Select and Confirm the Bid <span>({bidData?.length} recieved)</span>
          </div>
          {/* <div className={styles.sortDivBase}> */}
          {/* <div className={styles.sortDivBase}>
            <button
              onClick={onClickTaxiButtonHandler}
              className={`${styles.filterButton} ${styles.leftButton} ${
                filterTerms === FILTER_CONSTANT_TAXI && styles.selectedFilter
              }`}
            >
              Taxi
            </button>
            <button
              onClick={onClickAutoButtonHandler}
              className={`${styles.filterButton} ${styles.rightButton}  ${
                filterTerms === FILTER_CONSTANT_AUTO && styles.selectedFilter
              }`}
            >
              Auto
            </button>
         
            <p className={styles.sortText}>Sort</p>
            <img
              className={styles.arrowSortUpDown}
              src={images.arrowSortUpDown}
              alt="arrow up down"
            /> 
          </div> */}
        </div>
        <div className={styles.bidCardDiv}>
          {Array.isArray(bidData) &&
            bidData.length > 0 &&
            bidData?.map((eachVehicleBid) => {
              if (
                filterTerms === FILTER_CONSTANT_BOTH ||
                eachVehicleBid?.car_details?.vehicle_type
                  .toLowerCase()
                  .slice(0, 4) === filterTerms.toLowerCase()
              ) {
                return (
                  <React.Fragment key={eachVehicleBid?.id}>
                    <BidCard
                      key={eachVehicleBid?.id}
                      bidId={eachVehicleBid?.id}
                      price={parseFloat(eachVehicleBid?.amount / 100)
                        .toFixed(1)
                        .toString()}
                      timeToReach={eachVehicleBid?.eta_time}
                      driverName={eachVehicleBid?.driver_name}
                      driverRating={5}
                      driverImage={
                        eachVehicleBid?.driver_photo || images.userGeneric
                      }
                      vehicleNumber={
                        eachVehicleBid?.car_details?.registration_name
                      }
                      vehicleName={eachVehicleBid?.car_details?.car_name}
                      vehicleImage={
                        eachVehicleBid?.car_details?.car_photo || images.cab
                      }
                      vehicleType={eachVehicleBid?.car_details?.vehicle_type}
                      selectedBidId={selectedBid}
                      setSelectedBid={() =>
                        setSelectedBidHandler(eachVehicleBid?.id)
                      }
                    />
                  </React.Fragment>
                );
              }
              return null;
            })}
        </div>
        <div className={styles.bottomButton}>
          <Button
            displayText="Confirm Bid"
            onClickFunction={onClickFunctionHandler}
          />
        </div>
      </div>
    </>
  );
};

const HomeButton = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        zIndex: 5,
        position: "absolute",
        bottom: "4rem",
        right: "3rem",
        fontSize: "1.8rem",
        top: `${window.innerHeight * 0.9}px`,
      }}
      className={`${styles.floatingAction} ${styles.right}`}
    >
      <button
        className={styles.homeButton}
        onClick={() => {
          navigate(`${RIDER_HOME_POST_A_RIDE__TO_ROUTE}`);
        }}
      >
        <p
          style={{
            fontSize: "1.6rem",
          }}
          className=""
        >
          Home
        </p>
        <p
          style={{
            fontSize: "1.4rem",
          }}
        >
          {/* (Outstation) */}
        </p>
      </button>
    </div>
  );
};
