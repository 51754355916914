import { createSlice } from "@reduxjs/toolkit";
const inputOriginPlaceId = "inputOriginPlaceId";
const inputOrigin = "inputOrigin";
const inputOriginText = "inputOriginText";
const inputDestinationPlaceId = "inputDestinationPlaceId";
const inputDestination = "inputDestination";
const inputDestinationText = "inputDestinationText";
// const inputTime = "inputTime";

const riderPostRideSlice = createSlice({
  name: "riderPostRide",
  initialState: {
    inputOriginPlaceId: localStorage.getItem(inputOriginPlaceId) || "",
    inputOrigin: JSON.parse(localStorage.getItem(inputOrigin)) || {
      latitude: "",
      longitude: "",
    },
    inputOriginText: JSON.parse(localStorage.getItem(inputOriginText)) || {
      mainAddress: "",
      subAddress: "",
    },
    inputDestinationPlaceId:
      localStorage.getItem(inputDestinationPlaceId) || "",
    inputDestination: JSON.parse(localStorage.getItem(inputDestination)) || {
      latitude: "",
      longitude: "",
    },
    inputDestinationText: JSON.parse(
      localStorage.getItem(inputDestinationText)
    ) || { mainAddress: "", subAddress: "" },
    // inputTime: "now",
  },
  reducers: {
    updateOriginAddress(state, action) {
      state.inputOrigin = action.payload;
      localStorage.setItem(inputOrigin, JSON.stringify(action.payload));
    },

    updateOriginPlaceId(state, action) {
      state.inputOriginPlaceId = action.payload;
      localStorage.setItem(inputOriginPlaceId, action.payload);
    },

    updateOriginAddressText(state, action) {
      state.inputOriginText = action.payload;
      localStorage.setItem(inputOriginText, JSON.stringify(action.payload));
    },

    updateDestinationPlaceId(state, action) {
      state.inputDestinationPlaceId = action.payload;
      localStorage.setItem(inputDestinationPlaceId, action.payload);
    },
    updateDestinationAddress(state, action) {
      state.inputDestination = action.payload;
      localStorage.setItem(inputDestination, JSON.stringify(action.payload));
    },

    updateDestinationAddressText(state, action) {
      state.inputDestinationText = action.payload;
      localStorage.setItem(
        inputDestinationText,
        JSON.stringify(action.payload)
      );
    },

    // updateTime(state, action) {
    //   state.inputTime = action.payload;
    // },
    clearPostRide(state) {
      state.inputOriginPlaceId = "";
      localStorage.removeItem(inputOriginPlaceId);
      state.inputOrigin = { latitude: "", longitude: "" };
      localStorage.removeItem(inputOrigin);
      state.inputOriginText = { mainAddress: "", subAddress: "" };
      localStorage.removeItem(inputOriginText);
      state.inputDestinationPlaceId = "";
      localStorage.removeItem(inputDestinationPlaceId);
      state.inputDestination = { latitude: "", longitude: "" };
      localStorage.removeItem(inputDestination);
      state.inputDestinationText = { mainAddress: "", subAddress: "" };
      localStorage.removeItem(inputDestinationText);
      // state.inputTime = "now";
    },
  },
});

export const riderPostRideActions = riderPostRideSlice.actions;
export default riderPostRideSlice;
