import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomModal,
  LoadingBids,
  LoadingSpinner,
  // LoadingBids,
  NoEditGridFromToCancelSupportBox,
  SelectBidContinue,
  TopGreenCheckStatus,
} from "../../../components";
import {
  BASE_URL,
  CODE_200,
  CODE_301,
  CODE_302,
  RIDER_CANCEL_WITHOUT_LIABILITY,
  RIDER_PAYMENT_CASH_METHOD,
  RIDE_DETAILS_FROM_RIDE_ID,
  // WEB_SOCKET_BASE_URL_RIDER,
} from "../../../constants/apiConstants";
// import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./WaitingForBid.module.css";
import { useEffect } from "react";
import {
  // useInterval,
  useNavigateOnEvent,
  usePreventBackBehaviour,
} from "../../../hooks";

// import useWebSocket from "react-use-websocket";
import {
  customModalActions,
  riderBidActions,
  riderCreatedRideActions,
  riderPostRideActions,
} from "../../../store/slice";
import Cookies from "js-cookie";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  USER_LOCAL_STORAGE,
} from "../../../constants/storageConstants";
import { ONE_SECOND } from "../../../constants/timeConstants";
import { toast } from "react-toastify";
import {
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
  RIDER_RIDE_ARRIVING_ROUTE,
  RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE,
  RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE,
} from "../../../constants/routeConstants";
import { useNavigate } from "react-router-dom";

const WaitingForBid = () => {
  usePreventBackBehaviour();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rideIdUrlParams] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const currentRide = searchParams.get("rideId");
    // console.log("Param ride id", currentRide);
    return currentRide;
  });

  const bids = useSelector((state) => state.riderBid.bids);

  const [
    selectedBidFromSelectBidContinue,
    setSelectedBidFromSelectBidContinue,
  ] = useState("");

  const [adress, setAdress] = useState({
    originAddress: "",
    destinationAddress: "",
  });

  const getAddress = async (rideId) => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const url = `${BASE_URL}${RIDE_DETAILS_FROM_RIDE_ID}`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: rideId,
      }),
    };
    const response = await fetch(url, options);

    if (response.ok) {
      const data = await response.json();

      setAdress({
        originAddress: data?.pickup_address_text,
        destinationAddress: data?.drop_address_text,
      });
    }
  };

  useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const currentRide = searchParams.get("rideId");

    getAddress(currentRide);
  }, []);

  const navigateToHomeScreen = useNavigateOnEvent(
    RIDER_HOME_POST_A_RIDE__TO_ROUTE
  );

  // const navigateToRideArrivingScreen = useNavigateOnEvent(
  //   RIDER_RIDE_ARRIVING_ROUTE
  // );

  // const navigateToMakePayment = useNavigateOnEvent(
  //   RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE
  // );

  // const navigateToCcAvenuePayment = useNavigateOnEvent(
  //   RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE
  // );

  useEffect(() => {
    const getBdList = () => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(`${BASE_URL}/bids/?filters=all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            // console.log("response ok ");

            return response.json();
          }
          console.log("response not ok ");
          throw response;
        })
        .then((data) => {
          // console.log("Bid Data", data);
          if (
            data &&
            Array.isArray(data?.payload) &&
            data?.payload.length > 0
          ) {
            dispatch(riderBidActions.addMultipleBids(data.payload));
          } else if (data && data?.code === CODE_301) {
            dispatch(riderCreatedRideActions.clearCreateRideId());
            dispatch(riderPostRideActions.clearPostRide());
            toast.info(
              data?.message || "No drivers available recreate your ride"
            );

            dispatch(riderBidActions.addMultipleBids([]));
            // console.log("Data is this - ", data);
            navigateToHomeScreen();
          } else if (data && data?.code === CODE_302) {
            dispatch(riderCreatedRideActions.clearCreateRideId());
            dispatch(riderPostRideActions.clearPostRide());
            toast.info(
              data?.message || "Bid expired, Please recreate your ride"
            );

            dispatch(riderBidActions.addMultipleBids([]));
            // console.log("Data is this - ", data);
            navigateToHomeScreen();
          } else {
            // console.log("CODE ELSE IN BID FILTER ALL Data : ", data);
            dispatch(riderBidActions.addMultipleBids([]));
          }
        })
        .catch((e) => {
          console.log("error occured", e);
        });
    };

    getBdList();

    const interval = setInterval(() => {
      getBdList();
      // can be changed to any time we want
    }, ONE_SECOND * 5);

    return () => clearInterval(interval);
  }, [dispatch]);

  const cancelButtonOnClickFunctionHandler = async () => {
    // console.log("Cancelling ride ...");

    // clear state for post ride
    dispatch(riderPostRideActions.clearPostRide());

    // API Call for
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const fetchUrl = `${BASE_URL}${RIDER_CANCEL_WITHOUT_LIABILITY}`;
    const fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        // * CODE_REFACTOR_SEPERATION_OF_CONCERN
        ride_id: rideIdUrlParams,
        value: "",
      }),
    };

    try {
      const response = await fetch(fetchUrl, fetchOptions);

      if (response.ok) {
        // console.log("Ride cancelled from server...");
        // console.log("Clearing local state...");
        // clear redux state for current ride
        dispatch(riderCreatedRideActions.clearCreateRideId());
        // console.log("Local state cleared ✔");
        // info notification
        // console.log("Sending info toast...");
        toast.info("Ride has been cancelled");
        // console.log("Toast sent ✔");
        // navigate to home screen for booking new ride
        // console.log("Navigating to home screen...");
        navigateToHomeScreen();
      }
    } catch (error) {
      console.log("Something went wrong. Printing error message...");
      console.log(error);
    }
  };

  const supportButtonOnClickFunctionHandler = () => {
    // console.log("Function connected...");
  };

  const cashPaymentMethodHandler = async () => {
    toast.success("Booking your ride", {
      toastId: "cashbook",
      autoClose: false,
      icon: <LoadingSpinner />,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    // console.log("cash...");

    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const fetchCashUrl = `${BASE_URL}${RIDER_PAYMENT_CASH_METHOD}`;
    const fetchCashOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        user_id:
          JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE))?.user_id ||
          undefined,
        ride_id: rideIdUrlParams,
        bid_id: selectedBidFromSelectBidContinue,
      }),
    };

    const responseCash = await fetch(fetchCashUrl, fetchCashOptions);

    if (!responseCash.ok) {
      toast.update("cashbook", {
        render: "Network error !",
        type: toast.TYPE.ERROR,
        autoClose: 20000,
        closeButton: true,
        icon: null,
        // transition: Slide,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const dataFromCash = await responseCash.json();

    if (dataFromCash.Code === CODE_200) {
      // console.log(dataFromCash);

      // get bid id  and ride id from backend then navigate
      toast.update("cashbook", {
        render: "Ride Booked !",
        type: toast.TYPE.SUCCESS,
        autoClose: 20000,
        closeButton: true,
        icon: null,
        // transition: Slide,
        position: toast.POSITION.TOP_CENTER,
      });
      // navigateToRideArrivingScreen();
      navigate(
        `${RIDER_RIDE_ARRIVING_ROUTE}?rideId=${dataFromCash.ride_id}&bidId=${dataFromCash.bid_id}`
      );
      return;
    }

    if (dataFromCash.Code === "400") {
      toast.update("cashbook", {
        render: dataFromCash?.message?.toString() || "Something went wrong!",
        type: toast.TYPE.ERROR,
        autoClose: 20000,
        closeButton: true,
        icon: null,
        // transition: Slide,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    toast.update("cashbook", {
      render: "Something went wrong !",
      type: toast.TYPE.ERROR,
      autoClose: 20000,
      closeButton: true,
      icon: null,
      // transition: Slide,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const onlinePaymentMetohdHandler = () => {
    // console.log("online...");
    // dispatch(riderBidActions.setSelectedBid(selectedBidFromSelectBidContinue));

    const phoneNumber =
      JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE))?.phone_number ||
      undefined;

    if (
      phoneNumber &&
      (phoneNumber.toString() === "9455879127" ||
        phoneNumber.toString() === "9650145293" ||
        phoneNumber.toString() === "7838712954")
    ) {
      // navigateToCcAvenuePayment();
      navigate(
        `${RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE}?rideId=${rideIdUrlParams}&bidId=${selectedBidFromSelectBidContinue}`
      );
      return;
    } else {
      toast.info("Please select cash");
    }
    // navigateToMakePayment();
    // navigate(
    //   `${RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE}?rideId=${rideIdUrlParams}&bidId=${selectedBidFromSelectBidContinue}`
    // );
  };

  const setSelectedBidUpliftHandler = (selectedBidFromSelectBidContinue) => {
    // open modal passing the selected bid
    // Modal Open
    dispatch(customModalActions.openCustomModalDialogBox());
    // send bid id
    setSelectedBidFromSelectBidContinue(selectedBidFromSelectBidContinue);
  };

  return (
    <div className={styles.waitingForBid}>
      <TopGreenCheckStatus displayText="Your ride has been created." />
      <NoEditGridFromToCancelSupportBox
        originAddressText={adress.originAddress}
        destinationAddressText={adress.destinationAddress}
        cancelButtonOnClickFunction={cancelButtonOnClickFunctionHandler}
        supportButtonOnClickFunction={supportButtonOnClickFunctionHandler}
      />
      <CustomModal
        mainHeading="Please select your payment method"
        buttons={{
          firstButton: {
            displayText: "Cash",
            method: cashPaymentMethodHandler,
          },
          secondButton: {
            displayText: "Online",
            method: onlinePaymentMetohdHandler,
          },
        }}
      />
      {Array.isArray(bids) && bids.length > 0 ? (
        <SelectBidContinue
          bidData={bids}
          setSelectedBidUplift={setSelectedBidUpliftHandler}
        />
      ) : (
        <LoadingBids />
      )}
    </div>
  );
};

export default WaitingForBid;
