import React from "react";
import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./MenuTextUserWrapper.module.css";

import { AnimatePresence, motion, useCycle } from "framer-motion";
import { useLogoutHandler, useNavigateOnEvent } from "../../../hooks";
import {
  ADV_POST_A_RIDE_TO_ROUTE,
  RIDER_EXTRAS_HELP,
  RIDER_EXTRAS_PAST_RIDES,
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
  RIDER_SCHEDULED_RIDE,
} from "../../../constants/routeConstants";
import {
  GLOBAL_VERSION_CONSTANT,
  callToWhatsApp,
} from "../../../constants/storageConstants";
import { useDispatch } from "react-redux";
import { uiElementActions } from "../../../store/slice";
import { getMobileOperatingSystem } from "../../../lib";
import { useNavigate } from "react-router-dom";

const MenuTextUserWrapper = ({
  children,
  logoHeadingText = "prop-MenuTextUserWrapper",
  bgGreenTextWhite = false,
}) => {
  const navigate = useNavigate();
  const navigateToPostRideTo = useNavigateOnEvent(
    RIDER_HOME_POST_A_RIDE__TO_ROUTE
  );
  const navigateToExtrasPastRides = useNavigateOnEvent(RIDER_EXTRAS_PAST_RIDES);
  const navigateToExtrasHelp = useNavigateOnEvent(RIDER_EXTRAS_HELP);
  const navigateToSchduledRide = useNavigateOnEvent(RIDER_SCHEDULED_RIDE);

  const logoutFunction = useLogoutHandler();

  const naviateToFirst = () => {
    cycleOpen();
    navigateToPostRideTo();
  };
  const onClickScheduledRide = () => {
    cycleOpen();
    // navigateToSchduledRide();
    callToWhatsApp();
    navigate(`${ADV_POST_A_RIDE_TO_ROUTE}`);
  };
  const naviateToSecond = () => {
    cycleOpen();
    navigateToExtrasPastRides();
  };

  const naviateToThird = () => {
    cycleOpen();
    navigateToExtrasHelp();
  };

  const dispatch = useDispatch();

  const logsDialogBox = () => {
    // console.log("Clicked");
    cycleOpen();
    dispatch(uiElementActions.openLogsDownloadDialogBox());
  };

  const userAgentOs = getMobileOperatingSystem();

  const hamMenuItems = [
    {
      imgSrc: images.car,
      alt: "car",
      text: "Book your ride",
      onClickNavigation: naviateToFirst,
    },

    {
      imgSrc: images.clock,
      alt: "clock",
      text: "Advance ride",
      onClickNavigation: onClickScheduledRide,
      //os: "iOS",
    },
    {
      imgSrc: images.clockThin,
      alt: "clock",
      text: "Your Rides",
      onClickNavigation: naviateToSecond,
    },
    {
      imgSrc: images.headset,
      alt: "help",
      text: "Help",
      onClickNavigation: naviateToThird,
    },
    {
      imgSrc: images.logOff,
      alt: "log out",
      text: "Log Off",
      onClickNavigation: logoutFunction,
    },
  ];
  const [open, cycleOpen] = useCycle(false, true);
  return (
    <>
      <div className={styles.menuTextUserWrapper}>
        <AnimatePresence>
          {open && (
            <>
              <motion.aside
                initial={{ width: "0%", opacity: 0 }}
                animate={{ width: "70%", opacity: 1 }}
                exit={{
                  width: "0%",
                  transition: { delay: 0, duration: 0.3 },
                  opacity: 0,
                }}
                className={styles.hamburgerMenu}
              >
                <button onClick={cycleOpen} className={styles.crossButton}>
                  X
                </button>

                <motion.ul
                  initial={{ opacity: 0, visibility: "hidden" }}
                  animate={{ opacity: 1, visibility: "visible" }}
                  exit={{
                    opacity: 0,
                    transition: { delay: 0, duration: 0.1 },
                  }}
                  className={styles.hamburgerList}
                >
                  {hamMenuItems.map(
                    (eachItem) =>
                      userAgentOs !== eachItem?.os && (
                        <li
                          key={eachItem.text}
                          className={styles.liClass}
                          onClick={eachItem.onClickNavigation}
                        >
                          <img
                            className={`${
                              eachItem.alt === "whatsapp"
                                ? styles.liClassWa
                                : styles.liClassImg
                            }`}
                            src={eachItem.imgSrc}
                            alt={eachItem.alt}
                          />
                          <span>{eachItem.text}</span>
                        </li>
                      )
                  )}
                </motion.ul>

                <motion.p
                  initial={{ opacity: 0, visibility: "hidden" }}
                  animate={{ opacity: 1, visibility: "visible" }}
                  exit={{
                    opacity: 0,
                    transition: { delay: 0, duration: 0.1 },
                  }}
                  className={styles.termsOfService}
                  onClick={() => {}}
                  onDoubleClick={logsDialogBox}
                >
                  {`Version - ${GLOBAL_VERSION_CONSTANT}`}
                </motion.p>
              </motion.aside>
              <motion.aside
                onClick={cycleOpen}
                className={styles.hamburgerSideClosing}
              ></motion.aside>
            </>
          )}
        </AnimatePresence>
        <div
          className={styles.appbar}
          style={{
            backgroundColor: bgGreenTextWhite ? "#419c00" : "",
          }}
        >
          <img
            onClick={cycleOpen}
            className={styles.hamburgerIcon}
            src={images.hamburgerIcon}
            alt="back icon"
          />
          <h1
            style={{
              fontWeight: bgGreenTextWhite ? "bold" : "",
            }}
            className={styles.logoHeadingText}
          >
            {logoHeadingText}
          </h1>
          <img
            className={styles.userGeneric}
            src={images.userGeneric}
            alt="user profile"
          />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </>
  );
};

export default MenuTextUserWrapper;
