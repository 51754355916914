import React from "react";
import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./LoadingBids.module.css";

const LoadingBids = ({ loadingBidsText = "Waiting for drivers to bid..." }) => {
  return (
    <div className={styles.loadingBids}>
      <p className={styles.para}>{loadingBidsText}</p>
      <img
        className={styles.hourGlass}
        src={images.hourGlass}
        alt="hour glass "
      />
    </div>
  );
};

export default LoadingBids;
