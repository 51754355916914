import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    showNotification: false,
    notificationData: {
      title: "",
      body: "",
    },
  },
  reducers: {
    setShowNotificationTrue(state) {
      state.showNotification = true;
    },

    setShowNotificationFalse(state) {
      state.showNotification = false;
    },

    setNotificationData(state, action) {
      state.notificationData = action.payload;
    },

    clearNotificationData(state) {
      state.notificationData = { title: "", body: "" };
    },
  },
});

export const notificationActions = notificationSlice.actions;
export default notificationSlice;
