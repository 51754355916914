export const ROOT_ROUTE = "/";

//
export const SPLASH_SCREEN_ROUTE = "/splashscreen";
export const WELCOME_ROUTE = "/welcome";

//
export const AUTH_SIGN_IN_ROUTE = "/auth/signin";
export const AUTH_SIGN_UP_ROUTE = "/auth/signup";
export const AUTH_VERIFY_OTP_ROUTE = "/auth/verifyotp";

//
export const ROUTE_TERMS_AND_CONDITIONS = "/open/terms/n/condition";
export const ROUTE_REFUND_POLICY = "/open/refund/policy";

//
// RIDER
export const RIDER_COMPLETE_PROFILE_ROUTE = "/rider/completeprofile";

//
export const RIDER_HOME_POST_A_RIDE__TO_ROUTE = "/rider/home/post/ride/to";
export const RIDER_POST_A_RIDE_FROM_TO_ROUTE = "/rider/post/ride/from/to";
export const RIDER_POST_A_RIDE_FROM_TO_MAP_SELECTION_ORDER_ROUTE =
  "/rider/book/ride";
export const RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE =
  "/rider/waiting/confirm/bid";
export const RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE =
  "/rider/ride/confirmed/payment";
export const RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE =
  "/rider/ride/confirmed/paymentcc";

export const RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE_WAITING_PAGE =
  "/rider/ride/confirmed/paymentcc/waiting";

export const RIDER_RIDE_ARRIVING_ROUTE = "/rider/ride/arriving";
export const RIDER_RIDE_IN_PROGRESS_ROUTE = "/rider/ride/inprogress";
export const RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE =
  "/rider/ride/completed/rate";

//
export const RIDER_EXTRAS_EDIT_PROFILE = "/rider/editprofile";
export const RIDER_EXTRAS_PAST_RIDES = "/rider/pastrides";
export const RIDER_EXTRAS_PAST_RIDE_DETAIL = "/rider/pastrides/detail";
export const RIDER_EXTRAS_HELP = "/rider/help";

//
// DRIVER
export const DRIVER_PROFILE_COMPLETE_PROFILE_ROUTE = "/driver/profile/basic";
export const DRIVER_PROFILE_ADDRESS_DETAILS_ROUTE = "/driver/profile/address";
export const DRIVER_PROFILE_VEHICLE_DETAILS_ROUTE = "/driver/profile/vehicle";
export const DRIVER_PROFILE_DOCUMENT_UPLOAD_ROUTE = "/driver/profile/documents";
export const DRIVER_BID_SECURITY = "/driver/profile/bid/security";

//
export const DRIVER_VIEW_RIDES_MY_BIDS_ROUTE = "/driver/rides";
export const DRIVER_PLACE_A_BID_ROUTE = "/driver/placebid";
export const DRIVER_BID_ACCEPTED_START_RIDE_ROUTE =
  "/driver/bidacceptedstartride";
export const DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE = "/driver/rideinprogress";

//
export const DRIVER_EXTRAS_EDIT_PROFILE = "/driver/editprofile";
export const DRIVER_EXTRAS_PAST_RIDES = "/driver/pastrides";
export const DRIVER_EXTRAS_PAST_RIDE_DETAIL = "/driver/pastrides/detail";
export const DRIVER_EXTRAS_HELP = "/driver/help";

// scheduled ride page
export const RIDER_SCHEDULED_RIDE = "/rider/schedule";

// advance booking routes
export const ADV_POST_A_RIDE_TO_ROUTE = "/adv/post/ride/to";
export const ADV_POST_A_RIDE_FROM_ROUTE = "/adv/post/ride/from";
export const ADV_POST_A_RIDE_MAP_VIEW_ROUTE = "/adv/post/ride/mapView";
export const ADV_RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE =
  "/adv/make/payment/ccavenue";
export const ADV_RIDER_RIDE_ARRIVING_ROUTE_ASSIGNED_DRIVER_ROUTE =
  "/adv/driver/assigned";
export const ADV_RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE_WAITING_PAGE =
  "/adv/payment/wait";

export const ADV_RIDER_RIDE_IN_PROGRESS_ROUTE = "/adv/ride/inprogress";
export const ADV_RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE = "/adv/ride/completed";
