import React from "react";
import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./BookRideWithBack.module.css";

const BookRideWithBack = ({
  enableBackButton = false,
  backButtonFunction = () => {
    console.warn("prop-Function not passed");
  },
  textHeading = "Book a ride now",
  textHeadingColorGreen = false,
  fullGreenWhiteText = false,
}) => {
  return (
    <div
      className={styles.bookRideWith}
      style={{
        backgroundColor: fullGreenWhiteText ? "#419c00" : "",
      }}
    >
      {enableBackButton && (
        <img
          onClick={backButtonFunction}
          className={styles.backButton}
          src={images.backArrow}
          alt="back button"
        />
      )}
      <img className={styles.cabImage} src={images.cab} alt="cab" />
      <p
        style={{
          color: fullGreenWhiteText ? "white" : "",
        }}
        className={`${styles.bookRideText} ${
          textHeadingColorGreen ? styles.bookRideTextGreen : ""
        }`}
      >
        {textHeading}
      </p>
    </div>
  );
};

export default BookRideWithBack;
