import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import styles from "./AdvanceBookingOnWhatsApp.module.css";
import { useNavigate } from "react-router-dom";
import { ADV_POST_A_RIDE_TO_ROUTE } from "../../constants/routeConstants";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../constants/storageConstants";
import Cookies from "js-cookie";
import {
  ADV_RIDER_GET_CURRENT_RIDE_STATUS,
  BASE_URL,
  RIDE_STATUS_BID_CONFIRMED,
} from "../../constants/apiConstants";

const AdvanceBookingOnWhatsApp = () => {
  const navigate = useNavigate();
  const floatingButtonOnClick = () => {
    // callToWhatsApp();
    navigate(`${ADV_POST_A_RIDE_TO_ROUTE}`);
  };

  const [flashing, setFlashing] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    //;
    (async () => {
      try {
        const response = await fetch(
          `${BASE_URL}${ADV_RIDER_GET_CURRENT_RIDE_STATUS}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setData(data);
          if (data?.ride_status === RIDE_STATUS_BID_CONFIRMED) {
            setFlashing(true);
          } else {
            setFlashing(false);
          }
        }
      } catch (error) {
        setFlashing(false);
      }
    })();
  }, []);

  function formatDateLabel(dateString) {
    const currentDate = new Date();
    const targetDate = new Date(dateString);

    // Get the year, month, and day of the current date
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    // Get the year, month, and day of the target date
    const targetYear = targetDate.getFullYear();
    const targetMonth = targetDate.getMonth();
    const targetDay = targetDate.getDate();

    // Check if the target date is today
    if (
      currentYear === targetYear &&
      currentMonth === targetMonth &&
      currentDay === targetDay
    ) {
      return "Today";
    }

    // Calculate tomorrow's date
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(currentDate.getDate() + 1);

    // Check if the target date is tomorrow
    if (
      targetYear === tomorrowDate.getFullYear() &&
      targetMonth === tomorrowDate.getMonth() &&
      targetDay === tomorrowDate.getDate()
    ) {
      return "Tomorrow";
    }

    // If the date is not today or tomorrow, return the formatted date
    return dateString;
  }

  function convertTo12HourFormat(time24) {
    // Check if time24 is defined and is a valid string
    if (typeof time24 !== "string" || !time24.match(/^\d{2}:\d{2}:\d{2}$/)) {
      return "Invalid time format";
    }
    console.log(time24);
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);

    // Determine if it's AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours from 24-hour to 12-hour format
    const hours12 = hours % 12 || 12;

    // Format the time in 12-hour format
    const time12 = `${hours12}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return time12;
  }

  return (
    <div
      className={`${styles.floatingAction} 
      ${flashing ? styles.flashing : ""} ${styles.greenColorBg}
        `}
      style={{
        bottom: "95px",
        right: "15px",
        fontSize: "1.6rem",
      }}
    >
      {data?.ride_status === "Bid Confirmed" && (
        <button
          onClick={floatingButtonOnClick}
          className={styles.floatingActionButton}
        >
          <p
            style={{
              fontSize: "1.6rem",
            }}
            className=""
          >
            You have scheduled Advance Booking
          </p>
          <p
            style={{
              fontSize: "1.4rem",
            }}
          >
            (
            {`${formatDateLabel(
              data?.schedule_date
            )} at ${convertTo12HourFormat(data?.schedule_time)}`}
            )
          </p>
        </button>
      )}
    </div>
  );
};

export default AdvanceBookingOnWhatsApp;
