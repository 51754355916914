import React from "react";
// eslint-disable-next-line
import styles from "./GoogleMapArrivingSoonOtpDriverLocation.module.css";
import { DirectionsRenderer, GoogleMap, Marker } from "@react-google-maps/api";
import { images } from "../../images";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

/**
 * Renders the Google Map for multiple polylines and markers.
 *
 * @param {MapCenterLatLng}  mapCenterlatLng - { latitude: 0.0  ( Float ) , longitude: 0.0 ( Float ) }
 * @param {markerList} markerlist - List of json objects with latitude and longitude
 * @param {directionResponseList} directionResponseList - List of direction Service Response results
 */
const GoogleMapArrivingSoonOtpDriverLocation = ({
  mapCenterlatLng,
  markerList,
  directionResponseList = [],
}) => {
  // console.log(markerList);
  return (
    <div className={styles.googleMapArrivingSoonOtpDriverLocation}>
      {mapCenterlatLng.latitude === undefined ||
      mapCenterlatLng.longitude === undefined ? (
        <div className={styles.loadingArea}>
          <LoadingSpinner />
          <p className={styles.loadingMapText}>Loading Map</p>
        </div>
      ) : (
        <GoogleMap
          center={{
            lat: mapCenterlatLng?.latitude,
            lng: mapCenterlatLng?.longitude,
          }}
          zoom={15}
          mapContainerStyle={{
            height: "100%",
            width: "100%",
            // borderRadius: "5px",
          }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            gestureHandling: "greedy",
          }}
        >
          {Array.isArray(markerList) &&
            markerList.length > 0 &&
            markerList.map((eachMarker) => (
              <Marker
                key={eachMarker.id}
                position={eachMarker?.position}
                icon={{
                  url: eachMarker?.icon,
                  scaledSize: new window.google.maps.Size(32, 32),
                }}
              />
            ))}

          {Array.isArray(directionResponseList) &&
            directionResponseList.length > 0 &&
            directionResponseList.map((eachDirectionResponse) => (
              <DirectionsRenderer
                key={eachDirectionResponse?.id}
                options={{
                  suppressMarkers: true,
                  polylineOptions: {
                    strokeColor: "#000",
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  },
                }}
                directions={eachDirectionResponse?.response}
              />
            ))}
        </GoogleMap>
      )}
    </div>
  );
};

export default GoogleMapArrivingSoonOtpDriverLocation;
