import { useNavigate } from "react-router-dom";

function useNavigateOnEvent(url, replace = false) {
  const navigate = useNavigate();

  function navigateOnEvent() {
    navigate(url, { replace });
  }

  return navigateOnEvent;
}

export { useNavigateOnEvent };
