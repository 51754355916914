import React, { useEffect } from "react";
// eslint-disable-next-line
import styles from "./SplashScreen.module.css";
import { images } from "../../../images";
import {
  useInterval,
  useLocalStorage,
  useNavigateOnEvent,
} from "../../../hooks";
import {
  AUTH_SIGN_UP_ROUTE,
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
} from "../../../constants/routeConstants";
import { motion } from "framer-motion";
import Cookies from "js-cookie";
import { ONE_SECOND } from "../../../constants/timeConstants";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  NULL,
  RIDER_NAME,
  UNDEFINED,
  USER_AGENT,
  USER_AGENT_RIDER,
  USER_LOCAL_STORAGE,
} from "../../../constants/storageConstants";
import { useDispatch } from "react-redux";
import { riderPostRideActions } from "../../../store/slice";

const SplashScreen = () => {
  const dispatch = useDispatch();

  const navigateToPostRideTo = useNavigateOnEvent(
    RIDER_HOME_POST_A_RIDE__TO_ROUTE,
    true
  );
  const navigateToSignUp = useNavigateOnEvent(AUTH_SIGN_UP_ROUTE, true);

  const [, setUserAgent] = useLocalStorage(USER_AGENT);

  useEffect(() => {
    setUserAgent(USER_AGENT_RIDER);
  }, [setUserAgent]);

  useInterval(() => {
    // console.log("Checking access token...");
    const riderName =
      JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE))?.first_name ||
      undefined;
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    if (
      !accessToken ||
      accessToken === null ||
      accessToken === undefined ||
      accessToken === NULL ||
      accessToken === UNDEFINED ||
      !riderName ||
      riderName === null ||
      riderName === undefined ||
      riderName === NULL ||
      riderName === UNDEFINED
    ) {
      // console.log("Access token not found...");
      // console.log("Access token - ");
      // console.log(accessToken);
      // console.log("Driver name - ");
      // console.log(driverName);
      // console.log("Navigating to Signup...");
      dispatch(riderPostRideActions.clearPostRide());
      navigateToSignUp();
      return;
    }

    // console.log("Found access token - ");
    // console.log(accessToken);

    // console.log("Rider name found - ");
    // console.log(driverName);

    dispatch(riderPostRideActions.clearPostRide());
    navigateToPostRideTo();
  }, ONE_SECOND);

  const variants = {
    initial: {
      opacity: 0,
      scale: 0,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      variants={variants}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        className={styles.splashScreenTaxiwarsLogo}
        src={images.splashScreenTaxiwarsLogo}
        alt="taxiwars logo with text"
      />

      <img
        className={styles.splashScreenLandscape}
        src={images.splashScreenLandscape}
        alt="splash screen landscape"
      />
    </motion.div>
  );
};

export default SplashScreen;
