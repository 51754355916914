import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import styles from "./CustomModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { customModalActions } from "../../store/slice";
import { USER_LOCAL_STORAGE } from "../../constants/storageConstants";

const CustomModal = ({
  mainHeading = "",
  buttons = {
    firstButton: {
      displayText: "",
      method: () => {},
    },
    secondButton: {
      displayText: "",
      method: () => {},
    },
  },
}) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const [showOnlinePayment, setShowOnlinePayment] = useState(false);

  useEffect(() => {
    const phoneNumber =
      JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE))?.phone_number ||
      undefined;

    if (
      phoneNumber &&
      (phoneNumber.toString() === "9455879127" ||
        phoneNumber.toString() === "9650145293" ||
        phoneNumber.toString() === "7838712954")
    ) {
      setShowOnlinePayment(true);
    }
  }, []);
  const isPaymentSelectionModalOpen = useSelector(
    (state) => state.customModal.isPaymentSelectionModalOpen
  );

  const closCustomModal = () => {
    // console.log("Close modal");
    dispatch(customModalActions.closeCustomModalDialogBox());
  };

  const firstButtonOnClickHandler = () => {
    buttons.firstButton.method();
    closCustomModal();
  };

  const secondButtonOnClickHandler = () => {
    buttons.secondButton.method();
    closCustomModal();
  };
  return (
    <>
      {isPaymentSelectionModalOpen && (
        <div className={styles.customModal}>
          <div onClick={closCustomModal} className={styles.modalBackdrop}>
            <div className={styles.visibleModal}>
              <h3 className={styles.modalMainHeading}>{mainHeading}</h3>
              {false && <p className={styles.modalSubHeading}>Sub Heading</p>}
              <div className={styles.buttonRow}>
                <button
                  onClick={firstButtonOnClickHandler}
                  className={`${styles.button} ${styles.buttonFirst}`}
                >
                  {buttons.firstButton.displayText}
                </button>
                {showOnlinePayment && (
                  <button
                    onClick={secondButtonOnClickHandler}
                    className={`${styles.button} ${styles.buttonSecond}`}
                  >
                    {buttons.secondButton.displayText}
                  </button>
                )}
              </div>
              <p className={styles.bottomDescriptionText}>
                {/* if description received is a string then show paragraph */}
                {false && <p>Paragraph</p>}
                {/* if description received is a list of string then show list items */}
                {false && (
                  <ul className={styles.ulList}>
                    <li>Cash will book your ride</li>
                    <li>Online will</li>
                  </ul>
                )}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomModal;
