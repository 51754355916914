import React from "react";
import { images } from "../../images";
// eslint-disable-next-line
import styles from "./RiderRatingCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { riderRatingActions } from "../../store/slice";
// import { useEffect } from "react";
// import { BASE_URL, SEND_RATING } from "../../constants/apiConstants";
// import { ACCESS_TOKEN_COOKIE_NAME } from "../../constants/storageConstants";
// import Cookies from "js-cookie";

const RiderRatingCard = () => {
  const dispatch = useDispatch();

  const numbersArray = [1, 2, 3, 4, 5];

  // const selectedBidId = useSelector((state) => state.riderBid.selectedBidId);
  // const selectedBidId = 6;

  const punctuality = useSelector((state) => state.riderRating.punctuality);
  const cleanliness = useSelector((state) => state.riderRating.cleanliness);
  const professionalism = useSelector(
    (state) => state.riderRating.professionalism
  );

  // console.log("punctuality", punctuality);
  // console.log("cleanliness", cleanliness);
  // console.log("professionalism", professionalism);

  const onClickPuntuality = (number) => {
    dispatch(riderRatingActions.setPunctuality(number));
  };

  const onClickCleanliness = (number) => {
    dispatch(riderRatingActions.setCleanliness(number));
  };

  const onClickProfessionalism = (number) => {
    dispatch(riderRatingActions.setProfessionalism(number));
  };

  return (
    <div className={styles.riderRatingCard}>
      <div className={styles.heading}>
        <p>Rate your ride</p>
      </div>
      <div className={styles.individualRating}>
        <p>Punctuality :</p>
        {numbersArray.map((eachNumber) => (
          <img
            key={eachNumber}
            onClick={() => {
              onClickPuntuality(eachNumber);
            }}
            className={`${punctuality >= eachNumber ? styles.active : ""}`}
            src={images.orangeStar}
            alt="rating star"
          />
        ))}
      </div>
      <div className={styles.individualRating}>
        <p>Cleanliness :</p>
        {numbersArray.map((eachNumber) => (
          <img
            key={eachNumber}
            onClick={() => {
              onClickCleanliness(eachNumber);
            }}
            className={`${cleanliness >= eachNumber ? styles.active : ""}`}
            src={images.orangeStar}
            alt="rating star"
          />
        ))}
      </div>
      <div className={styles.individualRating}>
        <p>Professionalism :</p>
        {numbersArray.map((eachNumber) => (
          <img
            key={eachNumber}
            onClick={() => {
              onClickProfessionalism(eachNumber);
            }}
            className={`${professionalism >= eachNumber ? styles.active : ""}`}
            src={images.orangeStar}
            alt="rating star"
          />
        ))}
      </div>
    </div>
  );
};

export default RiderRatingCard;
