import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    inputNumber: "",
    otp: "",
  },
  reducers: {
    updateInputMobileNumber(state, action) {
      state.inputNumber = action.payload;
    },
    otpInputBox(state, action) {
      state.otp = action.payload;
    },
    clearOTP(state) {
      state.otp = null;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
