import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddressListFromSearch,
  AdvanceBookingOnWhatsApp,
  // BookRideWithBack,
  Button,
  CampaignDialogBox,
  ErrorInNumber,
  GoogleMapComponent,
  InputBox,
  SelectOnMap,
  SosButton,
  // VisitedHistoryList,
  WhereToSearch,
} from "../../../components";
import {
  BASE_URL,
  GOOGLE_MAPS_API_KEY,
  RIDER_FCM_TOKEN,
  RIDER_PROFILE_DETAILS,
} from "../../../constants/apiConstants";
import {
  ADV_POST_A_RIDE_TO_ROUTE,
  RIDER_POST_A_RIDE_FROM_TO_MAP_SELECTION_ORDER_ROUTE,
  RIDER_POST_A_RIDE_FROM_TO_ROUTE,
} from "../../../constants/routeConstants";
import {
  useNavigateOnEvent,
  useNotificationToken,
  usePreventBackBehaviour,
  useRedirectAccToRide,
} from "../../../hooks";

import {
  campaignDialogBoxActions,
  riderPostRideActions,
} from "../../../store/slice";
// eslint-disable-next-line
import styles from "./PostRideTo.module.css";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { ONE_SECOND } from "../../../constants/timeConstants";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  USER_LOCAL_STORAGE,
  optionsgeloc,
} from "../../../constants/storageConstants";
import { images } from "../../../images";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const bottomAd = [
  {
    linkToOpen: "https://taxiwars.in",
    lefText: "Incubated by",
    rightImage: images.ndbiLogo,
    customStyle: false,
  },
  {
    linkToOpen: "https://taxiwars.in",
    lefText: "Experience Fair Pricing",
    rightImage: images.taxiwarsLogoHoriZontalWithText,
    customStyle: false,
  },
  {
    linkToOpen: "https://www.tigercubsbaner.com/",
    lefText: "Admission Open ",
    rightImage: images.schoolAdTiger,
    customStyle: true,
  },
];

const PostRideTo = () => {
  useRedirectAccToRide();
  usePreventBackBehaviour();
  useNotificationToken();
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    debounce: ONE_SECOND,
    options: {
      componentRestrictions: { country: "IN" },
    },
  });
  const dispatch = useDispatch();
  const origin = useSelector((state) => state.riderPostRide.inputOrigin);
  const destination = useSelector(
    (state) => state.riderPostRide.inputDestinationText
  );
  // const time = useSelector((state) => state.riderPostRide.inputTime);

  useEffect(() => {
    setSearchTerm(destination.mainAddress);
  }, [destination]);

  const [isMapSelectOpen, setIsMapSelectOpen] = useState(true);
  const [mapDragged, setMapDragged] = useState(false);

  const navigateToOrderNow = useNavigateOnEvent(
    RIDER_POST_A_RIDE_FROM_TO_MAP_SELECTION_ORDER_ROUTE
  );
  const navigateToPostRideFrom = useNavigateOnEvent(
    RIDER_POST_A_RIDE_FROM_TO_ROUTE
  );

  // const {
  //   loading: loadingGeoLoc,
  //   error: errorGeoLoc,
  //   data: dataGeoLoc,
  // } = useGeolocation(optionsgeloc);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (origin && origin.latitude && origin.longitude) {
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (e) => {
        console.log("geoloc success", e);
        dispatch(
          riderPostRideActions.updateOriginAddress({
            latitude: e.coords.latitude,
            longitude: e.coords.longitude,
          })
        );
        dispatch(
          riderPostRideActions.updateOriginAddressText({
            mainAddress: "",
            subAddress: "",
          })
        );
      },
      () => {
        console.log("error geoloc");
        dispatch(
          riderPostRideActions.updateOriginAddress({
            latitude: undefined,
            longitude: undefined,
          })
        );
        dispatch(
          riderPostRideActions.updateOriginAddressText({
            mainAddress: "",
            subAddress: "",
          })
        );
      },
      optionsgeloc
    );

    // if (loadingGeoLoc) return;
    // if (errorGeoLoc) {
    //   dispatch(
    //     riderPostRideActions.updateOriginAddress({
    //       latitude: undefined,
    //       longitude: undefined,
    //     })
    //   );
    //   dispatch(
    //     riderPostRideActions.updateOriginAddressText({
    //       mainAddress: "",
    //       subAddress: "",
    //     })
    //   );
    //   return;
    // }
  }, [dispatch]);

  const inputChangeHandler = (e) => {
    setSearchTerm(e.target.value);
    // console.log(e.target.value);
    if (e.target.value.trim().length > 5) {
      // console.log(e.target.value);
      getPlacePredictions({ input: e.target.value });
    }
  };

  const conditionalNavigation = () => {
    // Case 1 = location enabled
    if (origin && origin.latitude && origin.longitude) {
      dispatch(
        riderPostRideActions.updateOriginAddress({
          latitude: origin.latitude,
          longitude: origin.longitude,
        })
      );
      navigateToOrderNow();
    }
    // Case 2 = location disabled
    else {
      navigateToPostRideFrom();
    }
  };

  const selectAddressFromListHandler = (address) => {
    placesService.getDetails(
      {
        placeId: address.place_id,
        fields: ["geometry"],
      },
      (placeResult, status) => {
        if (status === "OK" && placeResult) {
          const {
            geometry: { location },
          } = placeResult;
          // console.log("Place details Origin:", { location });
          // console.log("location", location.lat(), location.lng());

          const lat = location.lat();
          const lng = location.lng();
          dispatch(
            riderPostRideActions.updateDestinationAddress({
              latitude: lat || "",
              longitude: lng || "",
            })
          );
        } else {
          // console.log("Something went wrong");
        }
      }
    );
    dispatch(riderPostRideActions.updateDestinationPlaceId(address.place_id));
    dispatch(
      riderPostRideActions.updateDestinationAddressText({
        mainAddress: address.structured_formatting.main_text || "",
        subAddress: address.structured_formatting.secondary_text || "",
      })
    );

    conditionalNavigation();
  };

  // Opening and closing Google Map selector
  const openMapHandler = () => {
    setIsMapSelectOpen(true);
  };
  const onInputFocus = () => {
    setIsMapSelectOpen(false);
  };

  const mapSelectProceedButtonHandler = () => {
    // After map dispatches the address to store
    if (!mapDragged) {
      toast.dismiss();
      toast.info("Drag map to select destination");
      return;
    }
    conditionalNavigation();
  };

  // useEffect(() => {
  //   console.log("Place Predictions", placePredictions);
  //   console.log("Place Serivce", placesService);
  // }, [placePredictions, placesService]);

  const addressLiftUpHandler = (address, selectedLocation) => {
    // console.log("Address from Map selection", address);
    setMapDragged(true);
    dispatch(
      riderPostRideActions.updateDestinationAddress({
        latitude: selectedLocation.lat || "",
        longitude: selectedLocation.lng || "",
      })
    );
    const mainText =
      address && address.substring(0, Math.ceil(address.length * 0.6)); // first 40% of the address
    const secondaryText =
      address && mainText && address.substring(mainText.length).trim(); // remaining 60% of the address
    dispatch(
      riderPostRideActions.updateDestinationAddressText({
        mainAddress: mainText || "",
        subAddress: secondaryText || "",
      })
    );

    setSearchTerm(mainText + secondaryText);
  };
  const [openUserNameAndEmailModal, setOpenUserNameAndEmailInputModal] =
    useState(false);

  const [userData, setUserData] = useState();

  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

  useEffect(() => {
    const userNameAndEmailCheck = () => {
      fetch(`${BASE_URL}${RIDER_PROFILE_DETAILS}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          // Check if both "first_name" and "email" properties exist and have values
          if (
            data &&
            data.hasOwnProperty("first_name") &&
            data.first_name &&
            data.hasOwnProperty("last_name") &&
            data.last_name &&
            data.hasOwnProperty("email") &&
            data.email &&
            data.email !== "na@na.com"
          ) {
            // Both "first_name" and "email" exist and have values, indicating username and email are taken
            setOpenUserNameAndEmailInputModal(false);
          } else {
            // Either "first_name" or "email" is missing or empty, indicating username or email is available
            setOpenUserNameAndEmailInputModal(true);
            setUserData(data);
          }
        })
        .catch((error) => {
          // Handle errors from the fetch or parsing response
          console.error("Error in userNameAndEmailCheck:", error);
        });
    };

    userNameAndEmailCheck();
  }, [accessToken]);

  const [openRideTypeModal, setOpenRideTypeModal] = useState(false);

  // TODO TWO DAY TRUE
  // const [ openCampaignPopup, setOpenCampaignPopup ] = useState(true);
  const isCampaignPopupModalOpen = useSelector(
    (state) => state.campaignDialogBox.isCampaignPopupModalOpen
  );

  const handleClose = () => {
    dispatch(campaignDialogBoxActions.closeCampaignPopup());
  };

  const phoneNumber =
    JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE))?.phone_number ||
    undefined;

  return (
    <div className={styles.postRideTo}>
      <CampaignDialogBox
        visibility={isCampaignPopupModalOpen}
        onCloseClick={handleClose}
      />
      <div
        style={{ position: "relative" }}
        onClick={() => setOpenRideTypeModal((prev) => !prev)}
      >
        <BookRideWithBack />
        <BookingTypeModal
          openLogDialogBox={openRideTypeModal}
          setOpenRideTypeModal={setOpenRideTypeModal}
        />
      </div>

      <UserNameAndEmailInputModal
        openLogDialogBox={openUserNameAndEmailModal}
        setOpenUserNameInputModal={setOpenUserNameAndEmailInputModal}
        userData={userData}
      />

      <WhereToSearch
        textInputValue={searchTerm}
        textInputOnChange={inputChangeHandler}
        textInputOnFocus={onInputFocus}
      />

      {/* <div>
        <SavedPlaces />
      </div> */}
      <div>
        {!isMapSelectOpen && (
          <div onClick={openMapHandler}>
            <SelectOnMap />
          </div>
        )}
        {isMapSelectOpen && (
          <div className={styles.mapBox}>
            <GoogleMapComponent addressLiftUp={addressLiftUpHandler} />
            <Button
              style={{ backgroundColor: `` }}
              displayText="Confirm Location"
              onClickFunction={mapSelectProceedButtonHandler}
            />
          </div>
        )}
        {placePredictions.length > 0 && (
          <div className={styles.list}>
            <AddressListFromSearch
              data={placePredictions}
              loading={isPlacePredictionsLoading}
              selectAddressFromListHandler={selectAddressFromListHandler}
            />
            {/* <div> */}
            {/* <VisitedHistoryList
              showClockIcon
              addressLineOne="JM Road, Deccan, Pune"
              addressLineTwo="Cafe Goodluck, Near Champion Sports, Pune"
            />
            <VisitedHistoryList
              showClockIcon
              addressLineOne="JM Road, Deccan, Pune"
              addressLineTwo="Cafe Goodluck, Near Champion Sports, Pune"
            /> */}
            {/* </div> */}
          </div>
        )}
        <AdvanceBookingOnWhatsApp />
        <div
          style={{
            cursor: "pointer",
          }}
          className={styles.bottomAd}
        >
          <BottomAd jsonDataArray={bottomAd} />
        </div>
      </div>
      {/* // TODO TWO DAY UNCOMMENT */}
      {/* <AdvanceBookingOnWhatsApp /> */}
      {/* {phoneNumber &&
      (phoneNumber.toString() === "9455879127" ||
        phoneNumber.toString() === "9650145293" ||
        phoneNumber.toString() === "7219748297" ||
        phoneNumber.toString() === "7678638011" ||
        phoneNumber.toString() === "7838712954" ||
        phoneNumber.toString() === "8465035940") ? (
        <>
          <AdvanceBookingOnWhatsApp />
        </>
      ) : (
        <></>
      )} */}
    </div>
  );
};

export default PostRideTo;

const BottomAd = ({ jsonDataArray }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const rotationInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % jsonDataArray.length);
    }, 3 * 1000); // 1000 = 1 second
    return () => clearInterval(rotationInterval);
  }, []);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
      onClick={() => {
        window.open(`${jsonDataArray[currentIndex].linkToOpen}`, "_blank");
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          marginLeft: "2rem",
        }}
      >
        <p
          style={{
            fontSize: "2rem",
            fontWeight: "bolder",
            color: "#000000",
          }}
        >
          {jsonDataArray[currentIndex].lefText}
        </p>
        <img
          className={
            jsonDataArray[currentIndex].customStyle
              ? styles.bottomAdImageCustom
              : styles.bottomAdImage
          }
          src={jsonDataArray[currentIndex].rightImage}
          alt="ndbi "
        />
      </div>
    </div>
  );
};

const BookRideWithBack = ({
  textHeading = "Book a ride now",
  textHeadingColorGreen = false,
  fullGreenWhiteText = false,
}) => {
  return (
    <div
      className={styles.bookRideWith}
      style={{
        backgroundColor: fullGreenWhiteText ? "#419c00" : "",
      }}
    >
      <img className={styles.cabImage} src={images.cab} alt="cab" />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          cursor: "pointer",
        }}
        className={`${styles.bookRideText} ${
          textHeadingColorGreen ? styles.bookRideTextGreen : ""
        }`}
      >
        <p
          style={{
            color: "white",
            fontWeight: "bold",
          }}
          className={styles.optionStyle}
        >
          {textHeading}
        </p>
        <img
          src={images.caretDown}
          alt="arrow"
          style={{
            marginTop: "-0.1rem",
            height: "20px",
            width: "20px",
          }}
        />
      </div>
    </div>
  );
};

const BookingTypeModal = ({ openLogDialogBox = false }) => {
  const rideTypes = [
    {
      id: "current ride",
      displayText: "Book a ride now",
    },
    {
      id: "advance ride",
      displayText: "Advance ride",
    },
  ];
  const navigate = useNavigate();
  const onClickScheduledRide = () => {
    // navigateToSchduledRide();
    navigate(`${ADV_POST_A_RIDE_TO_ROUTE}`);
  };

  return (
    <>
      {openLogDialogBox && (
        <div className={styles.logsBottomModal}>
          <div className={styles.visibleModal}>
            {rideTypes.map((each) => (
              <p
                onClick={() => {
                  // select this id
                  if (each.id === "advance ride") {
                    onClickScheduledRide();
                  }
                }}
                style={{
                  width: "100%",
                  fontSize: "1.8rem",
                  border: "2px solid gray",
                  borderRadius: "5px",
                  textAlign: "center",
                  padding: "1rem",
                }}
                key={each.id}
              >
                {each.displayText}
              </p>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const UserNameAndEmailInputModal = ({
  openLogDialogBox = false,
  setOpenUserNameInputModal,
  userData,
}) => {
  const [firstName, setFirstName] = useState(userData?.first_name || "");
  const [lastName, setLastName] = useState(userData?.last_name || "");
  const [email, setEmail] = useState(
    userData?.email && userData.email !== "na@na.com" && userData.email !== ""
      ? userData.email
      : ""
  );

  const [error, setError] = useState("");

  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateName = (name) => {
    const nameRegex = /^[A-Za-z]+(?:\s[A-Za-z]+)*$/;
    return nameRegex.test(name.trim());
  };

  const isEmailMissing = !userData?.email || userData.email === "na@na.com";
  const isFirstNameMissing =
    !userData?.first_name || userData.first_name === "";
  const isLastNameMissing = !userData?.last_name || userData.last_name === "";

  const requestMethod =
    isEmailMissing && (!isFirstNameMissing || !isLastNameMissing)
      ? "PATCH"
      : "POST";

  const sendUserDetails = ({ updatedData = {}, fcm = "" }) => {
    const patchData = {
      first_name: updatedData.first_name.trim() || userData?.first_name,
      last_name: updatedData.last_name.trim() || userData?.last_name,
      email: updatedData.email || userData?.email,
      help_number: "",
      referral_code: "organic",
    };

    fetch(`${BASE_URL}${RIDER_PROFILE_DETAILS}`, {
      method: requestMethod,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(patchData),
    })
      .then((response) => {
        if (
          (requestMethod === "PATCH" && response.status === 200) ||
          (requestMethod === "POST" && response.status === 201)
        ) {
          setOpenUserNameInputModal(false);
          return response.json();
        } else {
          toast.info("Something went wrong");
          throw new Error("Failed to send user details");
        }
      })
      .then((data) => {
        if (data && data.hasOwnProperty("customer_details")) {
          const dataMapping = {
            user_id: data?.customer_details,
            first_name: data?.first_name,
            last_name: data?.last_name,
            email: data?.email,
          };

          localStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify(dataMapping));

          if (fcm.toString().length > 0) {
            fetch(`${BASE_URL}${RIDER_FCM_TOKEN}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              method: "PUT",
              body: JSON.stringify({
                user_id: data?.id,
                fcm: fcm,
              }),
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error in sendUserDetails:", error);
      });
  };

  const handleInput = () => {
    if (isFirstNameMissing) {
      if (!firstName && !userData?.first_name) {
        setError("First Name cannot be empty.");
        return;
      }
      if (!validateName(firstName)) {
        setError("First Name can only contain letters.");
        return;
      }
      if (firstName.length < 3) {
        setError("First Name must be at least 3 characters long.");
        return;
      }

      if (!lastName && !userData?.last_name) {
        setError("Last Name cannot be empty.");
        return;
      }
      if (!validateName(lastName)) {
        setError("Last Name can only contain letters.");
        return;
      }
      if (lastName.length < 3) {
        setError("Last Name must be at least 3 characters long.");
        return;
      }

      if (!email && (!userData?.email || userData.email === "na@na.com")) {
        setError("Email cannot be empty.");
        return;
      }
      if (!validateEmail(email)) {
         setError("Please enter a valid email address.");
         return;
       }
    } else {
      if (!validateEmail(email)) {
        setError("Please enter a valid email address.");
        return;
      }
    }
    setError("");

    let updatedData = {};

    if (firstName !== userData?.first_name) {
      updatedData.first_name = firstName;
    }

    if (lastName !== userData?.last_name) {
      updatedData.last_name = lastName;
    }

    if (email !== userData?.email) {
      updatedData.email = email;
    }

    const fcm = localStorage.getItem("FCM_WITHOUT_NAV");
    sendUserDetails({ updatedData, fcm });
  };

  return (
    <>
      {openLogDialogBox && (
        <div className={styles.InputlogsBottomModal}>
          <div className={styles.InputModalBackdrop}>
            <div className={styles.visibleModal}>
              <h2
                style={{
                  fontSize: "2rem",
                  margin: "auto",
                  color: "#fc8018",
                }}
              >
                Welcome!
              </h2>
              <h2
                style={{
                  fontSize: "1.8rem",
                  margin: "auto",
                }}
              >
                Please Fill in Your Details
              </h2>

              {!userData?.first_name && (
                <InputBox
                  style={{ margin: "auto" }}
                  type="text"
                  idAndName="firstName"
                  placeholderText="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              )}
              {!userData?.last_name && (
                <InputBox
                  style={{ margin: "auto" }}
                  type="text"
                  idAndName="lastName"
                  placeholderText="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              )}

              {(!userData?.email || userData.email === "na@na.com") && (
                <InputBox
                  style={{ margin: "auto" }}
                  type="email"
                  idAndName="email"
                  placeholderText="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              )}

              {error && <ErrorInNumber displayText={error} />}

              <div>
                <Button
                  style={{ width: "100%", alignSelf: "stretch" }}
                  displayText="Submit"
                  onClickFunction={handleInput}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
