import { useDispatch, useSelector } from "react-redux";
import { useNavigateOnEvent, usePreventBackBehaviour } from "../../../hooks";
import styles from "./RideCompletedAdvancedRide.module.css";
import { useEffect, useState } from "react";
import { ADV_POST_A_RIDE_TO_ROUTE } from "../../../constants/routeConstants";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import Cookies from "js-cookie";
import {
  ADV_RIDE_DETAILS_COMPLETED,
  ADV_SEND_RATING,
  BASE_URL,
  EXPORT_EMAIL,
  RIDER_PROFILE_DETAILS,
} from "../../../constants/apiConstants";
import {
  riderPostRideAdvanceBookingActions,
  riderRatingAdvancedRideActions,
} from "../../../store/slice";
import {
  Button,
  ErrorInNumber,
  MenuTextUserWrapper,
  PaidAcknowledgementCheckbox,
  PleasePayAmountParaLine,
  RideCarDriverPickupDropDetailsCard,
  TopGreenCheckStatus,
} from "../../../components";
import { images } from "../../../images";

const RideCompletedAdvancedRide = () => {
  usePreventBackBehaviour();
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(false);
  const [checkBoxError, setCheckBoxError] = useState(false);

  const [urlParams] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const rideId = searchParams.get("rideId");
    return { rideId: rideId };
  });

  const punctuality = useSelector(
    (state) => state.riderRatingAdvancedRide.punctuality
  );
  const cleanliness = useSelector(
    (state) => state.riderRatingAdvancedRide.cleanliness
  );
  const professionalism = useSelector(
    (state) => state.riderRatingAdvancedRide.professionalism
  );

  const [completedRideDetails, setCompletedRideDetails] = useState();
  const [email, setEmail] = useState("");
  const [emailUpdate, setEmailUpdate] = useState(false);

  const navigateToHome = useNavigateOnEvent(ADV_POST_A_RIDE_TO_ROUTE, true);

  const rideCompletedButtonHandler = () => {
    if (!isChecked) {
      setCheckBoxError(true);
      return;
    }
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

    fetch(`${BASE_URL}${ADV_SEND_RATING}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: urlParams.rideId,
        punctuality: punctuality || 5,
        cleanliness: cleanliness || 5,
        professionalism: professionalism || 5,
      }),
    })
      .then((response) => {
        if (response.ok) {
          // console.log(response);
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        // console.log("Retrun data", data);
      })
      .catch((e) => {
        console.log("Error while sending rating", e);
      });

    const updateEmailId = () => {
      fetch(`${BASE_URL}${EXPORT_EMAIL}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        method: "POST",
        body: JSON.stringify({
          email: email ? email : "na@na.com",
        }),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Email updated successfully");
          } else {
            console.error("Failed to update email:");
          }
        })
        .catch((error) => {
          console.error("Error in email update request:", error);
        });
    };
    if (emailUpdate) {
      updateEmailId();
    }

    //cleaning of ridPostSlice
    dispatch(riderPostRideAdvanceBookingActions.clearPostRide());
    navigateToHome();
  };

  const currentRideId = urlParams.rideId;
  // const currentRideId = 31;

  // const selectedBidId = useSelector((state) => state.riderBid.selectedBidId);

  useEffect(() => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    fetch(`${BASE_URL}${ADV_RIDE_DETAILS_COMPLETED}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: parseInt(currentRideId),
        // bid_id: selectedBidId,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        // console.log("The completed ride details", data);
        setCompletedRideDetails({
          carName: data?.car_name,
          vehicleImage: data?.car_photo,
          driverName: data?.driver_name,
          driverImage: data?.driver_photo,
          rideStartTime: data?.driver_ride_start,
          rideEndTime: data?.driver_ride_end,
          pickupAddressText: data?.pickup_address_text,
          dropAddressText: data?.drop_address_text,
          totalAmount: data?.total_amount,
          amountPayable: data?.amount_payable,
          paymentType: data?.payment_type,
        });
      })
      .catch((e) => {
        console.log("Error occured fetching completed ride details", e);
        setCompletedRideDetails({});
      });
  }, [currentRideId]);

  const onCheckBoxChangeHandler = (value) => {
    setIsChecked(value);
  };

  return (
    <MenuTextUserWrapper logoHeadingText="Ride Completed" bgGreenTextWhite>
      <div className={styles.rideCompleted}>
        <TopGreenCheckStatus displayText="Your ride is completed." />
        {/* <StaticMapWithTimeCancelledCard /> */}
        <RiderRatingCard />
        <PleasePayAmountParaLine
          totalAmount={completedRideDetails?.amountPayable}
          paymentType={completedRideDetails?.paymentType}
        />
        <PaidAcknowledgementCheckbox
          label={"I have paid the driver"}
          isChecked={isChecked}
          onCheckboxChange={onCheckBoxChangeHandler}
        />
        {checkBoxError && (
          <ErrorInNumber displayText="Please confirm if paid" />
        )}
        <EmailVerification
          setEmailUpdate={setEmailUpdate}
          email={email}
          setEmail={setEmail}
        />
        {/* Already present in PleasePayAmountParaLine component */}
        {/* <ErrorInNumber displayText="Toll and Parking to be paid at actual" /> */}
        <Button
          displayText="Submit"
          onClickFunction={rideCompletedButtonHandler}
        />
        <RideCarDriverPickupDropDetailsCard
          carName={completedRideDetails?.carName}
          driverName={completedRideDetails?.driverName}
          rideStartTime={completedRideDetails?.rideStartTime}
          rideEndTime={completedRideDetails?.rideEndTime}
          pickupAddressText={completedRideDetails?.pickupAddressText}
          dropAddressText={completedRideDetails?.dropAddressText}
          totalAmount={completedRideDetails?.totalAmount}
          vehicleImage={completedRideDetails?.vehicleImage}
          driverImage={completedRideDetails?.driverImage}
          paymentType={completedRideDetails?.paymentType}
        />
      </div>
    </MenuTextUserWrapper>
  );
};

export default RideCompletedAdvancedRide;

const RiderRatingCard = () => {
  const dispatch = useDispatch();

  const numbersArray = [1, 2, 3, 4, 5];

  // const selectedBidId = useSelector((state) => state.riderBid.selectedBidId);
  // const selectedBidId = 6;

  const punctuality = useSelector(
    (state) => state.riderRatingAdvancedRide.punctuality
  );
  const cleanliness = useSelector(
    (state) => state.riderRatingAdvancedRide.cleanliness
  );
  const professionalism = useSelector(
    (state) => state.riderRatingAdvancedRide.professionalism
  );

  // console.log("punctuality", punctuality);
  // console.log("cleanliness", cleanliness);
  // console.log("professionalism", professionalism);

  const onClickPuntuality = (number) => {
    dispatch(riderRatingAdvancedRideActions.setPunctuality(number));
  };

  const onClickCleanliness = (number) => {
    dispatch(riderRatingAdvancedRideActions.setCleanliness(number));
  };

  const onClickProfessionalism = (number) => {
    dispatch(riderRatingAdvancedRideActions.setProfessionalism(number));
  };

  return (
    <div className={styles.riderRatingCard}>
      <div className={styles.heading}>
        <p>Rate your ride</p>
      </div>
      <div className={styles.individualRating}>
        <p>Punctuality :</p>
        {numbersArray.map((eachNumber) => (
          <img
            key={eachNumber}
            onClick={() => {
              onClickPuntuality(eachNumber);
            }}
            className={`${punctuality >= eachNumber ? styles.active : ""}`}
            src={images.orangeStar}
            alt="rating star"
          />
        ))}
      </div>
      <div className={styles.individualRating}>
        <p>Cleanliness :</p>
        {numbersArray.map((eachNumber) => (
          <img
            key={eachNumber}
            onClick={() => {
              onClickCleanliness(eachNumber);
            }}
            className={`${cleanliness >= eachNumber ? styles.active : ""}`}
            src={images.orangeStar}
            alt="rating star"
          />
        ))}
      </div>
      <div className={styles.individualRating}>
        <p>Professionalism :</p>
        {numbersArray.map((eachNumber) => (
          <img
            key={eachNumber}
            onClick={() => {
              onClickProfessionalism(eachNumber);
            }}
            className={`${professionalism >= eachNumber ? styles.active : ""}`}
            src={images.orangeStar}
            alt="rating star"
          />
        ))}
      </div>
    </div>
  );
};

const EmailVerification = ({ setEmailUpdate, email, setEmail }) => {
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  const [isEmailavailable, setEmailavailability] = useState(false);

  useEffect(() => {
    const userNameCheck = () => {
      fetch(`${BASE_URL}${RIDER_PROFILE_DETAILS}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if (data && data.hasOwnProperty("email")) {
            if (data.email === "na@na.com") {
              setEmailavailability(true);
              setEmailUpdate(true);
            }
          }
        })
        .catch((error) => {
          // Handle errors from the fetch or parsing response
          console.error("Error in userNameCheck:", error);
        });
    };

    userNameCheck();
  }, [accessToken]);

  const setInputEmail = (email) => {
    setEmail(email);
  };

  return (
    <>
      {isEmailavailable && (
        <input
          className={styles.input}
          type="text"
          value={email}
          onChange={(e) => setInputEmail(e.target.value)}
          placeholder="Enter email to recieve Invoice"
        />
      )}
    </>
  );
};
