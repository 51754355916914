import { useEffect } from "react";
import { pushState } from "history-throttled";
import { HASH_MAP_HISTORY_SIZE_ARRAY } from "../constants/storageConstants";

const usePreventBackBehaviour = () => {
  useEffect(() => {
    Array.from({ length: HASH_MAP_HISTORY_SIZE_ARRAY }).map((_, index) => {
      pushState(null, null, `#backButtonDisabled=${JSON.stringify(index + 1)}`);
      return null;
    });
  }, []);
};

export { usePreventBackBehaviour };
