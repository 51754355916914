import React from "react";
import { images } from "../../images";
// eslint-disable-next-line
import styles from "./ActualPastRideCard.module.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";

const ActualPastRideCard = ({
  cancelled = false,
  rideAmount,
  date,
  pickupAddressText,
  dropAddressText,
  rideTime,
}) => {
  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);

  const formattedDateTime = dayjs(date, "YYYY-MM-DD").format("DD-MM-YYYY");
  const formattedTime = dayjs(rideTime, "HH:mm:ss").format("hh:mm A");
  return (
    <>
      <div className={styles.actualPastRideCard}>
        <div className={styles.middle}>
          <div className={styles.upper}>
            <p className={styles.line}>
              <span>{formattedDateTime}</span>
              <span>{formattedTime}</span>
            </p>
            <p className={styles.line}>{/* <span>{vehicleNumber}</span> */}</p>
          </div>
          <div className={styles.lower}>
            <div className={styles.bottomTwo}>
              <span className={styles.dotGreen}></span>
              <p>{pickupAddressText}</p>
            </div>
            <div className={styles.bottomTwo}>
              <span className={styles.dotRed}></span>
              <p>{dropAddressText}</p>
            </div>
          </div>
          {/* <div className={styles.driverNameNumberLine}>
            <div>{driverName}</div>
            <a className={styles.anchorReset} href={`tel:${driverPhoneNumber}`}>
              {driverPhoneNumber}
            </a>
          </div> */}
        </div>
        <div className={styles.right}>
          {cancelled ? (
            <p className={styles.cancelled}>Cancelled</p>
          ) : (
            <p className={styles.rideAmount}>{`₹${(rideAmount / 100).toFixed(
              1
            )}`}</p>
          )}
          {/* <img src={driverPhoto || images.userGenericInvert} alt="driver" /> */}
        </div>
      </div>
    </>
  );
};

export default ActualPastRideCard;
