import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    isTimeSelectModalOpen: false,
    isSortBidsModalOpen: false,
  },
  reducers: {
    openTimeSelectModal(state) {
      state.isTimeSelectModalOpen = true;
    },
    closeTimeSelectModal(state) {
      state.isTimeSelectModalOpen = false;
    },
    openSorBodsModal(state) {
      state.isSortBidsModalOpen = true;
    },
    closeSortBidsModal(state) {
      state.isSortBidsModalOpen = false;
    },
  },
});

export const uiActions = uiSlice.actions;
export default uiSlice;
