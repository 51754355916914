import Cookies from "js-cookie";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AnimationLayout,
  Button,
  ErrorInNumber,
  InputBox,
  RiderDriverSign,
  TextHeadingSign,
} from "../../../components";
import {
  BASE_URL,
  RIDER_FCM_TOKEN,
  RIDER_PROFILE_DETAILS,
} from "../../../constants/apiConstants";
import {
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
  // WELCOME_ROUTE,
} from "../../../constants/routeConstants";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  EMAIL_VALIDATION_REGEX,
  USER_LOCAL_STORAGE,
} from "../../../constants/storageConstants";
import { useNavigateOnEvent } from "../../../hooks";
import { riderProfileActions } from "../../../store/slice";
// eslint-disable-next-line
import styles from "./RiderCompleteProfile.module.css";

const RiderCompleteProfile = () => {
  const dispatch = useDispatch();
  const firstName = useSelector((state) => state.riderProfile.firstName);
  const lastName = useSelector((state) => state.riderProfile.lastName);
  const emailAddress = useSelector((state) => state.riderProfile.emailAddress);
  const helpNumber = useSelector((state) => state.riderProfile.helpNumber);
  const fcm = useSelector((state) => state.fcmToken.fcm);

  const [referralCode, setReferralCode] = useState("");

  const navigateToPostRideTo = useNavigateOnEvent(
    RIDER_HOME_POST_A_RIDE__TO_ROUTE,
    true
  );

  const [isNetworkError, setIsNetworkError] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [isError, setIsError] = useState({
    firstName: false,
    lastName: false,
    emailAddress: false,
    helpNumber: false,
    isValidEmail: false,
  });

  const firstNameChangeHandler = (e) => {
    setIsNetworkError(false);
    setIsSending(false);

    dispatch(riderProfileActions.updateFirstName(e.target.value));
    setIsError((prev) => ({ ...prev, firstName: false }));
  };
  const lastNameChangeHandler = (e) => {
    setIsNetworkError(false);
    setIsSending(false);

    dispatch(riderProfileActions.updateLastName(e.target.value));
    setIsError((prev) => ({ ...prev, lastName: false }));
  };
  const emailAddressChangeHandler = (e) => {
    setIsNetworkError(false);
    setIsSending(false);

    dispatch(riderProfileActions.updateEmailAddress(e.target.value));
    setIsError((prev) => ({ ...prev, emailAddress: false }));
    setIsError((prev) => ({ ...prev, isValidEmail: false }));
  };

  const helpNumberChangeHandler = (e) => {
    const num = e.target.value;
    if (num.length > 10) return;

    setIsNetworkError(false);
    setIsSending(false);

    dispatch(riderProfileActions.updateHelpNumber(e.target.value));
    setIsError((prev) => ({ ...prev, helpNumber: false }));
  };

  const checkValidateAndNavigate = () => {
    setIsNetworkError(false);
    setIsSending(false);
    setIsError(false);
    // validation
    if (firstName.trim().length === 0) {
      setIsError((prev) => ({ ...prev, firstName: true }));
      return;
    }

    if (lastName.trim().length === 0) {
      setIsError((prev) => ({ ...prev, lastName: true }));
      return;
    }

    if (emailAddress.trim().length === 0) {
      setIsError((prev) => ({ ...prev, emailAddress: true }));
      return;
    }
    // email validation

    if (!EMAIL_VALIDATION_REGEX.test(emailAddress)) {
      setIsError((prev) => ({ ...prev, isValidEmail: true }));
      return;
    }

    // help number optional validation
    if (helpNumber.trim().length !== 0) {
      if (helpNumber.length < 10) {
        setIsError((prev) => ({ ...prev, helpNumber: true }));
        return;
      }
    }

    //sending
    setIsSending(true);
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    // console.log(accessToken);
    fetch(`${BASE_URL}${RIDER_PROFILE_DETAILS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: emailAddress,
        help_number: helpNumber,
        referral_code: referralCode || "organic",
        // customer_photo: null,
      }),
    })
      .then((response) => {
        if (response.status === 201) {
          console.log("Customer created", response);
          return response.json();
        } else if (response.status === 200) {
          console.log("Custoemr Details Exist");
          return;
        }

        throw response;
      })
      .then((data) => {
        if (data && data.hasOwnProperty("customer_details")) {
          // Data format recived from backend
          // * IGNORE - id field
          // customer_details represents the actual user_id
          // customer_details has ben mapped to user_id in local storage
          // to maintain uniformity in frontend
          //   {
          //     "id": 17,
          //     "first_name": "SCustomerFirstName",
          //     "last_name": "SCustomerLastName",
          //     "email": "semail@gmail.com",
          //     "customer_photo": null,
          //     "customer_details": 19
          // }

          const dataMapping = {
            user_id: data?.customer_details,
            first_name: data?.first_name,
            last_name: data?.last_name,
            email: data?.email,
            phone_number: data?.phone_number,
          };
          // console.log("The data after we filled the form");
          // console.log(dataMapping);

          localStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify(dataMapping));
          //customer created now fcm call with id
          // console.log(data);
          if (fcm.toString().length > 0) {
            fetch(`${BASE_URL}${RIDER_FCM_TOKEN}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              method: "POST",
              body: JSON.stringify({
                user_id: data?.id,
                fcm: fcm,
              }),
            }).then((response) => {
              if (response.ok) {
                console.log("Fcm send success response ok", response);
                navigateToPostRideTo();
                return response.json();
              }
              throw response;
            });
          } else {
            // console.log(
            //   "No fcm token found..navigating to post ride without fcm"
            // );
            navigateToPostRideTo();
          }

          // navigateToPostRideTo();
          return;
        }
      })
      .catch((e) => {
        setIsNetworkError(true);
        console.log("Something went wrong", e);
      })
      .finally(() => {
        setIsSending(false);
      });

    // fetchData();
    // if (error) {
    // console.warn(error.message);
    // return;
    // }
    // if (!loading && !error && data) navigateToPostRideTo();
  };

  return (
    <>
      <RiderDriverSign>
        <AnimationLayout>
          <div className={styles.children}>
            <TextHeadingSign displayText="Complete your profile" />
            <InputBox
              type="text"
              idAndName="firstName"
              placeholderText="What's your first name?"
              value={firstName}
              onChange={firstNameChangeHandler}
            />
            {isError.firstName && (
              <ErrorInNumber displayText="First name cannot be empty." />
            )}
            <InputBox
              type="text"
              idAndName="lastName"
              placeholderText="What's your last name?"
              value={lastName}
              onChange={lastNameChangeHandler}
            />
            {isError.lastName && (
              <ErrorInNumber displayText="Last name cannot be empty." />
            )}
            <InputBox
              type="email"
              idAndName="emailAddress"
              placeholderText="What's your email address?"
              value={emailAddress}
              onChange={emailAddressChangeHandler}
            />
            {isError.emailAddress && (
              <ErrorInNumber displayText="Email cannot be empty" />
            )}
            {isError.isValidEmail && (
              <ErrorInNumber displayText="Please enter correct email." />
            )}
            <InputBox
              type="tel"
              idAndName="helpNumber"
              placeholderText="Emergency Contact ( Optional )"
              value={helpNumber}
              onChange={helpNumberChangeHandler}
            />
            <InputBox
              type="text"
              idAndName="referralcode"
              placeholderText="Referral Code ( Optional )"
              value={referralCode}
              onChange={(e) => {
                const referText = e.target.value;
                setReferralCode(referText);
                console.log(referText);
              }}
            />
            {isError.helpNumber && (
              <ErrorInNumber displayText="Enter correct number." />
            )}
            {isSending && <ErrorInNumber displayText="Sending data..." />}
            {isNetworkError && (
              <ErrorInNumber displayText="Netwrok/Server error" />
            )}

            <Button
              displayText="Continue"
              onClickFunction={checkValidateAndNavigate}
            />
          </div>
        </AnimationLayout>
      </RiderDriverSign>
    </>
  );
};

export default RiderCompleteProfile;
