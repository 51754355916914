import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {
  useAdvRedirectAcc,
  useNavigateOnEvent,
  usePreventBackBehaviour,
} from "../../../hooks";
import styles from "./PostRideToAdvanceBooking.module.css";
import { GOOGLE_MAPS_API_KEY } from "../../../constants/apiConstants";
import { ONE_SECOND } from "../../../constants/timeConstants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { riderPostRideAdvanceBookingActions } from "../../../store/slice";
import {
  callToWhatsApp,
  optionsgeloc,
} from "../../../constants/storageConstants";
import { toast } from "react-toastify";
import {
  AddressListFromSearch,
  BookRideWithBack,
  Button,
  GoogleMapComponent,
  SelectOnMap,
  WhereToSearch,
} from "../../../components";
import {
  ADV_POST_A_RIDE_FROM_ROUTE,
  ADV_POST_A_RIDE_MAP_VIEW_ROUTE,
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
} from "../../../constants/routeConstants";
import { useNavigate } from "react-router-dom";

const PostRideToAdvanceBooking = () => {
  useAdvRedirectAcc();
  usePreventBackBehaviour();
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    debounce: ONE_SECOND,
    options: {
      componentRestrictions: { country: "IN" },
    },
  });
  const dispatch = useDispatch();
  const origin = useSelector(
    (state) => state.riderPostRideAdvanceBooking.inputOrigin
  );
  const destination = useSelector(
    (state) => state.riderPostRideAdvanceBooking.inputDestinationText
  );

  useEffect(() => {
    setSearchTerm(destination.mainAddress);
  }, [destination]);

  const [isMapSelectOpen, setIsMapSelectOpen] = useState(false);
  const [mapDragged, setMapDragged] = useState(false);

  const navigateToOrderNow = useNavigateOnEvent(ADV_POST_A_RIDE_MAP_VIEW_ROUTE);
  const navigateToPostRideFrom = useNavigateOnEvent(ADV_POST_A_RIDE_FROM_ROUTE);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (origin && origin.latitude && origin.longitude) {
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (e) => {
        console.log("geoloc success", e);
        dispatch(
          riderPostRideAdvanceBookingActions.updateOriginAddress({
            latitude: e.coords.latitude,
            longitude: e.coords.longitude,
          })
        );
        dispatch(
          riderPostRideAdvanceBookingActions.updateOriginAddressText({
            mainAddress: "",
            subAddress: "",
          })
        );
      },
      () => {
        console.log("error geoloc");
        dispatch(
          riderPostRideAdvanceBookingActions.updateOriginAddress({
            latitude: undefined,
            longitude: undefined,
          })
        );
        dispatch(
          riderPostRideAdvanceBookingActions.updateOriginAddressText({
            mainAddress: "",
            subAddress: "",
          })
        );
      },
      optionsgeloc
    );
  }, [dispatch]);

  const inputChangeHandler = (e) => {
    setSearchTerm(e.target.value);
    // console.log(e.target.value);
    if (e.target.value.trim().length > 5) {
      // console.log(e.target.value);
      getPlacePredictions({ input: e.target.value });
    }
  };

  const conditionalNavigation = () => {
    // Case 1 = location enabled
    if (origin && origin.latitude && origin.longitude) {
      dispatch(
        riderPostRideAdvanceBookingActions.updateOriginAddress({
          latitude: origin.latitude,
          longitude: origin.longitude,
        })
      );
      navigateToOrderNow();
    }
    // Case 2 = location disabled
    else {
      navigateToPostRideFrom();
    }
  };

  const selectAddressFromListHandler = (address) => {
    placesService.getDetails(
      {
        placeId: address.place_id,
        fields: ["geometry"],
      },
      (placeResult, status) => {
        if (status === "OK" && placeResult) {
          const {
            geometry: { location },
          } = placeResult;
          // console.log("Place details Origin:", { location });
          // console.log("location", location.lat(), location.lng());

          const lat = location.lat();
          const lng = location.lng();
          dispatch(
            riderPostRideAdvanceBookingActions.updateDestinationAddress({
              latitude: lat || "",
              longitude: lng || "",
            })
          );
        } else {
          // console.log("Something went wrong");
        }
      }
    );
    dispatch(
      riderPostRideAdvanceBookingActions.updateDestinationPlaceId(
        address.place_id
      )
    );
    dispatch(
      riderPostRideAdvanceBookingActions.updateDestinationAddressText({
        mainAddress: address.structured_formatting.main_text || "",
        subAddress: address.structured_formatting.secondary_text || "",
      })
    );

    conditionalNavigation();
  };

  // Opening and closing Google Map selector
  const openMapHandler = () => {
    setIsMapSelectOpen(true);
  };
  const onInputFocus = () => {
    setIsMapSelectOpen(false);
  };

  const mapSelectProceedButtonHandler = () => {
    // After map dispatches the address to store
    if (!mapDragged) {
      toast.dismiss();
      toast.info("Drag map to select destination");
      return;
    }
    conditionalNavigation();
  };

  const addressLiftUpHandler = (address, selectedLocation) => {
    // console.log("Address from Map selection", address);
    setMapDragged(true);
    dispatch(
      riderPostRideAdvanceBookingActions.updateDestinationAddress({
        latitude: selectedLocation.lat || "",
        longitude: selectedLocation.lng || "",
      })
    );
    const mainText =
      address && address.substring(0, Math.ceil(address.length * 0.6)); // first 40% of the address
    const secondaryText =
      address && mainText && address.substring(mainText.length).trim(); // remaining 60% of the address
    dispatch(
      riderPostRideAdvanceBookingActions.updateDestinationAddressText({
        mainAddress: mainText || "",
        subAddress: secondaryText || "",
      })
    );

    setSearchTerm(mainText + secondaryText);
  };

  return (
    <div className={styles.postRideTo}>
      <BookRideWithBack
        textHeading="Advance Booking"
        textHeadingColorGreen
        fullGreenWhiteText
      />
      <WhereToSearch
        textInputValue={searchTerm}
        textInputOnChange={inputChangeHandler}
        textInputOnFocus={onInputFocus}
      />

      {/* <div>
        <SavedPlaces />
      </div> */}
      <div>
        {!isMapSelectOpen && (
          <div onClick={openMapHandler}>
            <SelectOnMap />
          </div>
        )}
        {isMapSelectOpen && (
          <div className={styles.mapBox}>
            <GoogleMapComponent addressLiftUp={addressLiftUpHandler} />
            <Button
              style={{ backgroundColor: `` }}
              displayText="Confirm Location"
              onClickFunction={mapSelectProceedButtonHandler}
            />
          </div>
        )}
        {(placePredictions.length > 0 || isPlacePredictionsLoading) && (
          <div className={styles.list}>
            <AddressListFromSearch
              data={placePredictions}
              loading={isPlacePredictionsLoading}
              selectAddressFromListHandler={selectAddressFromListHandler}
            />
          </div>
        )}
      </div>
      <BackToHome />
      {/* <BookOnWhatsApp2 /> */}
    </div>
  );
};

export default PostRideToAdvanceBooking;

const BackToHome = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        zIndex: 5,
        position: "absolute",
        bottom: "calc(4rem + 75px)",
        right: "3rem",
        fontSize: "1.8rem",
      }}
      className={`${styles.floatingAction} ${styles.right}`}
    >
      <button
        onClick={() => {
          navigate(`${RIDER_HOME_POST_A_RIDE__TO_ROUTE}`);
        }}
        style={{
          border: "none",
          background: "none",
          // padding: 0,
          margin: 0,
          font: "inherit",
          cursor: "pointer",
          // outline: "inherit",

          /* Custom */
          backgroundColor: "#419c00",
          padding: "1.6rem",
          outline: "none",
          borderRadius: "30px",
          minWidth: "130px",
          color: "#fff",
          fontWeight: " bold",
          // -webkit-tap-highlight-color: transparent;
          /* transition: background-color 0.3s ease-in; */
          userSelect: "none",
        }}
        className={styles.floatingActionButton}
      >
        <p
          style={{
            fontSize: "1.6rem",
          }}
          className=""
        >
          Book Current Ride
        </p>
        <p
          style={{
            fontSize: "1.4rem",
          }}
        >
          {/* (Outstation) */}
        </p>
      </button>
    </div>
  );
};

const BookOnWhatsApp2 = () => {
  return (
    <div
      style={{
        zIndex: 5,
        position: "absolute",
        bottom: "calc(4rem + 75px)",
        left: "3rem",
        fontSize: "1.8rem",
      }}
      className={`${styles.floatingAction}`}
    >
      <button
        onClick={() => {
          // navigate(`${RIDER_HOME_POST_A_RIDE__TO_ROUTE}`);
          callToWhatsApp();
        }}
        style={{
          border: "none",
          background: "none",
          // padding: 0,
          margin: 0,
          font: "inherit",
          cursor: "pointer",
          // outline: "inherit",

          /* Custom */
          backgroundColor: "#419c00",
          padding: "1.6rem",
          outline: "none",
          borderRadius: "30px",
          minWidth: "130px",
          color: "#fff",
          fontWeight: " bold",
          // -webkit-tap-highlight-color: transparent;
          /* transition: background-color 0.3s ease-in; */
          userSelect: "none",
        }}
        className={styles.floatingActionButton}
      >
        <p
          style={{
            fontSize: "1.6rem",
          }}
          className=""
        >
          Book on WhatsApp
        </p>
        <p
          style={{
            fontSize: "1.4rem",
          }}
        >
          {/* (Outstation) */}
        </p>
      </button>
    </div>
  );
};
