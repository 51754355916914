import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  MenuTextUserWrapper,
  NumberPlateCarNameRatingCard,
  SosButton,
  TopGreenCheckStatus,
} from "../../../components";
import {
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
  RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE,
  SPLASH_SCREEN_ROUTE,
} from "../../../constants/routeConstants";
import { useInterval, useNavigateOnEvent } from "../../../hooks";
// eslint-disable-next-line
import styles from "./RideInProgress.module.css";
import Cookies from "js-cookie";
import {
  BASE_URL,
  RIDER_GET_ACCEPTED_RIDE_DETAILS,
  RIDE_STATUS_ACTIVE,
  RIDE_STATUS_BID_CONFIRMED,
  RIDE_STATUS_CANCELLED,
  RIDE_STATUS_COMPLETED,
  RIDE_STATUS_GET,
  RIDE_STATUS_IN_PROGRESS,
  RIDE_STATUS_PENDING,
} from "../../../constants/apiConstants";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import { riderCurrentRideActions } from "../../../store/slice";
import { ONE_SECOND } from "../../../constants/timeConstants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { pushState } from "history-throttled";

const RideInProgress = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToPostRideTo = useNavigateOnEvent(
    RIDER_HOME_POST_A_RIDE__TO_ROUTE,
    true
  );

  const [urlParams] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const rideId = searchParams.get("rideId");
    const bidId = searchParams.get("bidId");
    // console.log("Param ride id", rideId, "bid id", bidId);
    return { rideId: rideId, bidId: bidId };
  });

  // const currentRideId = useSelector(
  //   (state) => state.riderCurrentRide.currentRIdeId
  // );
  const currentRideId = urlParams.rideId;
  const currentBidId = urlParams.bidId;

  // const selectedBidDetailsWithNumber = useSelector(
  //   (state) => state.riderBid.selectedBidDetailsWithNumber
  // );

  const [selectedBidDetailsWithNumber, setSelectedBidDetailsWithNumber] =
    useState({
      vehicleNumber: "",
      vehicleName: "",
      driverName: "",
      phoneNumber: "",
      driverImage: "",
      vehicleImage: "",
      dropLat: "",
      dropLng: "",
    });

  // const navigateToRideCompleted = useNavigateOnEvent(
  //   RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE,
  //   true
  // );

  // const navigateToSplashScreen = useNavigateOnEvent(SPLASH_SCREEN_ROUTE, true);

  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  const httpPolling = () => {
    fetch(`${BASE_URL}${RIDE_STATUS_GET}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: currentRideId,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((rideStatus) => {
        // console.log("Current ride Status", rideStatus);
        // dispatch(
        //   riderCurrentRideActions.setCurrentRideStatus(rideStatus?.Ride_Status)
        // );
      pushState(
          null,
          null,
          `#rideStatusFetch=${JSON.stringify(rideStatus?.Ride_Status)}`
        );

        if (rideStatus?.Ride_Status === RIDE_STATUS_COMPLETED) {
          // navigateToRideCompleted();
          navigate(
            `${RIDER_RIDE_COMPLETED_RIDE_RATING_ROUTE}?rideId=${rideStatus.ride_id}&bidId=${rideStatus.bid_id}`
          );
        }
        if (
          rideStatus?.Ride_Status === RIDE_STATUS_CANCELLED
          // rideStatus?.Ride_Status === RIDE_STATUS_IN_PROGRESS ||
          // rideStatus?.Ride_Status === RIDE_STATUS_BID_CONFIRMED ||
          // rideStatus?.Ride_Status === RIDE_STATUS_PENDING ||
          // rideStatus?.Ride_Status === RIDE_STATUS_ACTIVE
        ) {
          //
          navigateToPostRideTo();
        } else {
          // navigateToSplashScreen();
          // console.log(rideStatus);
        }
      })
      .catch((e) => {
        console.log("Error in retrieving ride status", e);
      });
  };

  // TODO Change according to the ride time at later stages
  useInterval(httpPolling, ONE_SECOND * 15);

  // const originAddress = useSelector((state) => state.riderPostRide.inputOrigin);
  // const destinationAddress = useSelector(
  //   (state) => state.riderPostRide.inputDestination
  // );

  useEffect(() => {
    // console.log("currentBidId", currentBidId);
    const functionCall = () => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(`${BASE_URL}${RIDER_GET_ACCEPTED_RIDE_DETAILS}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ bid_id: parseInt(currentBidId) }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          // console.log("Selected bid details with number -", data);
          // console.log("data se;e", data);
          setSelectedBidDetailsWithNumber({
            vehicleNumber: data?.car_details?.registration_number,
            vehicleName: data?.car_details?.car_name,
            driverName: data?.name,
            phoneNumber: data?.phone_number,
            driverImage: data?.driver_photo,
            vehicleImage: data?.car_details?.car_photo,
            driverLocationLat: parseFloat(data?.driver_location_lat),
            driverLocationLng: parseFloat(data?.driver_location_lng),
            dropLat: parseFloat(data?.drop_lat),
            dropLng: parseFloat(data?.drop_lng),
          });
          // dispatch(riderCurrentRideActions.setCurrentRideId(data?.ride_id));
        })
        .catch((e) => {
          console.log("Data could not be fetched", e);
        })
        .finally(() => {});
    };

    // console.log("fcuntion call...");
    functionCall();

    // calculateRoute();
    const interval = setInterval(() => {
      // console.log("fcuntion call interval set");
      functionCall();

      // calculateRoute();
      // can be changed to any time we want
    }, ONE_SECOND * 40);
    return () => {
      // console.log("fcuntion call interval release");
      clearInterval(interval);
    };
  }, []);

  const openGoogleMaps = (lat, lng) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&travelmode=driving`;
    window.open(url, "_blank");
  };

  const navigateOnMapButtonHandler = () => {
    if (
      !selectedBidDetailsWithNumber?.dropLat ||
      !selectedBidDetailsWithNumber?.dropLng
    ) {
      toast.dismiss();
      toast.info("Please wait");
      return;
    }
    openGoogleMaps(
      selectedBidDetailsWithNumber?.dropLat,
      selectedBidDetailsWithNumber?.dropLng
    );
  };

  return (
    <MenuTextUserWrapper logoHeadingText="Ride in Progress">
      <div className={styles.rideInProgress}>
        <TopGreenCheckStatus displayText="Your ride is in progress." />
        {selectedBidDetailsWithNumber.driverName && (
          <NumberPlateCarNameRatingCard
            driverImage={selectedBidDetailsWithNumber?.driverImage}
            vehicleNumber={selectedBidDetailsWithNumber?.vehicleNumber}
            vehicleName={selectedBidDetailsWithNumber?.vehicleName}
            driverName={selectedBidDetailsWithNumber?.driverName}
            phoneNumber={selectedBidDetailsWithNumber?.phoneNumber}
            vehicleImage={selectedBidDetailsWithNumber?.vehicleImage}
            cancelRideShow={false}
          />
        )}
        <Button
          displayText="Navigate on maps"
          onClickFunction={navigateOnMapButtonHandler}
        />
        {/* <Button
          displayText="Next screen"
          onClickFunction={navigateToRideCompleted}
        /> */}
      </div>
      {currentRideId && <SosButton currentRideId={currentRideId} />}
    </MenuTextUserWrapper>
  );
};

export default RideInProgress;
