import Cookies from "js-cookie";
import { ACCESS_TOKEN_COOKIE_NAME } from "../constants/storageConstants";
import { useNavigateOnEvent } from "./use-navigateOnEvent";
import { AUTH_SIGN_UP_ROUTE } from "../constants/routeConstants";

const useLogoutHandler = () => {
  const navigateToLoginPage = useNavigateOnEvent(AUTH_SIGN_UP_ROUTE, true);
  const logoutFunction = () => {
    Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
    localStorage.clear();
    sessionStorage.clear();

    //navigateToLoginPage
    navigateToLoginPage();
  };

  return logoutFunction;
};

export { useLogoutHandler };
