import React from "react";
// eslint-disable-next-line
import styles from "./NoEditGridFromToCancelSupportBox.module.css";

const NoEditGridFromToCancelSupportBox = ({
  originAddressText,
  destinationAddressText,
  cancelButtonOnClickFunction = () => {
    console.log("Function not connected");
  },
  supportButtonOnClickFunction = () => {
    console.log("Function not connected");
  },
}) => {
  return (
    <div className={styles.noEditGridFromToCancelSupportBox}>
      <div className={styles.gridSystem}>
        <div className={styles.greenToRed}>
          <span className={styles.dotGreen}></span>
          <hr className={styles.hrLine} />
          <span className={styles.dotRed}></span>
        </div>

        <div className={styles.textBoxes}>
          <p>{originAddressText && originAddressText.slice(0, 49)}</p>
          <hr className={styles.horizontalLine} />
          <p>{destinationAddressText && destinationAddressText.slice(0, 49)}</p>
        </div>
      </div>
      <hr className={styles.fullHorizontalLine} />
      <div className={styles.cancelSupport}>
        <button onClick={cancelButtonOnClickFunction}>Cancel Ride</button>
        {/* <hr className={styles.verticleLine} />
        <button onClick={supportButtonOnClickFunction}>Support</button> */}
      </div>
    </div>
  );
};

export default NoEditGridFromToCancelSupportBox;
