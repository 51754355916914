import React from "react";
// eslint-disable-next-line
import styles from "./PleasePayAmountParaLine.module.css";

const PleasePayAmountParaLine = ({ totalAmount, paymentType }) => {
  return (
    <>
      <div>
        <p className={styles.pleasePayAmountParaLine}>
          Please pay ₹ <span>{(totalAmount / 100).toFixed(1)} </span>
          to driver
        </p>
        <p className={styles.tollAmountLine}>
          ( Parking / Toll to be paid at actual. If, applicable )
        </p>
      </div>
    </>
  );
};

export default PleasePayAmountParaLine;
