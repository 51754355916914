import React from "react";
import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./WhereToSearch.module.css";
const WhereToSearch = ({
  textInputValue,
  textInputOnChange,
  textInputOnFocus,
}) => {
  return (
    <div className={styles.whereToSearch}>
      <label htmlFor="whereTo">
        <img className={styles.searchIcon} src={images.search} alt="search" />
      </label>
      <input
        // autoFocus
        onFocus={textInputOnFocus}
        id="whereTo"
        name="whereTo"
        className={styles.inputBar}
        type="text"
        placeholder="Where to?"
        value={textInputValue}
        onChange={textInputOnChange}
      />
    </div>
  );
};

export default WhereToSearch;
